import * as yup from "yup";

export const usersSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup.string().required("Email is required!"),
  dateOfBirth: yup.string(),
  country: yup.string(),
  subscribed: yup.string(),
  status: yup.string(),
  isAdmin: yup.boolean(),
  createdAt: yup.string(),
  lastActivity: yup.string(),
});
