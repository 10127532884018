// @mui material components
import {
  Card,
  Grid,
  TextField,
  CircularProgress,
  Radio,
  FormControlLabel,
  Icon,
  InputLabel,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ListStatusNewsList } from "../../../constant/constant";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAddNews } from "queries/news.queries";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { newsSchema } from "./schemas";
import { PreviewDialog } from "../../../examples/PreviewDialog";

function Default() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [open, setOpen] = useState(false);
  const [widthImage, setWidthImage] = useState(300);
  const refImage = useRef();

  const methods = useForm({
    resolver: yupResolver(newsSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      durationFrom: "",
      durationTo: "",
      status: 1,
      description: "",
      externalLink: "",
      image: null,
    },
  });
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    clearErrors,
    watch,
    formState: { errors, isValid },
    reset,
  } = methods;

  const { mutate, isSuccess, isLoading } = useAddNews();
  const onBack = () => {
    navigate(-1);
  };
  const onSubmit = handleSubmit((data) => {
    trigger();
    let formData = new FormData();
    formData.append("img", data.img);
    formData.append("title", data.title);
    formData.append("durationFrom", data.durationFrom ? new Date(data.durationFrom) : "");
    formData.append("durationTo", data.durationTo ? new Date(data.durationTo) : "");
    formData.append("status", data.status);
    formData.append("description", data.description);
    formData.append("externalLink", data.externalLink);
    mutate(formData);
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/news");
    }
  }, [isSuccess, navigate]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const showPreview = () => {
    if (file) {
      setOpen(true);
    }
  };

  const deleteImage = () => {
    setFile("");
    setValue("img", "");
  };

  useLayoutEffect(() => {
    setWidthImage(refImage.current.offsetWidth);
  }, []);

  const watchTitle = watch("title");
  const watchDescription = watch("description");

  return (
    <DashboardLayout>
      <PreviewDialog open={open} onClose={handleCloseDialog} file={file} />
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <FormProvider {...methods}>
            <ArgonBox component="form" onSubmit={onSubmit} ml={3} mr={3}>
              <ArgonBox mb={3}>
                <Card>
                  <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <ArgonTypography variant="h6">Add News</ArgonTypography>
                  </ArgonBox>
                  <ArgonBox
                    ml={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <ArgonTypography variant="h7">General information</ArgonTypography>
                  </ArgonBox>
                  <ArgonBox ml={3} mr={3} pb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ArgonBox>
                          <Label required={true}>Title</Label>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                fullWidth
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Type title..."
                                {...field}
                                sx={{ root: { height: "50px" } }}
                              />
                            )}
                          />
                          <InputLabel style={{ float: "right" }}>
                            {watchTitle.length}/25 characters
                          </InputLabel>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <ArgonBox>
                          <Label>Duration</Label>
                          <ArgonBox sx={{ display: "flex", gap: 1 }}>
                            <Controller
                              name="durationFrom"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <DateTimePicker
                                  inputFormat="HH:mm DD/MM/YYYY"
                                  {...field}
                                  defaultValues={null}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={error?.message ? error?.message : null}
                                      error={invalid}
                                      sx={{ root: { height: "50px" } }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "DD/MM/YYYY",
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                            <Controller
                              name="durationTo"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <DateTimePicker
                                  inputFormat="HH:mm DD/MM/YYYY"
                                  {...field}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={error?.message ? error?.message : null}
                                      error={invalid}
                                      sx={{ root: { height: "50px" } }}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "DD/MM/YYYY",
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <ArgonBox>
                          <Label>Status</Label>
                          <ArgonBox mb={1}>
                            <Controller
                              name="status"
                              value={[]}
                              control={control}
                              render={({ field }) => (
                                <Grid container>
                                  {ListStatusNewsList.filter((doc) => doc.value != "all").map(
                                    (item, index) => (
                                      <ArgonBox key={"grid-" + index}>
                                        <FormControlLabel
                                          {...field}
                                          key={item.label}
                                          label={item.label}
                                          control={
                                            <Radio
                                              sx={{ ml: 2 }}
                                              value={item.value}
                                              checked={Number(getValues("status")) === item.value}
                                            />
                                          }
                                        />
                                      </ArgonBox>
                                    )
                                  )}
                                </Grid>
                              )}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Card>
              </ArgonBox>
              <ArgonBox mb={3}>
                <Card>
                  <ArgonBox
                    ml={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <ArgonTypography variant="h7">Content</ArgonTypography>
                  </ArgonBox>
                  <ArgonBox ml={3} mr={3} pb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sx={{ textAlign: "center", position: "relative" }}>
                        <ArgonBox
                          component="img"
                          ref={refImage}
                          sx={{
                            width: "100%",
                            height: `${(widthImage * 2) / 3}px`,
                            cursor: "pointer",
                            objectFit: "fill",
                          }}
                          src={
                            file
                              ? file
                              : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                          }
                          alt=""
                          onClick={showPreview}
                        />
                        {file && (
                          <Icon
                            onClick={deleteImage}
                            sx={{
                              right: "2px",
                              top: "17px",
                              position: "absolute",
                              cursor: "pointer",
                              background: "white",
                            }}
                          >
                            close
                          </Icon>
                        )}
                        <ArgonBox
                          className="formInput"
                          sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                        >
                          <Label htmlFor="upload-photo">
                            <Controller
                              name="img"
                              control={control}
                              render={({ field, fieldState }) => (
                                <div>
                                  <input
                                    style={{ display: "none" }}
                                    id="upload-photo"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                    onChange={(e) => {
                                      setFile(URL.createObjectURL(e.target.files[0]));
                                      field.onChange(e.target.files[0]);
                                    }}
                                  />
                                  <p style={{ fontSize: "0.75rem" }}>
                                    Upload image with ratio 3:2 and minimum dimension 786*524
                                  </p>
                                  <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                    {fieldState.error?.message}
                                  </p>
                                </div>
                              )}
                            />
                            <ArgonButton color="warning" variant="contained" component="span">
                              Upload image
                            </ArgonButton>
                          </Label>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ArgonBox>
                              <Label required={true}>Description</Label>
                              <Controller
                                name="description"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <TextField
                                    fullWidth
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="Type description..."
                                    {...field}
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                              <InputLabel style={{ float: "right" }}>
                                {watchDescription.length}/150 characters
                              </InputLabel>
                            </ArgonBox>
                          </Grid>{" "}
                          <Grid item xs={12}>
                            <ArgonBox>
                              <Label>External Link</Label>
                              <Controller
                                name="externalLink"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <TextField
                                    fullWidth
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="Type externalLink..."
                                    {...field}
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </ArgonBox>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <ArgonBox sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                          <ArgonButton
                            m={3}
                            color="info"
                            className="btn"
                            variant="contained"
                            type="submit"
                            startIcon={isLoading && <CircularProgress size={20} />}
                            disabled={isLoading}
                          >
                            Save
                          </ArgonButton>
                          <ArgonButton
                            ml={3}
                            color="info"
                            className="btn"
                            variant="outlined"
                            onClick={onBack}
                            startIcon={isLoading && <CircularProgress size={20} />}
                            disabled={isLoading}
                          >
                            Cancel
                          </ArgonButton>
                        </ArgonBox>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Card>
              </ArgonBox>
            </ArgonBox>
          </FormProvider>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
