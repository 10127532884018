// @mui material components
import Card from "@mui/material/Card";
import {
  Grid,
  Icon,
  TextField,
  CircularProgress,
  Checkbox,
  Radio,
  FormControlLabel,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAddRank } from "../../../queries/ranks.queries";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { rankSchema } from "./schemas";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  YesNoNumberList,
  RewardTypeLabel,
  TypeRankReward,
  TypeRankRewardList,
  RankOnly,
} from "../../../constant/constant";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { RewardConfigPopup } from "../RewardConfigPopup";
import { toast } from "react-toastify";

function Default() {
  const navigate = useNavigate();
  const [rewardConfig, setRewardConfig] = useState(null);
  const [indexRewardConfig, Reward] = useState(0);
  const [popupReward, setPopupReward] = useState(false);
  const [rewardForWholeRank, setRewardForWholeRank] = useState(null);
  const [rewardForEachBattle, setRewardForEachBattle] = useState(
    RankOnly.filter((doc) => doc.value !== "all").map((doc) => {
      return {
        name: doc.label,
        reward: null,
        type: doc.value,
      };
    })
  );

  const methods = useForm({
    resolver: yupResolver(rankSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      durationFrom: "",
      durationTo: "",
      resetRank: "",
      rewardType: "",
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    formState: { errors, isValid },
    reset,
    watch,
  } = methods;

  const { mutate, isSuccess, isLoading, isError } = useAddRank();

  const watchRewardType = watch("rewardType");
  const watchName = watch("name");

  const onBack = () => {
    navigate(-1);
  };

  const validateForm = (data) => {
    if (
      Number(data.rewardType) === TypeRankReward.EACH &&
      rewardForEachBattle.filter((doc) => doc.reward).length < RankOnly.length
    ) {
      return `Reward for each rank is required`;
    }

    if (Number(data.rewardType) === TypeRankReward.WHOLE && !rewardForWholeRank) {
      return `Reward for all ranks is required`;
    }
    return "";
  };

  const onSubmit = handleSubmit((data) => {
    trigger();
    const message = validateForm(data);
    if (message) {
      toast.error(message);
      return;
    }
    let dataForm = {};
    for (let key in data) {
      if (key.includes("_")) {
        let arrs = key.split("_");
        if (data[arrs[0]]) {
          data[arrs[0]][arrs[1]] = data[key];
        } else {
          data[arrs[0]] = {
            [arrs[1]]: data[key],
          };
        }
        delete data[key];
      }
    }
    data.rewardAllRank = "";
    data.rewardEachRank = [];
    if (Number(data.rewardType) === TypeRankReward.WHOLE) {
      data.rewardAllRank = rewardForWholeRank._id;
    } else if (Number(data.rewardType) === TypeRankReward.EACH) {
      data.rewardEachRank = rewardForEachBattle.map((doc, index) => {
        return {
          rewardId: doc.reward._id,
          type: doc.type,
        };
      });
    }
    for (let key in data) {
      if (key === "durationFrom" || key === "durationTo") {
        dataForm[key] = new Date(data[key]);
      } else {
        dataForm[key] = data[key];
      }
    }
    mutate(dataForm);
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/rank");
    }
  }, [isSuccess, navigate]);

  const handleCloseDialog = () => {
    setPopupReward(false);
    setRewardConfig(null);
  };

  const onSelectReward = (dataReward) => {
    if (indexRewardConfig === 0) {
      setRewardForWholeRank(dataReward);
    } else {
      rewardForEachBattle[indexRewardConfig - 1].reward = dataReward;
      setRewardForEachBattle(rewardForEachBattle);
    }
  };

  const showPopupReward = (dataReward, index) => {
    setRewardConfig(dataReward);
    setPopupReward(true);
    Reward(index);
  };

  return (
    <DashboardLayout>
      <RewardConfigPopup
        open={popupReward}
        onClose={handleCloseDialog}
        reward={rewardConfig}
        onSelectReward={onSelectReward}
      />
      <DashboardNavbar />
      <ArgonBox py={3}>
        <FormProvider {...methods}>
          <ArgonBox mb={3} component="form" onSubmit={onSubmit}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <Typography variant="h6">Add Rank</Typography>
                </ArgonBox>
                <ArgonBox
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <Typography variant="h7">Basic information</Typography>
                </ArgonBox>
                <ArgonBox ml={3} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ArgonBox>
                        <Label required={true}>Rank name</Label>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              placeholder="Type Rank name"
                              {...field}
                              sx={{ root: { height: "50px" } }}
                            />
                          )}
                        />
                        <InputLabel style={{ float: "right" }}>
                          {watchName.length}/75 characters
                        </InputLabel>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={6}>
                      <ArgonBox>
                        <Label required={true}>Duration</Label>
                        <ArgonBox sx={{ display: "flex", gap: 1 }}>
                          <Controller
                            name="durationFrom"
                            control={control}
                            render={({ field, fieldState }) => (
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  inputFormat="HH:mm DD/MM/YYYY"
                                  {...field}
                                  defaultValues={null}
                                  renderInput={(params) => (
                                    <ArgonBox>
                                      <TextField {...params} sx={{ root: { height: "50px" } }} />
                                      <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                        {fieldState.error?.message}
                                      </p>
                                    </ArgonBox>
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                          <Controller
                            name="durationTo"
                            control={control}
                            render={({ field, fieldState }) => (
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  inputFormat="HH:mm DD/MM/YYYY"
                                  {...field}
                                  renderInput={(params) => (
                                    <ArgonBox>
                                      <TextField {...params} sx={{ root: { height: "50px" } }} />
                                      <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                        {fieldState.error?.message}
                                      </p>
                                    </ArgonBox>
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={6}>
                      <ArgonBox>
                        <Label required={true}>Reset MMR after rank season</Label>
                        <Controller
                          name="resetRank"
                          value={[]}
                          control={control}
                          render={({ field }) => (
                            <Grid container>
                              {YesNoNumberList.filter((doc) => doc.value != "all").map(
                                (item, index) => (
                                  <Box key={"grid-" + index}>
                                    <FormControlLabel
                                      {...field}
                                      key={item.label}
                                      label={item.label}
                                      control={
                                        <Radio
                                          sx={{ ml: 2 }}
                                          value={item.value}
                                          checked={Number(getValues("resetRank")) === item.value}
                                        />
                                      }
                                    />
                                  </Box>
                                )
                              )}
                            </Grid>
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
            {/* Reward */}
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <Typography variant="h7">Reward</Typography>
                </ArgonBox>
                <ArgonBox ml={6} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ArgonBox>
                        <ArgonBox mt={1}>
                          <Controller
                            name="rewardType"
                            value={[]}
                            control={control}
                            render={({ field, fieldState }) => (
                              <ArgonBox>
                                <Grid container>
                                  {TypeRankRewardList.map((item, index) => (
                                    <Box key={"grid-" + index}>
                                      <FormControlLabel
                                        {...field}
                                        key={item.label}
                                        label={item.label}
                                        control={
                                          <Radio
                                            sx={{ ml: 2 }}
                                            value={item.value}
                                            checked={Number(getValues("rewardType")) === item.value}
                                          />
                                        }
                                      />
                                    </Box>
                                  ))}
                                </Grid>
                                <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                  {fieldState.error?.message}
                                </p>
                              </ArgonBox>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {Number(watchRewardType) === TypeRankReward.WHOLE && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <ArgonButton
                              variant="outlined"
                              color="primary"
                              size="medium"
                              rel="noreferrer"
                              fullWidth
                              onClick={() => showPopupReward(rewardForWholeRank, 0)}
                            >
                              {!rewardForWholeRank ? (
                                <>
                                  <Icon sx={{ marginRight: "10px", float: "right" }}>add</Icon>Add
                                  reward configuration
                                </>
                              ) : (
                                <>
                                  <Icon sx={{ marginRight: "10px", float: "right" }}>edit</Icon>
                                  {`${rewardForWholeRank.name} -
                                    ${RewardTypeLabel[rewardForWholeRank.rewardType]}`}
                                </>
                              )}
                            </ArgonButton>
                          </ArgonBox>
                        </Grid>
                      </>
                    )}
                    {Number(watchRewardType) === TypeRankReward.EACH && (
                      <>
                        {rewardForEachBattle.map((doc, index) => (
                          <Grid key={index} item xs={12}>
                            <Grid container spacing={2} sx={{ maxWidth: "1200px" }}>
                              <Grid item xs={6} sm={3} lg={2} xl={2}>
                                <ArgonBox>
                                  <ArgonButton
                                    variant="outlined"
                                    color="error"
                                    size="medium"
                                    rel="noreferrer"
                                    onClick={() => {
                                      const dataRows = [...rewardForEachBattle];
                                      dataRows[index].reward = null;
                                      setRewardForEachBattle(dataRows);
                                    }}
                                  >
                                    <Icon sx={{ marginRight: "10px", float: "right" }}>delete</Icon>
                                    Delete
                                  </ArgonButton>
                                </ArgonBox>
                              </Grid>
                              <Grid item xs={6} sm={3} lg={2} xl={2}>
                                <ArgonBox>
                                  <ArgonButton
                                    variant="outlined"
                                    color="dark"
                                    size="medium"
                                    rel="noreferrer"
                                    disabled={true}
                                  >
                                    {doc.name}
                                  </ArgonButton>
                                </ArgonBox>
                              </Grid>
                              <Grid item xs={12} sm={6} lg={6} xl={4}>
                                <ArgonBox>
                                  <ArgonButton
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    rel="noreferrer"
                                    fullWidth
                                    onClick={() => showPopupReward(doc, index + 1)}
                                  >
                                    {!doc.reward ? (
                                      <>
                                        <Icon sx={{ marginRight: "10px", float: "right" }}>
                                          add
                                        </Icon>
                                        Add reward configuration
                                      </>
                                    ) : (
                                      <>
                                        <Icon sx={{ marginRight: "10px", float: "right" }}>
                                          edit
                                        </Icon>
                                        {`${doc.reward.name} -
                                    ${RewardTypeLabel[doc.reward.rewardType]}`}
                                      </>
                                    )}
                                  </ArgonButton>
                                </ArgonBox>
                              </Grid>
                              <Grid item xs={6}></Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                    <Grid item xs={12}>
                      <ArgonBox sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                        <ArgonButton
                          m={3}
                          color="info"
                          className="btn"
                          variant="contained"
                          type="submit"
                          startIcon={isLoading && <CircularProgress size={20} />}
                          disabled={isLoading}
                        >
                          Save
                        </ArgonButton>
                        <ArgonButton
                          ml={3}
                          color="info"
                          className="btn"
                          variant="outlined"
                          onClick={onBack}
                          startIcon={isLoading && <CircularProgress size={20} />}
                        >
                          Cancel
                        </ArgonButton>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
            {/* Reward */}
          </ArgonBox>
        </FormProvider>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
