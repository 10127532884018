import { toast } from "react-toastify";
import { RankPoint } from "../constant/constant";
import { useEffect, useRef } from "react";

export const isImage = (file) => {
  const fileType = file["type"];
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  if (!validImageTypes.includes(fileType)) {
    // invalid file type code goes here.
    toast.error("Unknown image file format. One of JPEG, PNG, GIF required.");
    return false;
  }
  return true;
};

export const getRankByPoint = (point) => {
  if (!point || point < 0) {
    return "";
  }
  const listPointRank = RankPoint.filter((doc) => Number(point) >= doc.point);
  const points = listPointRank.map((doc) => doc.point);
  const indexMax = points.indexOf(Math.max(...points));
  return listPointRank[indexMax].label;
};

export const getQuerySearch = (str) => {
  if (str) {
    str = decodeURIComponent(decodeURI(encodeURI(str)));
    str = str.toString().slice(1);
    const arrs = str.split("&");
    const obj = {};
    arrs.map((doc) => {
      let keys = doc.split("=");
      if (
        [
          "lastActivityFrom",
          "lastActivityTo",
          "createdFrom",
          "createdTo",
          "durationFrom",
          "durationTo",
        ].includes(keys[0]) &&
        !isNaN(Date.parse(keys[1])) &&
        keys[1].length > 4
      ) {
        obj[keys[0].trim()] = new Date(keys[1]);
      } else {
        obj[keys[0].trim()] = keys[1].trim();
      }
    });
    return obj;
  }
};

export const convertQuery = (obj) => {
  let str =
    "?" +
    Object.keys(obj)
      .reduce(function (a, k) {
        a.push(k + "=" + encodeURIComponent(obj[k]));
        return a;
      }, [])
      .join("&");
  return str;
};
