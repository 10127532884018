import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Argon Dashboard 2 MUI themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Argon Dashboard 2 MUI routes
import routes from "routes";

// Argon Dashboard 2 MUI contexts
import { useArgonController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";

// Icon Fonts
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";
import "assets/css/custom.css";
import { QueryClient, QueryClientProvider, QueryCache } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axios = require("axios");

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error) => {
      if (error.response && error.response.data && error.response.data.statusCode === 401) {
        let errorRefresh = true;
        if (localStorage.getItem("refreshed")) {
          errorRefresh = false;
        }
        if (localStorage.getItem("refreshToken") && !localStorage.getItem("refreshed")) {
          localStorage.setItem("refreshed", "true");
          try {
            const res = await axios.post(`/api/user/refresh-access-token`, {
              refreshToken: localStorage.getItem("refreshToken"),
            });
            if (res.data && res.data.accessToken) {
              localStorage.setItem("accessToken", res.data.accessToken);
              localStorage.setItem("refreshed", "");
              errorRefresh = false;
              window.location.reload();
            } else {
              errorRefresh = true;
            }
          } catch (e) {
            errorRefresh = true;
          }
          if (res.data && res.data.accessToken) {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshed", "");
            errorRefresh = false;
            window.location.reload();
          } else {
            errorRefresh = true;
          }
        }
        if (errorRefresh) {
          localStorage.setItem("accessToken", "");
          localStorage.setItem("refreshed", "");
          localStorage.setItem("refreshToken", "");
          window.location.replace("/login");
        }
      }
      toast.error(
        `Something went wrong: ${
          error.response && error.response.data ? error.response.data.message : ""
        }`
      );
    },
  }),
});

export default function App() {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, layout, openConfigurator, sidenavColor, darkSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const ProtectedRoute = (children) => {
    if (localStorage.getItem("accessToken") === null) {
      return <Navigate to="/login" replace={true} />;
    }

    return children;
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return route.login ? (
          <Route
            exact
            path={route.route}
            element={ProtectedRoute(route.component)}
            key={route.key}
          />
        ) : (
          <Route exact path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });

  const getRoutesSildeNav = (allRoutes) => {
    return allRoutes.filter((doc) => !doc.hideSidenav);
  };

  const configsButton = (
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </ArgonBox>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={darkSidenav || darkMode ? brand : brandDark}
              brandName="INTERNAL CONFLICT"
              routes={getRoutesSildeNav(routes)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
