// @mui material components
import Card from "@mui/material/Card";
import {
  Grid,
  Icon,
  TextField,
  CircularProgress,
  Checkbox,
  Radio,
  FormControlLabel,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { banCardSchema } from "./schemas";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGetCards, useAddBanCard } from "../../../queries/cards.queries";
import { useGetEvents } from "../../../queries/events.queries";
import Select2 from "react-select";
import { startOfDay, endOfDay } from "../../../utils/formatDate.ts";
import { toast } from "react-toastify";

function Default() {
  const navigate = useNavigate();
  const [q, setQ] = useState("");
  const [qEvent, setQEvent] = useState("");
  const [listCards, setListCards] = useState([]);
  const [listEvents, setListEvents] = useState([]);

  const { data: dataCards, refetch } = useGetCards({
    page: 1,
    perPage: 50,
    q,
  });

  const { data: dataEvents, refetch: refetchEvent } = useGetEvents({
    page: 1,
    perPage: 50,
    qEvent,
  });

  const methods = useForm({
    resolver: yupResolver(banCardSchema),
    mode: "onChange",
    defaultValues: {
      card: null,
      event: null,
      isBanGame: false,
      isBanUnrank: false,
      isBanRank: false,
      isBanChallenge: false,
      isBanEvent: true,
      banGame_durationFrom: "",
      banGame_durationTo: "",
      banUnrank_durationFrom: "",
      banUnrank_durationTo: "",
      banRank_durationFrom: "",
      banRank_durationTo: "",
      banChallenge_durationFrom: "",
      banChallenge_durationTo: "",
      banEvent_durationFrom: "",
      banEvent_durationTo: "",
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    formState: { errors, isValid },
    reset,
    watch,
  } = methods;

  const { mutate, isSuccess, isLoading, isError } = useAddBanCard();

  const onBack = () => {
    navigate(-1);
  };

  const onSubmit = handleSubmit((data) => {
    trigger();
    let dataForm = {};
    for (let key in data) {
      if (key.includes("_")) {
        let arrs = key.split("_");
        if (data[key]) {
          arrs[1] === "durationFrom"
            ? (data[key] = startOfDay(data[key]))
            : (data[key] = endOfDay(data[key]));
        }

        if (data[arrs[0]]) {
          data[arrs[0]][arrs[1]] = data[key];
        } else {
          data[arrs[0]] = {
            [arrs[1]]: data[key],
          };
        }
        delete data[key];
      }
    }
    for (let key in data) {
      if (key === "card") {
        dataForm.serial_number = data[key].value;
        dataForm.name = data[key].name;
      } else if (data[key]) {
        dataForm[key] = data[key];
      } else if (["banGame", "banUnrank", "banRank", "banChallenge", "banEvent"].includes(key)) {
        dataForm[key] = null;
      }
    }
    if (!data.isBanGame) dataForm.banGame = null;
    if (!data.isBanUnrank) dataForm.banUnrank = null;
    if (!data.isBanRank) dataForm.banRank = null;
    if (!data.isBanChallenge) dataForm.banChallenge = null;
    if (!data.isBanEvent) dataForm.banEvent = null;
    if (dataForm.banEvent) {
      dataForm.banEvent.eventIds = data.event ? data.event.map((doc) => doc.value) : [];
    }
    if (
      !dataForm.banGame &&
      !dataForm.banUnrank &&
      !dataForm.banRank &&
      !dataForm.banChallenge &&
      !dataForm.banEvent
    ) {
      toast.error("Select at least 1 option");
      return;
    }
    delete dataForm.isBanGame;
    delete dataForm.isBanUnrank;
    delete dataForm.isBanRank;
    delete dataForm.isBanChallenge;
    delete dataForm.isBanEvent;
    delete dataForm.event;
    mutate(dataForm);
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Create successfully");
      navigate("/cards");
    }
  }, [isSuccess, navigate]);

  const changeInputSelect = (e) => {
    setQ(e);
    refetch();
  };
  const changeInputSelectEvent = (e) => {
    setQEvent(e);
    refetchEvent();
  };
  useEffect(() => {
    if (dataCards && Array.isArray(dataCards.rows)) {
      const rows = dataCards.rows.map((item) => {
        return {
          value: item.serial_number,
          label: `${item.serial_number} - ${item.name}`,
          name: item.name,
        };
      });
      setListCards(rows);
    }
  }, [dataCards]);

  useEffect(() => {
    if (dataEvents && Array.isArray(dataEvents.rows)) {
      const rows = dataEvents.rows.map((item) => {
        return {
          value: item._id,
          label: `Event ${item._id} - ${item.name}`,
        };
      });
      setListEvents(rows);
    }
  }, [dataEvents]);

  const watchBanGame = watch("isBanGame");
  const watchBanUnrank = watch("isBanUnrank");
  const watchBanRank = watch("isBanRank");
  const watchBanChallenge = watch("isBanChallenge");
  const watchBanEvent = watch("isBanEvent");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <FormProvider {...methods}>
          <ArgonBox mb={3} component="form" onSubmit={onSubmit}>
            <ArgonBox mb={3} style={{ minHeight: "calc(100vh - 2rem)" }}>
              <Card style={{ overflow: "unset" }}>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <Typography variant="h6">Add Ban Card</Typography>
                </ArgonBox>
                <ArgonBox ml={3} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ArgonBox>
                        <Label required={true}>Select Card</Label>
                        <Controller
                          name="card"
                          control={control}
                          render={({ field, fieldState }) => (
                            <ArgonBox>
                              <Select2
                                {...field}
                                className="input-cont"
                                placeholder="Search Card"
                                options={listCards}
                                onInputChange={changeInputSelect}
                              />

                              <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                {fieldState.error?.message}
                              </p>
                            </ArgonBox>
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} />
                    {/* Game system */}
                    <Grid item xs={4} sm={4} lg={2}>
                      <ArgonBox mb={3}>
                        <Label>Game system</Label>
                        <ArgonBox mb={3}>
                          <Controller
                            name="isBanGame"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <Checkbox checked={getValues("isBanGame")} {...field} />
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {watchBanGame && (
                      <Grid item xs={8} sm={8} lg={10}>
                        <ArgonBox>
                          <Label>&nbsp;</Label>
                          <ArgonBox sx={{ display: "flex", gap: 1 }}>
                            <Controller
                              name="banGame_durationFrom"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    defaultValues={null}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                            <Controller
                              name="banGame_durationTo"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    )}
                    <Grid item xs={12} />
                    {/* Game system */}
                    {/* Unrank mode */}
                    <Grid item xs={4} sm={4} lg={2}>
                      <ArgonBox mb={3}>
                        <Label>Unrank mode</Label>
                        <ArgonBox mb={3}>
                          <Controller
                            name="isBanUnrank"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <Checkbox checked={getValues("isBanUnrank")} {...field} />
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {watchBanUnrank && (
                      <Grid item xs={8} sm={8} lg={10}>
                        <ArgonBox>
                          <Label>&nbsp;</Label>
                          <ArgonBox sx={{ display: "flex", gap: 1 }}>
                            <Controller
                              name="banUnrank_durationFrom"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    defaultValues={null}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                            <Controller
                              name="banUnrank_durationTo"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    )}
                    <Grid item xs={12} />
                    {/* Unrank mode */}
                    {/* Rank mode */}
                    <Grid item xs={4} sm={4} lg={2}>
                      <ArgonBox mb={3}>
                        <Label>Rank mode</Label>
                        <ArgonBox mb={3}>
                          <Controller
                            name="isBanRank"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <Checkbox checked={getValues("isBanRank")} {...field} />
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {watchBanRank && (
                      <Grid item xs={8} sm={8} lg={10}>
                        <ArgonBox>
                          <Label>&nbsp;</Label>
                          <ArgonBox sx={{ display: "flex", gap: 1 }}>
                            <Controller
                              name="banRank_durationFrom"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    defaultValues={null}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                            <Controller
                              name="banRank_durationTo"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    )}
                    <Grid item xs={12} />
                    {/* Rank mode */}
                    {/* Challenge Mode */}
                    <Grid item xs={4} sm={4} lg={2}>
                      <ArgonBox mb={3}>
                        <Label>Challenge Mode</Label>
                        <ArgonBox mb={3}>
                          <Controller
                            name="isBanChallenge"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <Checkbox checked={getValues("isBanChallenge")} {...field} />
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {watchBanChallenge && (
                      <Grid item xs={8} sm={8} lg={10}>
                        <ArgonBox>
                          <Label>&nbsp;</Label>
                          <ArgonBox sx={{ display: "flex", gap: 1 }}>
                            <Controller
                              name="banChallenge_durationFrom"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    defaultValues={null}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                            <Controller
                              name="banChallenge_durationTo"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        sx={{ root: { height: "50px" } }}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    )}
                    <Grid item xs={12} />
                    {/* Challenge Mode */}
                    {/* Event mode */}
                    <Grid item xs={4} sm={4} lg={2}>
                      <ArgonBox mb={3}>
                        <Label>Event mode</Label>
                        <ArgonBox mb={3}>
                          <Controller
                            name="isBanEvent"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <Checkbox checked={getValues("isBanEvent")} {...field} />
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {watchBanEvent && (
                      <>
                        <Grid item xs={8} sm={8} lg={10}>
                          <ArgonBox>
                            <Label>&nbsp;</Label>
                            <ArgonBox sx={{ display: "flex", gap: 1 }}>
                              <Controller
                                name="banEvent_durationFrom"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                      inputFormat="DD/MM/YYYY"
                                      {...field}
                                      defaultValues={null}
                                      renderInput={(params) => (
                                        <TextField
                                          fullWidth
                                          {...params}
                                          sx={{ root: { height: "50px" } }}
                                          helperText={error?.message ? error?.message : null}
                                          error={invalid}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                              <Controller
                                name="banEvent_durationTo"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                      inputFormat="DD/MM/YYYY"
                                      {...field}
                                      renderInput={(params) => (
                                        <TextField
                                          fullWidth
                                          {...params}
                                          sx={{ root: { height: "50px" } }}
                                          helperText={error?.message ? error?.message : null}
                                          error={invalid}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={4} sm={4} lg={2}></Grid>
                        <Grid item xs={8} sm={8} lg={10}>
                          <ArgonBox>
                            <Label>Select Event</Label>
                            <Controller
                              name="event"
                              control={control}
                              render={({ field, fieldState }) => (
                                <ArgonBox>
                                  <Select2
                                    {...field}
                                    className="input-cont"
                                    placeholder="All event"
                                    options={listEvents}
                                    isMulti
                                    onInputChange={changeInputSelectEvent}
                                  />

                                  <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                    {fieldState.error?.message}
                                  </p>
                                </ArgonBox>
                              )}
                            />
                          </ArgonBox>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} />
                    {/* Event mode */}
                    <Grid item xs={12}>
                      <ArgonBox sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                        <ArgonButton
                          m={3}
                          color="info"
                          className="btn"
                          variant="contained"
                          type="submit"
                          startIcon={isLoading && <CircularProgress size={20} />}
                          disabled={isLoading}
                        >
                          Save
                        </ArgonButton>
                        <ArgonButton
                          ml={3}
                          color="info"
                          className="btn"
                          variant="outlined"
                          onClick={onBack}
                          startIcon={isLoading && <CircularProgress size={20} />}
                        >
                          Cancel
                        </ArgonButton>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>
        </FormProvider>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
