// @mui material components
import { Card, Grid, InputLabel } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import { FormProvider, useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { useGetGraphPlayMode } from "../../../queries/dashboard.queries";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import {
  MatchTypeList,
  EndGameTypeList,
  PlayerTypeList,
  MatchType,
} from "../../../constant/constant";
import DoughnutCharts from "../../../examples/Charts/DoughnutCharts";
import { GraphPlayModeData, GraphKindOfDeckData, GraphKindOfBattleData } from "../data/index";
import { subDate, startOfDay, endOfDay } from "../../../utils/formatDate.ts";
import { KindOfDeckColumns } from "../../../mock/data-dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function Default() {
  const [matchType, setMatchType] = useState(1);
  const [q, setQ] = useState({});
  const [viewDetail, setViewDetail] = useState(false);
  const {
    data: dataGraph,
    refetch,
    isLoading,
  } = useGetGraphPlayMode({
    ...q,
  });

  let graphPlayModeData = { ...GraphPlayModeData };
  let graphKindOfDeckData = { ...GraphKindOfDeckData };
  let graphKindOfBattleData = { ...GraphKindOfBattleData };
  let tableDeck = [];
  let tableBattle = [];
  const getDataGraphKind = () => {
    graphKindOfDeckData.labels = PlayerTypeList.map((doc) => doc.label);
    graphKindOfDeckData.datasets[0].data = PlayerTypeList.map((doc) => {
      let count = 0;
      dataGraph.kindOfDeck.map((item) => {
        if (item.type === doc.value) {
          item.matchs.map((match) => {
            if (match.mathType === matchType) {
              count = match.count;
            }
          });
        }
      });
      return count;
    });
    graphKindOfBattleData.labels = EndGameTypeList.map((doc) => doc.label);
    graphKindOfBattleData.datasets[0].data = EndGameTypeList.map((doc) => {
      let count = 0;
      dataGraph.kindOfEnd.map((item) => {
        if (item.type === doc.value) {
          item.matchs.map((match) => {
            if (match.mathType === matchType) {
              count = match.count;
            }
          });
        }
      });
      return count;
    });
  };
  if (dataGraph) {
    graphPlayModeData.labels = MatchTypeList.map((doc) => doc.label);
    graphPlayModeData.datasets[0].data = MatchTypeList.map((doc) => {
      let count = 0;
      dataGraph.playerMode.map((item) => {
        if (item.mode === doc.value) {
          count = item.number;
        }
      });
      return count;
    });
    getDataGraphKind();
    tableDeck = PlayerTypeList.map((doc, index) => {
      const obj = {};
      for (let key in MatchType) {
        let count = 0;
        dataGraph.kindOfDeck.map((item) => {
          if (item.type === doc.value) {
            item.matchs.map((match) => {
              if (match.mathType === MatchType[key]) {
                count = match.count;
              }
            });
          }
        });
        obj[key] = count;
      }
      return {
        _id: index + 1,
        title: doc.label,
        ...obj,
      };
    });
    tableBattle = EndGameTypeList.map((doc, index) => {
      const obj = {};
      for (let key in MatchType) {
        let count = 0;
        dataGraph.kindOfEnd.map((item) => {
          if (item.type === doc.value) {
            item.matchs.map((match) => {
              if (match.mathType === MatchType[key]) {
                count = match.count;
              }
            });
          }
        });
        obj[key] = count;
      }
      return {
        _id: index + 1,
        title: doc.label,
        ...obj,
      };
    });
  }

  const defaultValues = {
    fromDate: "",
    toDate: "",
    timeStart: "",
    timeTo: "",
  };
  const { control, handleSubmit } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        fromDate: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          }),
        toDate: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          })
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.fromDate)) ||
              new Date(value) >= new Date(contextTest.parent.fromDate)
            );
          }),
        timeStart: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          }),
        timeTo: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          })
          .test("gatherTimeThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.timeStart ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.timeStart)) ||
              new Date(value) >= new Date(contextTest.parent.timeStart)
            );
          }),
      })
    ),
    mode: "onChange",
  });

  const onSubmit = handleSubmit((data) => {
    const query = {};
    for (let key in defaultValues) {
      if (data[key]) {
        if (key === "fromDate") {
          query[key] = startOfDay(data[key]);
        } else if (key === "toDate") {
          query[key] = endOfDay(data[key]);
        } else {
          query[key] = data[key];
        }
      }
    }
    setQ(query);
    refetch();
  });

  const updateMatchType = (value) => {
    setMatchType(value);
    getDataGraphKind();
  };

  return (
    <Card style={{ padding: "10px" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pt={0}
        pr={0}
        pb={1}
      >
        <ArgonTypography variant="h6">Graph of Play modes</ArgonTypography>
        <ArgonButton
          onClick={() => setViewDetail(!viewDetail)}
          variant="text"
          color="info"
          size="medium"
          rel="noreferrer"
        >
          {!viewDetail ? `View Detail >` : `Hide Detail`}
        </ArgonButton>
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pb={1} ml={3}>
        <FormProvider onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1} rowSpacing={0.1}>
            <Grid item xs={12} sm={6} lg={6}>
              <ArgonBox mb={3}>
                <InputLabel>Date range</InputLabel>
                <ArgonBox sx={{ display: "flex", gap: 1 }}>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        {...field}
                        defaultValues={null}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD/MM/YYYY",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD/MM/YYYY",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <ArgonBox mb={3}>
                <InputLabel>Time range</InputLabel>
                <ArgonBox sx={{ display: "flex", gap: 1 }}>
                  <Controller
                    name="timeStart"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TimePicker
                        inputFormat="HH:mm:ss"
                        {...field}
                        defaultValues={null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "HH:MM:SS",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="timeTo"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TimePicker
                        inputFormat="HH:mm:ss"
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "HH:MM:SS",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={2}>
              <InputLabel> &nbsp;</InputLabel>
              <ArgonBox pr={1}>
                <ArgonButton
                  type="submit"
                  onClick={onSubmit}
                  variant="outlined"
                  color="dark"
                  size="medium"
                  rel="noreferrer"
                >
                  Filter
                </ArgonButton>
              </ArgonBox>
            </Grid>
          </Grid>
        </FormProvider>
      </ArgonBox>
      <DoughnutCharts chart={graphPlayModeData} height="500px" />
      {viewDetail && (
        <>
          <ArgonBox justifyContent="space-between" textAlign="center" pb={1} ml={3}>
            {MatchTypeList &&
              MatchTypeList.map((timeChart, index) => (
                <ArgonButton
                  key={index}
                  sx={{ marginLeft: "15px", marginRight: "15px" }}
                  type="submit"
                  onClick={() => updateMatchType(timeChart.value)}
                  variant={timeChart.value === matchType ? "contained" : "outlined"}
                  color={timeChart.value === matchType ? "warning" : "dark"}
                  size="medium"
                  rel="noreferrer"
                >
                  {timeChart.label}
                </ArgonButton>
              ))}
          </ArgonBox>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <DoughnutCharts chart={graphKindOfDeckData} />
              <ArgonBox
                pb={3}
                ml={3}
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <DataGrid
                  autoHeight
                  className="datagrid"
                  rows={tableDeck}
                  rowCount={tableDeck.length}
                  columns={KindOfDeckColumns}
                  rowsPerPageOptions={[]}
                  getRowId={(row) => row._id}
                />
              </ArgonBox>
            </Grid>
            <Grid item xs={6}>
              <DoughnutCharts chart={graphKindOfBattleData} />
              <ArgonBox
                pb={3}
                ml={3}
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <DataGrid
                  autoHeight
                  className="datagrid"
                  rows={tableBattle}
                  rowCount={tableBattle.length}
                  columns={KindOfDeckColumns}
                  getRowId={(row) => row._id}
                  rowsPerPageOptions={[]}
                />
              </ArgonBox>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
}

export default Default;
