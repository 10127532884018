import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useGetRewards } from "../../../queries/reward.queries";
import { useState, useEffect } from "react";
import { rewardColumns } from "../../../mock/data-reward";
import { Link } from "react-router-dom";

export const RewardConfigPopup = (props) => {
  const { open, onClose, reward, onSelectReward } = props;
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sortModel, setSortModel] = useState({
    sort: "",
    model: [],
  });
  const [q, setQ] = useState({});
  const [updateNavigate, setUpdateNavigate] = useState(false);

  const closeDialog = () => {
    setPageCurrent(1);
    onClose();
  };
  let {
    data: dataRewards,
    refetch,
    isLoading,
  } = useGetRewards({
    page: pageCurrent,
    perPage: 10,
    sort: sortModel.sort,
    ...q,
  });

  const pageChange = (page) => {
    setPageCurrent(page + 1);
    refetch();
  };

  const onSelect = (dataReward) => {
    onSelectReward(dataReward);
    closeDialog();
  };

  const handleSortModelChange = (value) => {
    let sort = "";
    if (value && value[0]) {
      if (value[0].sort === "asc") {
        sort = value[0].field;
      } else {
        sort = "-" + value[0].field;
      }
    }
    setUpdateNavigate(true);
    setSortModel({
      sort,
      model: value,
    });
    refetch();
  };

  rewardColumns.map((item) => {
    if (item.field === "action") {
      item.width = 200;
      item.renderCell = function (params) {
        const isNotSelected = !reward || params.row._id != reward?._id;
        return (
          <ArgonBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <ArgonButton
              variant="outlined"
              color={isNotSelected ? "dark" : "success"}
              onClick={() => {
                onSelect(params.row);
              }}
              disabled={!isNotSelected}
            >
              Select
            </ArgonButton>
            <ArgonButton
              target="_blank"
              variant="outlined"
              color="dark"
              sx={{ marginLeft: "10px" }}
              component={Link}
              to={`/reward/${params.row._id}`}
            >
              View
            </ArgonButton>
          </ArgonBox>
        );
      };
    }
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "85%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {closeDialog ? (
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                fontSize: 28,
                color: "#333",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Reward lists</Typography>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <DataGrid
              autoHeight
              className="datagrid"
              paginationMode="server"
              rows={dataRewards ? dataRewards.rows : []}
              loading={isLoading}
              rowCount={dataRewards ? dataRewards.total : 0}
              onPageChange={(newPage) => pageChange(newPage)}
              columns={rewardColumns}
              pageSize={10}
              getRowId={(row) => row._id}
              pagination
              sortingMode="server"
              sortModel={sortModel.model}
              onSortModelChange={handleSortModelChange}
            />
          </ArgonBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
