export const ListStatusUserList = [
  { value: "all", label: "All" },
  { value: "offline", label: "Offline" },
  { value: "online", label: "Online" },
  { value: "away", label: "Away" },
];

export const RankList = [
  { value: "all", label: "All" },
  { value: 1, label: "Iron League" },
  { value: 2, label: "Bronze League" },
  { value: 3, label: "Silver League" },
  { value: 4, label: "Gold League" },
  { value: 5, label: "Platinum League" },
  { value: 6, label: "Diamond League" },
  { value: 7, label: "Master League" },
  { value: 8, label: "Mythical League" },
];

export const RankOnly = [
  { value: 1, label: "Iron" },
  { value: 2, label: "Bronze" },
  { value: 3, label: "Silver" },
  { value: 4, label: "Gold" },
  { value: 5, label: "Platinum" },
  { value: 6, label: "Diamond" },
  { value: 7, label: "Master" },
  { value: 8, label: "Mythical" },
];

export const YesNoList = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const Reward = { RANDOM: "Random", BASE: "Base on dominant trait of the NFT used" };

export const QuestType = {
  PLAY_X_GAME: "Play X games",
  WIN_X_GAME: "Win X games",
  PLAY_X_SHOT_CARD: "Play X Shot cards",
  DRAW_X_CARD: "Draw X cards",
  PLAY_X_CARD_SPECIFIC_CONST: "Play X cards that has specific Cost",
  PLAY_X_CARD_SPECIFIC_TYPE: "Play X cards that have specific Type",
  PLAY_X_CARD_SPECIFIC_MUTATION: "Play X cards that has specific Mutation",
  PLAY_X_CARD_SPECIFIC_TRIBE: "Play X cards that has specific Tribe",
};

export const ListStatusQuestList = [
  { value: "all", label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
  { value: 3, label: "Stopped" },
];

export const StatusQuest = {
  ACTIVE: 1,
  INACTIVE: 2,
  STOPPED: 3,
};

export const TypeAction = {
  DELETE: 1,
  STOP: 2,
};

export const RewardTypeList = [
  { value: "all", label: "All" },
  { value: 0, label: "Token" },
  { value: 1, label: "Card" },
  { value: 2, label: "Pod" },
  { value: 3, label: "Card Sleeves" },
  { value: 4, label: "Battlegrounds" },
  { value: 8, label: "Mutatuion Point" },
];

export const RewardType = {
  TOKEN: 0,
  CARD: 1,
  POD: 2,
  CARD_SLEEVES: 3,
  BATTLE_GROUND: 4,
  MUTATUION_POINT: 8,
};

export const RewardTypeLabel = {
  0: "Token",
  1: "Card",
  2: "Pod",
  3: "Card Sleeves",
  4: "Battlegrounds",
  8: "Mutatuion point",
};

export const ListQuestType = [
  { value: "all", label: "All" },
  { value: 1, label: "Play x games" },
  { value: 2, label: "Win x games" },
  { value: 3, label: "Play x shot cards" },
  { value: 4, label: "Draw x cards" },
  { value: 5, label: "Play x cards that have specific type" },
  { value: 6, label: "Play x cards that has specific mutation" },
  { value: 7, label: "Play x cards that has specific tribe" },
  { value: 8, label: "Play x cards that has specific cost" },
];

export const RewardList = [
  { value: "RANDOM", label: "Random" },
  { value: "BASE", label: "Base on dominant trait of the NFT used" },
];

export const RewardMutationList = [
  { value: 1, label: "Random" },
  { value: 2, label: "Spectify" },
];

export const RewardMutationEnum = {
  RANDOM: 1,
  SPECTIFY: 2,
};

export const CardType = [
  {
    value: "supacell",
    label: "Supacell",
  },
  {
    value: "virus",
    label: "Virus",
  },
];

export const MutationTypeList = [
  { value: 0, label: "WH" },
  { value: 1, label: "GR" },
  { value: 2, label: "BU" },
  { value: 3, label: "BK" },
  { value: 4, label: "PR" },
  { value: 5, label: "RD" },
  { value: 6, label: "OR" },
  // { value: 7, label: "CL" },
];

export const MutationType = {
  WH: 0,
  GR: 1,
  BU: 2,
  BK: 3,
  PR: 4,
  RD: 5,
  OR: 6,
  // CL: 7,
};

export const MutationTypeLabel = {
  0: "WH",
  1: "GR",
  2: "BU",
  3: "BK",
  4: "PR",
  5: "RD",
  6: "OR",
  // 7: "CL",
};

export const RarityList = [
  { value: "BASIC", label: "Basic" },
  { value: "RARE", label: "Rare" },
  { value: "EPIC", label: "Epic" },
  { value: "MYTHICAL", label: "Mythical" },
];

export const RewardCardTypeList = [
  { value: "all", label: "All" },
  { value: 1, label: "Random" },
  { value: 2, label: "Specify" },
];

export const TribeType = [
  { value: "tres", label: "TRES" },
  { value: "quad", label: "QUAD" },
  { value: "hexal", label: "HEXAL" },
  { value: "penta", label: "PENTA" },
  { value: "pointer", label: "POINTER" },
  { value: "nova", label: "NOVA" },
  { value: "love", label: "LOVE" },
  { value: "luna", label: "LUNA" },
  { value: "sun", label: "SUN" },
];

export const CostComparisonList = [
  { value: "LESS", label: "LESS" },
  { value: "EQUAL", label: "EQUAL" },
  { value: "MORE", label: "MORE" },
];

export const ListStatusEventList = [
  { value: "all", label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];

export const ListOperationEventList = [
  { value: "all", label: "All" },
  { value: 1, label: "Not start" },
  { value: 2, label: "Running" },
  { value: 3, label: "Stopped" },
  { value: 4, label: "Expired" },
  { value: 5, label: "Preparing" },
];

export const ListOperationRankList = [
  { value: "all", label: "All" },
  { value: 1, label: "Happening" },
  { value: 2, label: "Closed" },
  { value: 3, label: "Expired" },
  { value: 4, label: "Pending" },
];

export const ListOperationEvent = {
  NOT_START: 1,
  RUNNING: 2,
  STOPPED: 3,
  EXPIRED: 4,
  PREPARING: 5,
};

export const ListOperationRank = {
  HAPPENING: 1,
  CLOSED: 2,
  EXPIRED: 3,
  PENDING: 4,
};

export const RankPoint = [
  { point: 0, label: "Iron 5" },
  { point: 140, label: "Iron 4" },
  { point: 280, label: "Iron 3" },
  { point: 420, label: "Iron 2" },
  { point: 560, label: "Iron 1" },
  { point: 700, label: "Bronze 5" },
  { point: 840, label: "Bronze 4" },
  { point: 980, label: "Bronze 3" },
  { point: 1120, label: "Bronze 2" },
  { point: 1260, label: "Bronze 1" },
  { point: 1400, label: "Silver 5" },
  { point: 1540, label: "Silver 4" },
  { point: 1680, label: "Silver 3" },
  { point: 1820, label: "Silver 2" },
  { point: 1960, label: "Silver 1" },
  { point: 2100, label: "Gold 5" },
  { point: 2260, label: "Gold 4" },
  { point: 2420, label: "Gold 3" },
  { point: 2580, label: "Gold 2" },
  { point: 2740, label: "Gold 1" },
  { point: 2900, label: "Platinum 5" },
  { point: 3060, label: "Platinum 4" },
  { point: 3220, label: "Platinum 3" },
  { point: 3380, label: "Platinum 2" },
  { point: 3540, label: "Platinum 1" },
  { point: 3700, label: "Diamond 5" },
  { point: 3880, label: "Diamond 4" },
  { point: 4060, label: "Diamond 3" },
  { point: 4240, label: "Diamond 2" },
  { point: 4420, label: "Diamond 1" },
  { point: 4600, label: "Master 5" },
  { point: 4800, label: "Master 4" },
  { point: 5000, label: "Master 3" },
  { point: 5200, label: "Master 2" },
  { point: 5400, label: "Master 1" },
  { point: 5600, label: "Mythical Rank" },
];

export const TimeChart = {
  Hour: 1,
  Day: 2,
  Month: 3,
  Year: 4,
};

export const TimeChartList = [
  { value: 1, label: "Hour" },
  { value: 2, label: "Day" },
  { value: 3, label: "Month" },
  { value: 4, label: "Year" },
];

export const AgeGroupList = [
  { value: "all", label: "All" },
  { value: "15-21", label: "15-21" },
  { value: "22-27", label: "22-27" },
  { value: "28-34", label: "28-34" },
  { value: "35-44", label: "35-44" },
  { value: "45-54", label: "45-54" },
  { value: "55-64", label: "55-64" },
  { value: "65+", label: "65+" },
];

export const MatchTypeList = [
  { value: 1, label: "Unrank" },
  { value: 2, label: "Rank" },
  { value: 3, label: "Event" },
  { value: 4, label: "Tournament" },
  { value: 5, label: "Friend challenge" },
];

export const MatchType = {
  unranked: 1,
  ranked: 2,
  event: 3,
  tournament: 4,
  challenge: 5,
};

export const EndGameTypeList = [
  { value: 1, label: "Runs out of health" },
  { value: 2, label: "Deloy enough cards" },
  { value: 3, label: "AFK" },
  { value: 4, label: "Concede in 75s" },
  { value: 5, label: "Concede after 75s" },
];

export const PlayerTypeList = [
  { value: 1, label: "Virus" },
  { value: 2, label: "Supacell" },
];

export const CountryList = [
  { value: "", label: "" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas (the)", label: "Bahamas (the)" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia (Plurinational State of)", label: "Bolivia (Plurinational State of)" },
  { value: "Bonaire, Sint Eustatius and Saba", label: "Bonaire, Sint Eustatius and Saba" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  { value: "British Indian Ocean Territory (the)", label: "British Indian Ocean Territory (the)" },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands (the)", label: "Cayman Islands (the)" },
  { value: "Central African Republic (the)", label: "Central African Republic (the)" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos (Keeling) Islands (the)", label: "Cocos (Keeling) Islands (the)" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros (the)", label: "Comoros (the)" },
  {
    value: "Congo (the Democratic Republic of the)",
    label: "Congo (the Democratic Republic of the)",
  },
  { value: "Congo (the)", label: "Congo (the)" },
  { value: "Cook Islands (the)", label: "Cook Islands (the)" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic (the)", label: "Dominican Republic (the)" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands (the) [Malvinas]", label: "Falkland Islands (the) [Malvinas]" },
  { value: "Faroe Islands (the)", label: "Faroe Islands (the)" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  { value: "French Southern Territories (the)", label: "French Southern Territories (the)" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia (the)", label: "Gambia (the)" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands" },
  { value: "Holy See (the)", label: "Holy See (the)" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran (Islamic Republic of)", label: "Iran (Islamic Republic of)" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  {
    value: "Korea (the Democratic People's Republic of)",
    label: "Korea (the Democratic People's Republic of)",
  },
  { value: "Korea (the Republic of)", label: "Korea (the Republic of)" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao People's Democratic Republic (the)",
    label: "Lao People's Democratic Republic (the)",
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands (the)", label: "Marshall Islands (the)" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia (Federated States of)", label: "Micronesia (Federated States of)" },
  { value: "Moldova (the Republic of)", label: "Moldova (the Republic of)" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands (the)", label: "Netherlands (the)" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger (the)", label: "Niger (the)" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands (the)", label: "Northern Mariana Islands (the)" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine, State of", label: "Palestine, State of" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines (the)", label: "Philippines (the)" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Republic of North Macedonia", label: "Republic of North Macedonia" },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation (the)", label: "Russian Federation (the)" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Réunion", label: "Réunion" },
  { value: "Saint Barthélemy", label: "Saint Barthélemy" },
  {
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin (French part)", label: "Saint Martin (French part)" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten (Dutch part)", label: "Sint Maarten (Dutch part)" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan (the)", label: "Sudan (the)" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania, United Republic of", label: "Tanzania, United Republic of" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands (the)", label: "Turks and Caicos Islands (the)" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates (the)", label: "United Arab Emirates (the)" },
  {
    value: "United Kingdom of Great Britain and Northern Ireland (the)",
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
  },
  {
    value: "United States Minor Outlying Islands (the)",
    label: "United States Minor Outlying Islands (the)",
  },
  { value: "United States of America (the)", label: "United States of America (the)" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela (Bolivarian Republic of)", label: "Venezuela (Bolivarian Republic of)" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
  { value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
  { value: "Åland Islands", label: "Åland Islands" },
];

export const ListStatusNewsList = [
  { value: "all", label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];

export const ListTypeEventList = [
  { value: "all", label: "All" },
  { value: 1, label: "Event" },
  { value: 2, label: "Tournament" },
];

export const TypeEventList = {
  EVENT: 1,
  TOURNAMENT: 2,
  1: "Event",
  2: "Tournament",
};

export const ListTypeFreeList = [
  { value: "all", label: "All" },
  { value: 1, label: "Free" },
  { value: 2, label: "Token" },
  { value: 3, label: "Mutation" },
];

export const ListTypeFee = {
  Free: 1,
  Token: 2,
  Mutation: 3,
  1: "Free",
  2: "Token",
  3: "Mutation",
};

export const ListPayResetLoseList = [
  { value: "all", label: "All" },
  { value: 0, label: "No" },
  { value: 1, label: "Same entry free" },
  { value: 2, label: "Token" },
  { value: 3, label: "Mutation" },
];

export const PayResetLose = {
  No: 0,
  Same_entry_free: 1,
  Token: 2,
  Mutation: 3,
  0: "No",
  1: "Same entry free",
  2: "Token",
  3: "Mutation",
};

export const YesNoNumberList = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

export const YesNoNumber = {
  0: "No",
  1: "Yes",
  No: 0,
  Yes: 1,
};

export const TypeEventReward = {
  1: "Whole",
  2: "Each",
  WHOLE: 1,
  EACH: 2,
};

export const TypeRankReward = {
  1: "Whole",
  2: "Each",
  WHOLE: 1,
  EACH: 2,
};

export const TypeEventRewardList = [
  { value: 1, label: "Reward for whole event" },
  { value: 2, label: "Reward for each battle" },
];

export const TypeRankRewardList = [
  { value: 1, label: "Reward for all ranks" },
  { value: 2, label: "Reward for each rank" },
];

export const PaidToContinueList = [
  { value: 1, label: "Restart from 1st battle" },
  { value: 2, label: "Continue progress" },
];

export const AfterCompleteList = [
  { value: 1, label: "Not allow replay" },
  { value: 2, label: "Allow replay but no reward" },
  { value: 3, label: "Allow replay but get reward again" },
];

export const BanTypeList = [
  { value: 1, label: "Game system" },
  { value: 2, label: "Unrank mode" },
  { value: 3, label: "Rank mode" },
  { value: 4, label: "Challenge mode" },
  { value: 5, label: "Event mode" },
];

export const HighestLevel = 5;

export const CountMutationType = 8;
