// @mui material components
import Card from "@mui/material/Card";
import {
  Grid,
  Icon,
  TextField,
  CircularProgress,
  Checkbox,
  Radio,
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateUsers } from "queries/users.queries";
import { useGetUsersById, useGetInfoQuestUser } from "queries/users.queries";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { usersSchema } from "./schemas";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ListStatusUserList, CountryList } from "constant/constant";
import { DeckDetailPopup } from "../DeckDetailPopup/index";
import { getRankByPoint } from "../../../utils/utils";
import { formatDate } from "../../../utils/formatDate.ts";

function Default() {
  const { userId } = useParams();
  const { data, refetch } = useGetUsersById(userId);
  const { data: dataQuest } = useGetInfoQuestUser(userId);
  const { user, decks, battle } = data || { user: {}, decks: {}, battle: {} };
  const [dataDeck, setDataDeck] = useState(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(usersSchema),
    mode: "onChange",
    defaultValues: {
      username: "",
      email: "",
      dateOfBirth: "",
      country: "",
      subscribed: "",
      status: "",
      isAdmin: false,
      isBan: false,
      createdAt: "",
      lastActivity: "",
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    formState: { errors, isValid },
    reset,
  } = methods;

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        username: user.username,
        email: user.email,
        dateOfBirth: `${user.monthOfbirthday}/${user.dayOfbirthday}/${user.yearOfbirthday}`,
        isAdmin:
          user.roles && Array.isArray(user.roles) && user.roles.includes("admin") ? true : false,
        isBan: user.isBan || false,
        status: user.status,
        lastActivity: user.lastActivity,
      });
    }
  }, [user]);

  const { mutate, isSuccess, isLoading } = useUpdateUsers(userId);

  const onSubmit = handleSubmit((data) => {
    trigger();
    let roles = user.roles;
    if (data.isAdmin && !user.roles.includes("admin")) {
      roles.push("admin");
    } else if (!data.isAdmin && user.roles.includes("admin")) {
      roles = roles.filter((doc) => doc != "admin");
    }
    mutate({
      roles,
      isBan: data.isBan,
    });
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDetailDeck = (dataD) => {
    setDataDeck(dataD);
    setOpen(true);
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DeckDetailPopup open={open} onClose={handleCloseDialog} title="Tin tức" data={dataDeck} />
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Typography variant="h6">User information</Typography>
            </ArgonBox>
            <FormProvider {...methods}>
              <ArgonBox component="form" onSubmit={onSubmit} ml={3} mr={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label required={true}>Username</Label>
                      <Controller
                        name="username"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            disabled={true}
                            fullWidth
                            size="small"
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            placeholder="Type username..."
                            {...field}
                            sx={{ root: { height: "50px" }, mt: 1 }}
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label required={true}>Email</Label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            disabled={true}
                            fullWidth
                            size="small"
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            placeholder="Type email..."
                            {...field}
                            sx={{ root: { height: "50px" }, mt: 1 }}
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Date of birth</Label>
                      <Controller
                        name="dateOfBirth"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            {...field}
                            disabled={true}
                            renderInput={(params) => (
                              <TextField
                                disabled={true}
                                {...params}
                                size="small"
                                fullWidth
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Type headline..."
                                sx={{ root: { height: "50px" }, mt: 1 }}
                              />
                            )}
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Country</Label>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Select disabled={true} labelId="country-user" {...field} label="Status">
                            {CountryList.map((country, index) => {
                              return (
                                <MenuItem key={"country-" + index} value={country.value}>
                                  {country.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Subscribed</Label>
                      <ArgonBox mb={3}>
                        <Controller
                          name="subscribed"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <Checkbox disabled={true} {...field} />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Is Banned</Label>
                      <ArgonBox mb={3}>
                        <Controller
                          name="isBan"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <Checkbox checked={getValues("isBan")} {...field} />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Is Admin</Label>
                      <ArgonBox mb={3}>
                        <Controller
                          name="isAdmin"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <Checkbox checked={getValues("isAdmin")} {...field} />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Created at</Label>
                      <Typography variant="h6">{formatDate(user.createdAt)}</Typography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ArgonBox mb={3}>
                      <Label>Last activity</Label>
                      <Typography variant="h6">{formatDate(user.lastActivity)}</Typography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ArgonBox
                      mb={3}
                      mr={3}
                      sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}
                    >
                      <ArgonButton
                        m={3}
                        color="info"
                        className="btn"
                        variant="contained"
                        type="submit"
                        startIcon={isLoading && <CircularProgress size={20} />}
                        disabled={isLoading}
                      >
                        Save
                      </ArgonButton>
                      <ArgonButton
                        ml={3}
                        color="info"
                        className="btn"
                        variant="outlined"
                        onClick={onBack}
                        startIcon={isLoading && <CircularProgress size={20} />}
                      >
                        Cancel
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </FormProvider>
          </Card>
        </ArgonBox>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Typography variant="h6">Playing report</Typography>
            </ArgonBox>
            <ArgonBox ml={3} mr={3} mb={2} sx={{ borderBottom: "1px black solid" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ArgonBox mb={3}>
                    <Label>Connected Wallet</Label>
                  </ArgonBox>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <ArgonBox mb={3}>
                    <Typography variant="h6">
                      {user && user.publicAddress ? "Yes" : "No"}
                    </Typography>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox ml={3} mr={3} mb={2} sx={{ borderBottom: "1px black solid" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ArgonBox mb={3}>
                    <Label>Current mutation in game</Label>
                  </ArgonBox>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  {user.mutationPoint &&
                    Object.keys(user.mutationPoint).map((item, index) => (
                      <Grid container spacing={2} key={"mutation-" + index}>
                        <Grid item xs={6}>
                          <ArgonBox mb={3}>
                            <Typography variant="h6">{item}</Typography>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={6}>
                          <ArgonBox mb={3}>
                            <Typography variant="h6">{user.mutationPoint[item]}</Typography>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox ml={3} mr={3} mb={2} sx={{ borderBottom: "1px black solid" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ArgonBox mb={3}>
                    <Label>Last 5 decks</Label>
                  </ArgonBox>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  {Array.isArray(decks) &&
                    decks.map((item, index) => (
                      <Grid container spacing={2} key={"decks-" + index}>
                        <Grid item xs={6}>
                          <ArgonBox mb={3}>
                            <Typography variant="h6">{item.name}</Typography>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={6}>
                          <ArgonBox mb={3}>
                            <Typography variant="h6" onClick={() => handleDetailDeck(item)}>
                              Used {item.listCards.length} items <Icon>arrow_forward_ios</Icon>
                            </Typography>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox ml={3} mr={3} mb={2} sx={{ borderBottom: "1px black solid" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ArgonBox mb={3}>
                    <Label>Daily Quest</Label>
                  </ArgonBox>
                </Grid>
                <Grid item xs={9}>
                  <ArgonBox sx={{ borderBottom: "1px black solid" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ArgonBox mb={3}>
                          <Typography variant="h6">Result</Typography>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Complete</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{dataQuest?.completed}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Not start</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{dataQuest?.notStart}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Incomplete</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{dataQuest?.inProgress}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Change</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{dataQuest?.reroll}</Typography>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox ml={3} mr={3} mb={2} sx={{ borderBottom: "1px black solid" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ArgonBox mb={3}>
                    <Label>Battle</Label>
                  </ArgonBox>
                </Grid>
                <Grid item xs={9}>
                  <ArgonBox sx={{ borderBottom: "1px black solid" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ArgonBox mb={3}>
                          <Typography variant="h6">Play result</Typography>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Win</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{battle.win}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Lose</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{battle.lose}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Draw</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{battle.raw}</Typography>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Grid>
                <Grid item xs={3}>
                  <ArgonBox mb={3}></ArgonBox>
                </Grid>
                <Grid item xs={9}>
                  <ArgonBox>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ArgonBox mb={3}>
                          <Typography variant="h6">Play mode</Typography>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Unrank mode</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{battle.unrank}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Ranked mode</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{battle.ranked}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Event mode</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{battle.event}</Typography>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox ml={3} mr={3} mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ArgonBox mb={3}>
                    <Label>Ranked</Label>
                  </ArgonBox>
                </Grid>
                <Grid item xs={9}>
                  <ArgonBox sx={{ borderBottom: "1px black solid" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ArgonBox mb={3}>
                          <Typography variant="h6">Highest result</Typography>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Highest MMR</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{user.highestMmr}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Highest Rank</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{user.highestRank}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Highest order in World ranking</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">
                                {getRankByPoint(user.highestMmr)}
                              </Typography>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Grid>
                <Grid item xs={3}>
                  <ArgonBox mb={3}></ArgonBox>
                </Grid>
                <Grid item xs={9}>
                  <ArgonBox>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ArgonBox mb={3}>
                          <Typography variant="h6">Current season</Typography>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Played number of battles</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{user.highestMmr}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Current MMR</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{user.currentMmr}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Current Rank</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{user.currentRank}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Current Mythical point</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">{user.mythicalPoint}</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">Current order in World ranking</Typography>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={6}>
                            <ArgonBox mb={3}>
                              <Typography variant="h6">
                                {user.highestMmr ? getRankByPoint(user.highestMmr) : ""}
                              </Typography>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
