// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import { ListOperationRankList, ListOperationRank } from "../constant/constant";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
import { Link } from "react-router-dom";

export const rankColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    sortable: false,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row._id) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    width: 320,
    sortable: false,
  },
  {
    field: "name",
    headerName: "Season name",
    width: 200,
  },
  {
    field: "durationFrom",
    headerName: "From",
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.durationFrom, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "durationTo",
    headerName: "To",
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.durationTo, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "operation",
    headerName: "Operation state",
    width: 200,
    sortable: false,
    renderCell: (params) => {
      return ListOperationRankList.find((doc) => doc.value === Number(params.row.operation))?.label;
    },
  },
  {
    field: "createdAt",
    headerName: "Created time",
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
  },
];
