export const GraphSignupAccesingData = {
  labels: [],
  datasets: [
    {
      label: "New signups",
      data: [],
      borderColor: "rgb(25, 9, 245, 0.5)",
      backgroundColor: "rgba(25, 9, 245, 1)",
      field: "signUp",
      datalabels: {
        align: "end",
        anchor: "start",
      },
    },
    {
      label: "Access",
      data: [],
      borderColor: "rgb(244, 10, 57, 0.5)",
      backgroundColor: "rgba(244, 10, 57, 1)",
      field: "access",

      datalabels: {
        align: "start",
        anchor: "start",
      },
    },
  ],
};

export const GraphPlayModeData = {
  labels: [],
  datasets: [
    {
      label: "Play Mode",
      data: [],
      backgroundColor: [
        "rgba(102, 37, 6, 1)",
        "rgba(153, 52, 4, 1)",
        "rgba(204, 76, 2, 1)",
        "rgba(236, 112, 20, 1)",
        "rgba(209, 158, 20, 1)",
      ],
      borderColor: [
        "rgba(102, 37, 6, 1)",
        "rgba(153, 52, 4, 1)",
        "rgba(204, 76, 2, 1)",
        "rgba(236, 112, 20, 1)",
        "rgba(209, 158, 20, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const GraphKindOfDeckData = {
  labels: [],
  datasets: [
    {
      label: "Play Mode",
      data: [],
      backgroundColor: ["rgba(73, 0, 106, 1)", "rgba(122, 1, 119, 1)"],
      borderColor: ["rgba(73, 0, 106, 1)", "rgba(122, 1, 119, 1)"],
      borderWidth: 1,
    },
  ],
};

export const GraphKindOfBattleData = {
  labels: [],
  datasets: [
    {
      label: "Play Mode",
      data: [],
      backgroundColor: [
        "rgba(241, 143, 29, 1)",
        "rgba(231, 155, 77, 1)",
        "rgba(241, 176, 38, 1)",
        "rgba(252, 205, 127, 1)",
        "rgba(255, 225, 178, 1)",
      ],
      borderColor: [
        "rgba(241, 143, 29, 1)",
        "rgba(231, 155, 77, 1)",
        "rgba(241, 176, 38, 1)",
        "rgba(252, 205, 127, 1)",
        "rgba(255, 225, 178, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const CountryInactivePlayerData = {
  labels: [],
  datasets: [
    {
      label: "Country",
      data: [],
      borderColor: "rgb(202, 145, 219)",
      backgroundColor: "rgb(202, 145, 219, 0.5)",
      datalabels: {
        anchor: "end",
        align: "start",
      },
    },
  ],
};

export const AgeGroupInactivePlayerData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      backgroundColor: [
        "rgb(128, 0, 38, 1)",
        "rgb(189, 0, 37, 1)",
        "rgb(227, 26, 28, 1)",
        "rgb(252, 78, 42, 1)",
        "rgb(253, 141, 60, 1)",
        "rgb(254, 178, 76, 1)",
        "rgb(254, 219, 123, 1)",
      ],
      borderColor: [
        "rgb(128, 0, 38, 0.5)",
        "rgb(189, 0, 37, 0.5)",
        "rgb(227, 26, 28, 0.5)",
        "rgb(252, 78, 42, 0.5)",
        "rgb(253, 141, 60, 0.5)",
        "rgb(254, 178, 76, 0.5)",
        "rgb(254, 219, 123, 0.5)",
      ],
      borderWidth: 1,
    },
  ],
};

export const CountryActivePlayerData = {
  labels: [],
  datasets: [
    {
      label: "Country",
      data: [],
      backgroundColor: [
        "rgb(0, 68, 27, 1)",
        "rgb(0, 109, 44, 1)",
        "rgb(35, 139, 69, 1)",
        "rgb(65, 174, 118, 1)",
        "rgb(102, 194, 164, 1)",
      ],
      borderColor: [
        "rgb(0, 68, 27, 0.5)",
        "rgb(0, 109, 44, 0.5)",
        "rgb(35, 139, 69, 0.5)",
        "rgb(65, 174, 118, 0.5)",
        "rgb(102, 194, 164, 0.5)",
      ],
      borderWidth: 1,
    },
  ],
};

export const AgeGroupActivePlayerData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      backgroundColor: [
        "rgb(4, 20, 141, 1)",
        "rgb(4, 52, 141, 1)",
        "rgb(4, 64, 141, 1)",
        "rgb(4, 90, 141, 1)",
        "rgb(5, 112, 176, 1)",
        "rgb(54, 144, 192, 1)",
        "rgb(116, 169, 207, 1)",
      ],
      borderColor: [
        "rgb(4, 20, 141, 0.5)",
        "rgb(4, 52, 141, 0.5)",
        "rgb(4, 64, 141, 0.5)",
        "rgb(4, 90, 141, 0.5)",
        "rgb(5, 112, 176, 0.5)",
        "rgb(54, 144, 192, 0.5)",
        "rgb(116, 169, 207, 0.5)",
      ],
      borderWidth: 1,
    },
  ],
};
