// Argon Dashboard 2 MUI components
import { Icon } from "@mui/material";
import ArgonBadge from "components/ArgonBadge";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import { RewardMutationList, ListStatusQuestList } from "../constant/constant";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
import { Link } from "react-router-dom";

export const questColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    sortable: false,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row._id) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    width: 240,
    sortable: false,
    renderCell: (params) => {
      return (
        <ArgonBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
          <ArgonButton
            variant="outlined"
            color="dark"
            component={Link}
            to={`/quest/${params.row._id}`}
          >
            Edit
          </ArgonButton>
        </ArgonBox>
      );
    },
  },
  {
    field: "questName",
    headerName: "Quest name",
    width: 200,
  },
  {
    field: "mutationPoint",
    headerName: "Reward",
    sortable: false,
    width: 80,
  },
  {
    field: "mutationOption",
    headerName: "Mutation Type",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return RewardMutationList.find((doc) => doc.value === params.row.mutationOption)?.label;
    },
  },
  {
    field: "durationFrom",
    headerName: "From",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.durationFrom, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "durationTo",
    headerName: "To",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.durationTo, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      const statusLabel = ListStatusQuestList.find(
        (doc) => doc.value === Number(params.row.status)
      );
      return (
        <ArgonBadge
          variant="gradient"
          badgeContent={statusLabel?.label}
          color={
            statusLabel?.value === 1 ? "success" : statusLabel?.value === 1 ? "warning" : "error"
          }
          size="xs"
          container
        />
      );
    },
  },
  {
    field: "completedTimes",
    headerName: "Completed times",
    sortable: false,
    width: 140,
    renderCell: (params) => {
      return params.row.completedTimes ? params.row.completedTimes : 0;
    },
  },
  {
    field: "rerollTimes",
    headerName: "Reroll times",
    sortable: false,
    width: 140,
    renderCell: (params) => {
      return params.row.rerollTimes ? params.row.rerollTimes : 0;
    },
  },
  {
    field: "createdAt",
    headerName: "Created time",
    width: 160,
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
  },
];
