import { useState } from "react";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
// react-router-dom components
import { Link } from "react-router-dom";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import { useNavigate } from "react-router-dom";
import { useLogin } from "queries/login.queries";
import { useMutation } from "@tanstack/react-query";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { toast } from "react-toastify";
const axios = require("axios");
// Image
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

function Illustration() {
  let navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [usernameError, setUserNameError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [keepLogin, setKeepLogin] = useState(null);

  const [loginError, setLoginError] = useState("");

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        username: yup.string().required("This feild is required"),
        password: yup.string().required("This feild is required"),
      })
    ),
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isValid },
    reset,
    watch,
    setError,
  } = methods;

  const { mutate } = useMutation(useLogin, {
    onSuccess: (res) => {
      localStorage.setItem("accessToken", res.accessToken);
      if (keepLogin) {
        localStorage.setItem("refreshToken", res.refreshToken);
      }
      localStorage.setItem("email", res.email);
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.accessToken;
      toast.success("Login Success!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    },
    onError: (error) => {
      console.log(error, "eror");
      toast.error(error?.response?.data?.message ?? "Login Fail!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  const login = async () => {
    let check = false;
    if (!username.trim()) {
      check = true;
      setUserNameError("This field is required.");
    }

    if (!password) {
      check = true;
      setPasswordError("This field is required.");
    }

    if (!check) {
      mutate({
        emailOrUsername: username.trim().toString().toLowerCase(),
        password: password,
      });
    }
  };

  const onSubmit = handleSubmit((data) => {
    trigger();

    mutate({
      emailOrUsername: data.username.trim().toString().toLowerCase(),
      password: data.password,
    });
  });

  return (
    <IllustrationLayout
      title=""
      description=""
      illustration={{
        image: bgImage,
        title: '"Supa is new game"',
        description: "Supa is new game.",
      }}
    >
      <FormProvider {...methods}>
        <ArgonBox component="form" onSubmit={onSubmit}>
          <ArgonBox mb={3}>
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  fullWidth
                  helperText={error?.message ? error?.message : null}
                  error={invalid}
                  placeholder="Enter your Username or Email"
                  {...field}
                  sx={{ root: { height: "50px" } }}
                />
              )}
            />
          </ArgonBox>
          <ArgonBox mb={3}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  fullWidth
                  helperText={error?.message ? error?.message : null}
                  error={invalid}
                  placeholder="Password"
                  {...field}
                  type="password"
                  sx={{ root: { height: "50px" } }}
                />
              )}
            />
          </ArgonBox>

          <FormControlLabel
            label="Keep me logged in"
            sx={{ paddingLeft: "10px", display: "flex" }}
            control={
              <Checkbox
                sx={{ ml: 1.2 }}
                onChange={(e) => {
                  if (keepLogin) {
                    setKeepLogin(false);
                  } else {
                    setKeepLogin(true);
                  }
                }}
              />
            }
          />
          <ArgonBox mt={4} mb={1}>
            <ArgonButton color="info" size="large" fullWidth type="submit">
              Login
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </FormProvider>
    </IllustrationLayout>
  );
}

export default Illustration;
