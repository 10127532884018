import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import { DataGrid } from "@mui/x-data-grid";
import { deckDetailColumns } from "../../../mock/data-user";
import CloseIcon from "@mui/icons-material/Close";

export const DeckDetailPopup = (props) => {
  const { open, onClose, data } = props;
  const [sortModel, setSortModel] = React.useState([
    {
      field: "serial_number",
      sort: "asc",
    },
  ]);
  if (data) {
    data.listCards.map((doc, index) => {
      doc.id = index + 1;
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "85%",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {data ? data.name : ""}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                fontSize: 28,
                color: "#333",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <ArgonBox>
          <DialogContent
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  "& .MuiDialogContent-root": {
                    textAlign: "center",
                  },
                },
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4} mb={3}>
                <Typography variant="h6">Deck type</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="p">{data ? data.deckType : ""}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="h6">SUPA Organism NFT</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="p">Hero name</Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </ArgonBox>
        <DialogContent>
          <Typography variant="h6">Cards list</Typography>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <DataGrid
              autoHeight
              rows={data ? data.listCards : []}
              columns={deckDetailColumns}
              rowLength={10}
              rowsPerPageOptions={[]}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
          </ArgonBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
