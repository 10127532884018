import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
const { fetch } = require("../service/index");

function useGetNews(params) {
  return useQuery(["all-news", params], async (query) => {
    const res = await fetch({
      url: `/api/news/news-admin`,
      params: {
        ...query.queryKey[1],
      },
    });
    if (res.data && res.data.rows.length > 0) {
      res.data.rows.map((doc, index) => {
        doc.index = (Number(res.data.page) - 1) * Number(res.data.perPage) + index + 1;
      });
    }
    return res.data;
  });
}

function useGetNewsById(id) {
  return useQuery(
    ["news-detail"],
    async () => {
      const res = await fetch({
        url: `/api/news/` + id,
      });
      return res.data;
    },
    {
      retry: false,
    }
  );
}

function useAddNews() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/news/create`,
        method: "POST",
        data,
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-news");
      },
    }
  );
}
function useDeleteNews(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      await fetch({
        url: `/api/news/delete/${id}`,
        method: "DELETE",
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-news");
      },
    }
  );
}

function useUpdateNews(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/news/update/${id}`,
        method: "PUT",
        data,
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-news");
      },
    }
  );
}

export { useGetNews, useGetNewsById, useAddNews, useDeleteNews, useUpdateNews };
