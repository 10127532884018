import * as yup from "yup";
import {
  ListTypeFee,
  YesNoNumber,
  TypeEventReward,
  TypeEventList,
} from "../../../constant/constant";
export const eventSchema = yup.object().shape({
  name: yup
    .string()
    .required("Event name is required")
    .max(50, "Can not input more than 50 characters"),
  description: yup
    .string()
    .required("Description is required")
    .max(2000, "Can not input more than 2000 characters"),
  durationFrom: yup.string().nullable().required("Duration From is required"),
  durationTo: yup
    .string()
    .nullable()
    .required("Duration To is required")
    .test("gatherThan", "Invalid duration", (value, contextTest) => {
      return (
        isNaN(new Date(value)) ||
        isNaN(new Date(contextTest.parent.durationFrom)) ||
        new Date(value) >= new Date(contextTest.parent.durationFrom)
      );
    }),
  status: yup.string(),
  image: yup
    .mixed()
    .test("required", "Image is required", (value) => {
      return !!value;
    })
    .test("file-type", "Invalid image", (value) => {
      return !value || ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(value?.type);
    })
    .test("fileSize", "Image size is too large.", (value) => {
      return !value || value?.size <= 5 * 1024 * 1024;
    }),
  entryFee_token: yup.string().when("entryFeeType", (value) => {
    if (Number(value) === ListTypeFee.Token) {
      return yup
        .string()
        .required("Token value is required")
        .test(
          "validateToken",
          "Invalid value. Please input decimal > 0, maximum 64 characters. Example: 0.123456789",
          (value) => {
            return /^\d*\.?\d*$/.test(value) && value.toString().length <= 64;
          }
        );
    }
  }),
  payResetLose_token: yup.string().when("payResetLoseType", (value) => {
    if (Number(value) === ListTypeFee.Token) {
      return yup
        .string()
        .required("Token value is required")
        .test(
          "validateToken",
          "Invalid value. Please input decimal > 0, maximum 64 characters. Example: 0.123456789",
          (value) => {
            return /^\d*\.?\d*$/.test(value) && value.toString().length <= 64;
          }
        );
    }
  }),
  entryFee_mutationPoint: yup.string().when("entryFeeType", (value) => {
    if (Number(value) === ListTypeFee.Mutation) {
      return yup
        .string()
        .required("Mutatuion point is required")
        .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
          return /^[0-9]*$/.test(value) && Number(value) > 0;
        });
    }
  }),
  payResetLose_mutationPoint: yup.string().when("payResetLoseType", (value) => {
    if (Number(value) === ListTypeFee.Mutation) {
      return yup
        .string()
        .required("Mutatuion point is required")
        .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
          return /^[0-9]*$/.test(value) && Number(value) > 0;
        });
    }
  }),
  loseLimit: yup.string().when("loseLimitType", (value) => {
    if (Number(value) === YesNoNumber.Yes) {
      return yup
        .string()
        .required("Lost limit is required")
        .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
          return /^[0-9]*$/.test(value) && Number(value) > 0;
        });
    }
  }),
  numberWin: yup.string().when("typeEventReward", (value) => {
    if (Number(value) === TypeEventReward.WHOLE) {
      return yup
        .string()
        .required("Lost limit is required")
        .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
          return /^[0-9]*$/.test(value) && Number(value) > 0;
        });
    }
  }),
  typeEventReward: yup.string().required("Type Reward is required"),
  numberDeckFrom: yup.string().when("eventType", (value) => {
    if (Number(value) === TypeEventReward.TOURNAMENT) {
      return yup
        .string()
        .required("Number of decks per tournament is required")
        .test(
          "gatherThan",
          "The minimum number of decks must be greater than the limit",
          (value, contextTest) => {
            return (
              isNaN(value) ||
              isNaN(contextTest.parent.numberDeckTo) ||
              Number(value) <= Number(contextTest.parent.numberDeckTo)
            );
          }
        );
    }
  }),
  numberDeckTo: yup.string().when("eventType", (value) => {
    if (Number(value) === TypeEventReward.TOURNAMENT) {
      return yup.string().required("Number of decks per tournament is required");
    }
  }),
});
