/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 MUI layouts
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/login";
import SignUp from "layouts/authentication/sign-up";
import UserList from "layouts/users/list";
import UserDetail from "layouts/users/detail";
import CardList from "layouts/cards/list";
import CardAdd from "layouts/cards/add";
import CardEdit from "layouts/cards/edit";
import NewsList from "layouts/news/list";
import NewsAdd from "layouts/news/add";
import NewsEdit from "layouts/news/edit";
import QuestList from "layouts/quest/list";
import QuestAdd from "layouts/quest/add";
import QuestEdit from "layouts/quest/edit";
import EventList from "layouts/event/list";
import EventAdd from "layouts/event/add";
import EventEdit from "layouts/event/edit";
import RewardList from "layouts/reward/list";
import RewardAdd from "layouts/reward/add";
import RewardEdit from "layouts/reward/edit";
import RankList from "layouts/rank/list";
import RankAdd from "layouts/rank/add";
import RankEdit from "layouts/rank/edit";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import { Navigate } from "react-router-dom";

const Logout = () => {
  localStorage.setItem("accessToken", "");
  localStorage.setItem("refreshToken", "");
  localStorage.setItem("email", "");
  localStorage.setItem("refreshed", "");
  return <Navigate to="/login" replace={true} />;
};

const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    login: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <Dashboard />,
  },
  {
    type: "route",
    name: "User",
    key: "users",
    route: "/users",
    login: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-single-02" />,
    component: <UserList />,
  },
  {
    type: "route",
    name: "Detail User ",
    key: "detail-users",
    route: "/users/:userId",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <UserDetail />,
  },
  {
    type: "route",
    name: "News",
    key: "news",
    route: "/news",
    login: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-align-left-2" />,
    component: <NewsList />,
  },
  {
    type: "route",
    name: "Add News ",
    key: "add-news",
    route: "/news/add",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-align-left-2" />,
    component: <NewsAdd />,
  },
  {
    type: "route",
    name: "Edit News ",
    key: "edit-news",
    route: "/news/:id",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-align-left-2" />,
    component: <NewsEdit />,
  },
  {
    type: "route",
    name: "Reward",
    key: "reward",
    route: "/reward",
    login: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-trophy" />,
    component: <RewardList />,
  },
  {
    type: "route",
    name: "Add Reward ",
    key: "add-reward",
    route: "/reward/add",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-trophy" />,
    component: <RewardAdd />,
  },
  {
    type: "route",
    name: "Edit Reward ",
    key: "edit-reward",
    route: "/reward/:id",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-trophy" />,
    component: <RewardEdit />,
  },
  {
    type: "route",
    name: "Quest",
    key: "quest",
    route: "/quest",
    login: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-paper-diploma" />
    ),
    component: <QuestList />,
  },
  {
    type: "route",
    name: "Add Quest ",
    key: "add-quest",
    route: "/quest/add",
    login: true,
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-paper-diploma" />
    ),
    component: <QuestAdd />,
  },
  {
    type: "route",
    name: "Edit Quest ",
    key: "edit-quest",
    route: "/quest/:id",
    login: true,
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-paper-diploma" />
    ),
    component: <QuestEdit />,
  },
  {
    type: "route",
    name: "Event",
    key: "event",
    route: "/event",
    login: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-istanbul" />,
    component: <EventList />,
  },
  {
    type: "route",
    name: "Add Event ",
    key: "add-event",
    route: "/event/add",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-istanbul" />,
    component: <EventAdd />,
  },
  {
    type: "route",
    name: "Edit Event ",
    key: "edit-event",
    route: "/event/:id",
    login: true,
    hideSidenav: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-istanbul" />,
    component: <EventEdit />,
  },
  {
    type: "route",
    name: "Rank",
    key: "rank",
    route: "/rank",
    login: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-paper-diploma" />
    ),
    component: <RankList />,
  },
  {
    type: "route",
    name: "Add Rank ",
    key: "add-rank",
    route: "/rank/add",
    login: true,
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-paper-diploma" />
    ),
    component: <RankAdd />,
  },
  {
    type: "route",
    name: "Edit Rank ",
    key: "edit-rank",
    route: "/rank/:id",
    login: true,
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-paper-diploma" />
    ),
    component: <RankEdit />,
  },
  {
    type: "route",
    name: "Cards",
    key: "cards",
    route: "/cards",
    login: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <CardList />,
  },
  {
    type: "route",
    name: "Add Card ",
    key: "add-cards",
    route: "/cards/add",
    login: true,
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <CardAdd />,
  },
  {
    type: "route",
    name: "Edit Card ",
    key: "edit-cards",
    route: "/cards/:id",
    login: true,
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <CardEdit />,
  },
  {
    type: "route",
    name: "Logout",
    key: "logout",
    route: "/logout",
    login: true,
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-button-power" />,
    component: <Logout />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    route: "/login",
    hideSidenav: true,
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <SignIn />,
  },
  {
    type: "route",
    name: "Sign Up",
    key: "sign-up",
    route: "/sign-up",
    hideSidenav: true,
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <SignUp />,
  },
];

export default routes;
