import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
const timeZone = "Asia/Ho_Chi_Minh";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
export enum DateFormat {
  FULL_DATE_TIME = "HH:mm:ss DD/MM/YYYY",
  FULL_DATE = "DD/MM/YYYY",
  FULL_DATE_2 = "YYYY-MM-DD",
  TIME = "HH:mm",
}

export const formatDate = (
  date: Date | string,
  dateFormat: DateFormat = DateFormat.FULL_DATE_TIME
) => {
  if (!date) return "";
  if (!(date instanceof Date)) date = new Date(date);

  return dayjs(date).format(dateFormat);
};

export const subDate = (count: Number) => {
  return new Date(new Date().getTime() - Number(count) * 24 * 60 * 60 * 1000);
};

export const startOfDay = (date: Date) => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const endOfDay = (date: Date) => {
  return new Date(new Date(date).setHours(23, 59, 59, 59));
};

export const dateTZ = (date: Date) => {
  return new Date((<any>dayjs)(date).tz(timeZone));
};

export const fotmartTimeUTCToTZ = (time, dateFormat: DateFormat = DateFormat.TIME) => {
  if (!time) return "";
  return dayjs(new Date(`2022-02-02 ${time} +0`)).format(dateFormat);
};
