// @mui material components
import {
  Card,
  Icon,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { useState, useRef, useEffect } from "react";
import { userColumns } from "../../../mock/data-user";
import { DataGrid } from "@mui/x-data-grid";
import { ListStatusUserList, YesNoList, RankList } from "../../../constant/constant";
import { useGetUsers } from "../../../queries/users.queries";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import { getQuerySearch, convertQuery } from "../../../utils/utils";
import { userSchema } from "./schemas";
import { yupResolver } from "@hookform/resolvers/yup";

function Default() {
  const navigate = useNavigate();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sortModel, setSortModel] = useState({
    sort: "",
    model: [],
  });
  const [q, setQ] = useState({});
  const location = useLocation().search;
  const [updateNavigate, setUpdateNavigate] = useState(false);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const previousLocation = usePrevious(location);

  const {
    data: dataUsers,
    refetch,
    isLoading,
  } = useGetUsers({
    page: pageCurrent,
    perPage,
    sort: sortModel.sort,
    ...q,
  });

  useEffect(() => {
    if (!updateNavigate) return;
    navigate({
      pathname: "/users",
      search: convertQuery({
        page: pageCurrent,
        perPage,
        sort: sortModel.sort,
        ...q,
      }),
    });
  }, [pageCurrent, perPage, sortModel, q]);

  const pageChange = (page) => {
    setUpdateNavigate(true);
    setPageCurrent(page + 1);
    refetch();
  };

  const pageSizeChange = (pageSize) => {
    setUpdateNavigate(true);
    setPerPage(pageSize);
    refetch();
  };

  useEffect(() => {
    if (location) {
      const query = getQuerySearch(location);
      if (!query) {
        return;
      }

      if (query.page) {
        setPageCurrent(Number(query.page));
      }
      if (query.perPage) {
        setPerPage(Number(query.perPage));
      }
      if (query.sort) {
        setSortModel({
          sort: query.sort,
          model: [
            {
              field: query.sort.replace("-", ""),
              sort: query.sort.includes("-") ? "desc" : "asc",
            },
          ],
        });
      }
      delete query.page;
      delete query.perPage;
      delete query.sort;
      setQ({ ...query });
      for (let key in query) {
        if (["subscribed", "connectWallet"].includes(key)) {
          query[key] = query[key].split(",");
        }
      }
      reset({
        ...query,
      });
      refetch();
    }
  }, []);

  useEffect(() => {
    if (!location && previousLocation != location) {
      setUpdateNavigate(false);
      setPageCurrent(1);
      setPerPage(20);
      setSortModel({
        sort: "",
        model: [],
      });
      reset(defaultValues);
      setQ({});
      refetch();
    }
  }, [location]);

  const handleSortModelChange = (value) => {
    let sort = "";
    if (value && value[0]) {
      if (value[0].sort === "asc") {
        sort = value[0].field;
      } else {
        sort = "-" + value[0].field;
      }
    }
    setUpdateNavigate(true);
    setSortModel({
      sort,
      model: value,
    });
    refetch();
  };

  const defaultValues = {
    search: "",
    status: "all",
    subscribed: [],
    connectWallet: [],
    currentRank: "all",
    highestRank: "all",
    createdFrom: null,
    createdTo: null,
    lastActivityFrom: null,
    lastActivityTo: null,
    winRateFrom: 0,
    winRateTo: 0,
    isBan: false,
  };
  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(userSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const onSubmit = handleSubmit((data) => {
    const query = {};
    for (let key in defaultValues) {
      if (defaultValues[key] === "all") {
        if (data[key] !== "all") {
          query[key] = data[key];
        }
      } else if (Array.isArray(defaultValues[key])) {
        if (data[key].length > 0) {
          query[key] = data[key].join(",");
        }
      } else if (data[key]) {
        if (
          key === "durationFrom" ||
          key === "durationTo" ||
          key === "createdFrom" ||
          key === "createdTo"
        ) {
          query[key] = new Date(data[key]);
        } else if (key === "isBan") {
          query[key] = data[key];
        } else {
          query[key] = data[key].trim();
        }
      }
    }
    setUpdateNavigate(true);
    setPageCurrent(1);
    setQ(query);
    refetch();
  });

  const onReset = () => {
    setQ({});
    reset(defaultValues);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={0}>
        <ArgonBox mb={3}>
          <Card style={{ padding: "10px" }}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              pt={0}
              pr={0}
              pb={1}
            >
              <ArgonTypography variant="h6" sx={{ fontSize: "20px" }}>
                List Users
              </ArgonTypography>
              {/* <ArgonButton
                component={Link}
                to="/users/add"
                variant="outlined"
                color="info"
                size="medium"
                rel="noreferrer"
              >
                <Icon sx={{ marginRight: "10px" }}>add</Icon>Add
              </ArgonButton> */}
            </ArgonBox>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={1}
              ml={3}
            >
              <FormProvider onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} rowSpacing={0.1}>
                  <Grid item xs={6} sm={3} lg={3}>
                    <InputLabel id="demo-simple-select-label" aria-setsize={10}>
                      Search
                    </InputLabel>
                    <ArgonBox pr={1}>
                      <Controller
                        name="search"
                        control={control}
                        render={({ field }) => (
                          <ArgonInput
                            placeholder="Type here..."
                            {...field}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                onSubmit();
                              }
                            }}
                            startAdornment={
                              <Icon fontSize="small" style={{ marginRight: "6px" }}>
                                search
                              </Icon>
                            }
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="status-user">Status</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field }) => (
                            <Select labelId="status-user" {...field} label="Status">
                              {ListStatusUserList.map((statusUser, index) => {
                                return (
                                  <MenuItem key={"status-" + index} value={statusUser.value}>
                                    {statusUser.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="status-user">Subscribed?</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="subscribed"
                          control={control}
                          render={({ field }) => (
                            <Grid container sx={{ alignItems: "center" }}>
                              {YesNoList.map((item, index) => (
                                <Grid item xs={6} key={"grid-" + index}>
                                  <FormControlLabel
                                    {...field}
                                    key={item.label}
                                    label={item.label}
                                    sx={{ paddingLeft: "10px", display: "flex" }}
                                    control={
                                      <Checkbox
                                        sx={{ ml: 1 }}
                                        checked={getValues("subscribed").includes(item.value)}
                                        onChange={(e) => {
                                          if (!field.value.includes(item.value)) {
                                            field.onChange([...field.value, item.value]);
                                            return;
                                          }
                                          const newTopic = field.value.filter(
                                            (topic) => topic !== item.value
                                          );
                                          field.onChange(newTopic);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="status-user">Connected Wallet?</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="connectWallet"
                          control={control}
                          render={({ field }) => (
                            <Grid container sx={{ alignItems: "center" }}>
                              {YesNoList.map((item, index) => (
                                <Grid item xs={6} key={"grid-" + index}>
                                  <FormControlLabel
                                    {...field}
                                    key={item.label}
                                    label={item.label}
                                    sx={{ paddingLeft: "10px", display: "flex" }}
                                    control={
                                      <Checkbox
                                        sx={{ ml: 1.2 }}
                                        checked={getValues("connectWallet").includes(item.value)}
                                        onChange={(e) => {
                                          if (!field.value.includes(item.value)) {
                                            field.onChange([...field.value, item.value]);
                                            return;
                                          }
                                          const newTopics = field.value.filter(
                                            (topic) => topic !== item.value
                                          );
                                          field.onChange(newTopics);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} pr={2}>
                    <ArgonBox mb={3}>
                      <InputLabel>Created date</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="createdFrom"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                {...field}
                                defaultValues={null}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="Type headline..."
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        <Controller
                          name="createdTo"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                {...field}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="Type headline..."
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="rank-user">Win rate (%)</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="winRateFrom"
                          control={control}
                          render={({ field }) => <ArgonInput {...field} />}
                        />
                        <Controller
                          name="winRateTo"
                          control={control}
                          render={({ field }) => <ArgonInput {...field} />}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="status-user">Is Banned</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="isBan"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              {...field}
                              sx={{ paddingLeft: "10px", display: "flex" }}
                              control={<Checkbox sx={{ ml: 1 }} checked={!!getValues("isBan")} />}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="rank-user">Current Rank</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="currentRank"
                          control={control}
                          render={({ field }) => (
                            <Select labelId="rank-user" {...field} label="Rank">
                              {RankList.map((statusUser, index) => {
                                return (
                                  <MenuItem key={"rank-" + index} value={statusUser.value}>
                                    {statusUser.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="rank-user">Highest Rank</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="highestRank"
                          control={control}
                          render={({ field }) => (
                            <Select labelId="rank-user" {...field} label="Rank">
                              {RankList.map((statusUser, index) => {
                                return (
                                  <MenuItem key={"rank-" + index} value={statusUser.value}>
                                    {statusUser.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} pr={2}>
                    <ArgonBox mb={3}>
                      <InputLabel>Last activity</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="lastActivityFrom"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                {...field}
                                defaultValues={null}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="Type headline..."
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        <Controller
                          name="lastActivityTo"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                {...field}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="Type headline..."
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputLabel> &nbsp;</InputLabel>
                    <ArgonBox sx={{ display: "flex", gap: 1 }}>
                      <ArgonButton
                        type="submit"
                        onClick={onSubmit}
                        variant="outlined"
                        color="dark"
                        size="medium"
                        rel="noreferrer"
                      >
                        <Icon sx={{ marginRight: "10px" }}>search</Icon>Search
                      </ArgonButton>
                      <ArgonButton
                        ml={3}
                        color="info"
                        className="btn"
                        variant="outlined"
                        onClick={onReset}
                      >
                        Reset
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </FormProvider>
            </ArgonBox>
            <ArgonBox
              pb={3}
              ml={3}
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <DataGrid
                autoHeight
                className="datagrid"
                paginationMode="server"
                rows={dataUsers ? dataUsers.rows : []}
                loading={isLoading}
                rowCount={dataUsers ? dataUsers.total : 0}
                onPageChange={(newPage) => pageChange(newPage)}
                columns={userColumns}
                pageSize={perPage}
                onPageSizeChange={(newPageSize) => pageSizeChange(newPageSize)}
                rowsPerPageOptions={[20, 50, 100]}
                getRowId={(row) => row._id}
                pagination
                sortingMode="server"
                sortModel={sortModel.model}
                onSortModelChange={handleSortModelChange}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                      No data
                    </Stack>
                  ),
                }}
              />
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
