import * as yup from "yup";
export const rankSchema = yup.object().shape({
  name: yup
    .string()
    .required("Event name is required")
    .max(75, "Can not input more than 75 characters"),
  durationFrom: yup.string().nullable().required("Duration From is required"),
  durationTo: yup
    .string()
    .nullable()
    .required("Duration To is required")
    .test("gatherThan", "Invalid duration", (value, contextTest) => {
      return (
        isNaN(new Date(value)) ||
        isNaN(new Date(contextTest.parent.durationFrom)) ||
        new Date(value) >= new Date(contextTest.parent.durationFrom)
      );
    }),
  rewardType: yup.string().required("Type Reward is required"),
});
