// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import GraphSignupAccesing from "./components/GraphSignupAccesing";
import GraphPlayModes from "./components/GraphPlayModes";
import GraphInactivePlayers from "./components/GraphInactivePlayers";
import GraphActivePlayers from "./components/GraphActivePlayers";

function Default() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12}>
            <GraphSignupAccesing />
          </Grid>
          <Grid item xs={12}>
            <GraphPlayModes />
          </Grid>
          <Grid item xs={12}>
            <GraphInactivePlayers />
          </Grid>
          <Grid item xs={12}>
            <GraphActivePlayers />
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
