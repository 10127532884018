// @mui material components
import { Card, Grid, InputLabel } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import { FormProvider, useForm, Controller } from "react-hook-form";
import { TimeChart, TimeChartList } from "../../../constant/constant";
import { useState, useEffect } from "react";
import { useGetGraphSignupAccesing } from "../../../queries/dashboard.queries";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import LineCharts from "../../../examples/Charts/LineCharts/DefaultLineChart";
import { GraphSignupAccesingData } from "../data/index";
import { DateFormat, formatDate } from "../../../utils/formatDate.ts";
import { signupAccesingColumns } from "../../../mock/data-dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function Default() {
  const [groupType, setGroupType] = useState(TimeChart.Hour);
  const [q, setQ] = useState({});
  const [viewDetail, setViewDetail] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const {
    data: dataGraph,
    refetch,
    isLoading,
  } = useGetGraphSignupAccesing({
    groupType,
    ...q,
  });
  let dataTable = [];
  let lineChartData = { ...GraphSignupAccesingData };
  if (dataGraph && Array.isArray(dataGraph)) {
    lineChartData.labels = [];
    lineChartData.datasets.map((doc) => {
      doc.data = [];
    });
    const total = {
      _id: 0,
      time: "Total",
      signUp: 0,
      access: 0,
    };
    dataGraph.forEach((doc, index) => {
      lineChartData.labels.push(doc.time);
      lineChartData.datasets[0].data.push(doc.signUp);
      lineChartData.datasets[1].data.push(doc.access);
      total.signUp += doc.signUp;
      total.access += doc.access;
      doc._id = index + 1;
    });
    dataTable = [total].concat(dataGraph);
  }

  const defaultValues = {
    fromDate: "",
    toDate: "",
    timeStart: "",
    timeTo: "",
  };
  const { control, handleSubmit } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        fromDate: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          }),
        toDate: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          })
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.fromDate)) ||
              new Date(value) >= new Date(contextTest.parent.fromDate)
            );
          }),
        timeStart: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          }),
        timeTo: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          })
          .test("gatherTimeThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.timeStart ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.timeStart)) ||
              new Date(value) >= new Date(contextTest.parent.timeStart)
            );
          }),
      })
    ),
    mode: "onChange",
  });

  const onSubmit = handleSubmit((data) => {
    const query = {};
    for (let key in defaultValues) {
      if (data[key]) {
        if (key === "fromDate" || key === "toDate") {
          query[key] = formatDate(data[key], DateFormat.FULL_DATE_2);
        } else {
          query[key] = data[key];
        }
      }
    }
    setQ(query);
    refetch();
  });

  const updateTypeDate = (value) => {
    setGroupType(value);
    refetch();
  };

  return (
    <Card style={{ padding: "10px" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pt={0}
        pr={0}
        pb={1}
      >
        <ArgonTypography variant="h6">Graph of Signup/Accessing</ArgonTypography>
        <ArgonButton
          onClick={() => setViewDetail(!viewDetail)}
          variant="text"
          color="info"
          size="medium"
          rel="noreferrer"
        >
          {!viewDetail ? `View Detail >` : `Hide Detail`}
        </ArgonButton>
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pb={1} ml={3}>
        <FormProvider onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1} rowSpacing={0.1}>
            <Grid item xs={12} sm={6} lg={6}>
              <ArgonBox mb={3}>
                <InputLabel>Date range</InputLabel>
                <ArgonBox sx={{ display: "flex", gap: 1 }}>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        {...field}
                        defaultValues={null}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD/MM/YYYY",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD/MM/YYYY",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <ArgonBox mb={3}>
                <InputLabel>Time range</InputLabel>
                <ArgonBox sx={{ display: "flex", gap: 1 }}>
                  <Controller
                    name="timeStart"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TimePicker
                        inputFormat="HH:mm:ss"
                        {...field}
                        defaultValues={null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "HH:MM:SS",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="timeTo"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TimePicker
                        inputFormat="HH:mm:ss"
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "HH:MM:SS",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={2}>
              <InputLabel> &nbsp;</InputLabel>
              <ArgonBox pr={1}>
                <ArgonButton
                  type="submit"
                  onClick={onSubmit}
                  variant="outlined"
                  color="dark"
                  size="medium"
                  rel="noreferrer"
                >
                  Filter
                </ArgonButton>
              </ArgonBox>
            </Grid>
          </Grid>
        </FormProvider>
      </ArgonBox>
      <ArgonBox justifyContent="space-between" textAlign="center" pb={1} ml={3}>
        {TimeChartList &&
          TimeChartList.map((timeChart, index) => (
            <ArgonButton
              key={index}
              sx={{ marginLeft: "15px", marginRight: "15px", marginBottom: "15px" }}
              type="submit"
              onClick={() => updateTypeDate(timeChart.value)}
              variant={timeChart.value === groupType ? "contained" : "outlined"}
              color={timeChart.value === groupType ? "warning" : "dark"}
              size="medium"
              rel="noreferrer"
            >
              {timeChart.label}
            </ArgonButton>
          ))}
      </ArgonBox>
      <LineCharts chart={lineChartData} />
      {viewDetail && (
        <>
          <ArgonBox
            pb={3}
            ml={3}
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <DataGrid
              autoHeight
              className="datagrid"
              rows={dataTable ? dataTable : []}
              rowCount={dataTable ? dataTable.length : 0}
              columns={signupAccesingColumns}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              getRowId={(row) => row._id}
              pagination
            />
          </ArgonBox>
        </>
      )}
    </Card>
  );
}

export default Default;
