import * as yup from "yup";
export const banCardSchema = yup.object().shape({
  card: yup
    .object()
    .nullable()
    .test("requiredCard", "You must select Card serial", (value) => {
      console.log(value, "value");
      return !!value;
    }),
  banGame_durationFrom: yup.string().nullable(),
  banGame_durationTo: yup
    .string()
    .nullable()
    .when("isBanGame", (value) => {
      if (value) {
        return yup
          .string()
          .nullable()
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.banGame_durationFrom ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.banGame_durationFrom)) ||
              new Date(value) >= new Date(contextTest.parent.banGame_durationFrom)
            );
          });
      }
    }),
  banUnrank_durationFrom: yup.string().nullable(),
  banUnrank_durationTo: yup
    .string()
    .nullable()
    .when("isBanUnrank", (value) => {
      if (value) {
        return yup
          .string()
          .nullable()
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.banUnrank_durationFrom ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.banUnrank_durationFrom)) ||
              new Date(value) >= new Date(contextTest.parent.banUnrank_durationFrom)
            );
          });
      }
    }),
  banRank_durationFrom: yup.string().nullable(),
  banRank_durationTo: yup
    .string()
    .nullable()
    .when("isBanRank", (value) => {
      if (value) {
        return yup
          .string()
          .nullable()
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.banRank_durationFrom ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.banRank_durationFrom)) ||
              new Date(value) >= new Date(contextTest.parent.banRank_durationFrom)
            );
          });
      }
    }),
  banChallenge_durationFrom: yup.string().nullable(),
  banChallenge_durationTo: yup
    .string()
    .nullable()
    .when("isBanChallenge", (value) => {
      if (value) {
        return yup
          .string()
          .nullable()
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.banChallenge_durationFrom ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.banChallenge_durationFrom)) ||
              new Date(value) >= new Date(contextTest.parent.banChallenge_durationFrom)
            );
          });
      }
    }),
  banEvent_durationFrom: yup.string().nullable(),
  banEvent_durationTo: yup
    .string()
    .nullable()
    .when("isBanEvent", (value) => {
      if (value) {
        return yup
          .string()
          .nullable()
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              !value ||
              !contextTest.parent.banEvent_durationFrom ||
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.banEvent_durationFrom)) ||
              new Date(value) >= new Date(contextTest.parent.banEvent_durationFrom)
            );
          });
      }
    }),
});
