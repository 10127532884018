export const signupAccesingColumns = [
  {
    field: "time",
    headerName: "Time",
    width: 200,
  },
  {
    field: "signUp",
    headerName: "New signUps",
    width: 200,
  },
  {
    field: "access",
    headerName: "Access",
    width: 200,
  },
];

export const KindOfDeckColumns = [
  {
    field: "title",
    headerName: "Kind of deck winning",
    width: 200,
  },
  {
    field: "unranked",
    headerName: "Unrank",
    width: 100,
  },
  {
    field: "ranked",
    headerName: "Rank",
    width: 100,
  },
  {
    field: "event",
    headerName: "Event",
    width: 100,
  },
  {
    field: "tournament",
    headerName: "Tournament",
    width: 100,
  },
  {
    field: "challenge",
    headerName: "Fiend challenge",
    width: 100,
  },
];

export const KindOfBattleColumns = [
  {
    field: "title",
    headerName: "Kind of battle endding",
    width: 200,
    flex: 1,
  },
  {
    field: "unranked",
    headerName: "Unranked",
    width: 100,
    flex: 1,
  },
  {
    field: "ranked",
    headerName: "Ranked",
    width: 100,
    flex: 1,
  },
  {
    field: "event",
    headerName: "Event",
    width: 100,
    flex: 1,
  },
  {
    field: "challenge",
    headerName: "Fiend challenge",
    width: 100,
    flex: 1,
  },
  {
    field: "tournament",
    headerName: "Tournament",
    width: 100,
    flex: 1,
  },
];

export const inactivePlayerColumns = [
  {
    field: "country",
    headerName: "Country",
    width: 200,
    flex: 1,
  },
  {
    field: "total",
    headerName: "Total Inactive players",
    width: 200,
    flex: 1,
  },
  {
    field: "15-21",
    headerName: "15-21",
    width: 100,
    flex: 1,
  },
  {
    field: "22-27",
    headerName: "22-27",
    width: 100,
    flex: 1,
  },
  {
    field: "28-34",
    headerName: "28-34",
    width: 100,
    flex: 1,
  },
  {
    field: "35-44",
    headerName: "35-44",
    width: 100,
    flex: 1,
  },
  {
    field: "45-54",
    headerName: "45-54",
    width: 100,
    flex: 1,
  },
  {
    field: "55-64",
    headerName: "55-64",
    width: 100,
    flex: 1,
  },
  {
    field: "65+",
    headerName: "65+",
    width: 100,
    flex: 1,
  },
];

export const activePlayerColumns = [
  {
    field: "country",
    headerName: "Country",
    width: 200,
    flex: 1,
  },
  {
    field: "number",
    headerName: "Total Active players",
    width: 200,
    flex: 1,
  },
  {
    field: "rate",
    headerName: "Rate",
    width: 100,
    renderCell: (params) => {
      return `${params.row.rate} %`;
    },
    flex: 1,
  },
];

export const activeAgePlayerColumns = [
  {
    field: "age",
    headerName: "Age",
    width: 200,
    flex: 1,
  },
  {
    field: "number",
    headerName: "Total active players",
    width: 200,
    flex: 1,
  },
  {
    field: "rate",
    headerName: "Rate",
    width: 100,
    renderCell: (params) => {
      return `${params.row.rate} %`;
    },
    flex: 1,
  },
];
