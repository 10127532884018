import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
const { fetch } = require("../service/index");

const useGetUsers = (param) => {
  return useQuery(
    [
      "all-users",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/admin/users`,
        params: {
          ...query.queryKey[1],
        },
      });
      if (res.data && res.data.rows.length > 0) {
        res.data.rows.map((doc, index) => {
          doc.index = (Number(res.data.page) - 1) * Number(res.data.perPage) + index + 1;
        });
      }
      return res.data;
    }
  );
};

function useGetUsersById(id) {
  return useQuery(
    ["users-detail"],
    async () => {
      const res = await fetch({
        url: `/api/admin/users/` + id,
      });
      return res.data;
    },
    {
      retry: false,
    }
  );
}

function useGetInfoQuestUser(id) {
  return useQuery(
    ["users-quest"],
    async () => {
      const res = await fetch({
        url: `/api/user-quest/info-user/` + id,
      });
      return res.data.quest;
    },
    {
      retry: false,
    }
  );
}

function useUpdateUsers(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/admin/users/${id}`,
        method: "PUT",
        data,
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("users-detail");
      },
    }
  );
}

export { useGetUsers, useGetUsersById, useUpdateUsers, useGetInfoQuestUser };
