// @mui material components
import { Card, Icon, TextField, Grid, InputLabel, Select, MenuItem, Stack } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState, useEffect, useRef } from "react";
import { newsColumns } from "../../../mock/data-news";
import { DataGrid } from "@mui/x-data-grid";
import { ListStatusNewsList } from "../../../constant/constant";
import { useGetNews, useDeleteNews } from "../../../queries/news.queries";
import { DeleteNewsDialog } from "examples/DeleteNewsDialog";
import { getQuerySearch, convertQuery } from "../../../utils/utils";
import { newSchema } from "./schemas";
import { yupResolver } from "@hookform/resolvers/yup";

function Default() {
  const navigate = useNavigate();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sortModel, setSortModel] = useState({
    sort: "",
    model: [],
  });
  const [q, setQ] = useState({});
  const [open, setOpen] = useState(false);
  const [newsId, setNewsId] = useState(null);
  const location = useLocation().search;
  const [updateNavigate, setUpdateNavigate] = useState(false);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousLocation = usePrevious(location);
  const defaultValues = {
    search: "",
    status: "all",
    durationFrom: null,
    durationTo: null,
    createdFrom: null,
    createdTo: null,
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(newSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    data: dataUsers,
    refetch,
    isLoading,
  } = useGetNews({
    page: pageCurrent,
    perPage,
    sort: sortModel.sort,
    ...q,
  });

  useEffect(() => {
    if (!updateNavigate) return;
    navigate({
      pathname: "/news",
      search: convertQuery({
        page: pageCurrent,
        perPage,
        sort: sortModel.sort,
        ...q,
      }),
    });
  }, [pageCurrent, perPage, sortModel, q]);

  const pageChange = (page) => {
    setUpdateNavigate(true);
    setPageCurrent(page + 1);
    refetch();
  };

  const pageSizeChange = (pageSize) => {
    setUpdateNavigate(true);
    setPerPage(pageSize);
    refetch();
  };

  useEffect(() => {
    if (location) {
      const query = getQuerySearch(location);
      if (!query) {
        return;
      }

      if (query.page) {
        setPageCurrent(Number(query.page));
      }
      if (query.perPage) {
        setPerPage(Number(query.perPage));
      }
      if (query.sort) {
        setSortModel({
          sort: query.sort,
          model: [
            {
              field: query.sort.replace("-", ""),
              sort: query.sort.includes("-") ? "desc" : "asc",
            },
          ],
        });
      }
      delete query.page;
      delete query.perPage;
      delete query.sort;
      reset({
        ...query,
      });
      setQ(query);
      refetch();
    }
  }, []);

  useEffect(() => {
    if (!location && previousLocation != location) {
      setUpdateNavigate(false);
      setPageCurrent(1);
      setPerPage(20);
      setSortModel({
        sort: "",
        model: [],
      });
      reset(defaultValues);
      setQ({});
      refetch();
    }
  }, [location]);

  const handleSortModelChange = (value) => {
    let sort = "";
    if (value && value[0]) {
      if (value[0].sort === "asc") {
        sort = value[0].field;
      } else {
        sort = "-" + value[0].field;
      }
    }
    setUpdateNavigate(true);
    setSortModel({
      sort,
      model: value,
    });
    refetch();
  };

  const handleDelete = (id) => {
    setNewsId(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setNewsId(null);
  };

  const onSubmit = handleSubmit((data) => {
    const query = {};
    for (let key in defaultValues) {
      if (defaultValues[key] === "all") {
        if (data[key] !== "all") {
          query[key] = data[key];
        }
      } else if (Array.isArray(defaultValues[key])) {
        if (data[key].length > 0) {
          query[key] = data[key].join(",");
        }
      } else if (data[key]) {
        if (
          key === "createdFrom" ||
          key === "createdTo" ||
          key === "durationFrom" ||
          key === "durationTo"
        ) {
          query[key] = new Date(data[key]);
        } else {
          query[key] = data[key].trim();
        }
      }
    }
    setUpdateNavigate(true);
    setPageCurrent(1);
    setQ(query);
    refetch();
  });

  newsColumns.map((doc) => {
    if (doc.field === "action") {
      doc.renderCell = (params) => {
        return (
          <ArgonBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <ArgonBox mr={1}>
              <ArgonButton
                variant="outlined"
                color="dark"
                component={Link}
                to={`/news/${params.row._id}`}
              >
                Edit
              </ArgonButton>
            </ArgonBox>

            <ArgonBox>
              <ArgonButton
                variant="outlined"
                color="error"
                onClick={() => handleDelete(params.row._id)}
              >
                Delete
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        );
      };
    }
  });

  const onReset = () => {
    setQ({});
    reset(defaultValues);
  };

  return (
    <DashboardLayout>
      <DeleteNewsDialog
        open={open}
        onClose={handleCloseDialog}
        title="News"
        id={newsId}
        handleRefetch={refetch}
        deleteQuery={useDeleteNews}
      />
      <DashboardNavbar />
      <ArgonBox py={0}>
        <ArgonBox mb={3}>
          <Card style={{ padding: "10px" }}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              pt={0}
              pr={0}
              pb={1}
            >
              <ArgonTypography variant="h6" sx={{ fontSize: "20px" }}>
                List News
              </ArgonTypography>
              <ArgonButton
                component={Link}
                to="/news/add"
                variant="outlined"
                color="info"
                size="medium"
                rel="noreferrer"
              >
                <Icon sx={{ marginRight: "10px", float: "right" }}>add</Icon>Add
              </ArgonButton>
            </ArgonBox>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={1}
              ml={3}
            >
              <FormProvider onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4} lg={3}>
                    <InputLabel id="demo-simple-select-label" aria-setsize={10}>
                      Search
                    </InputLabel>
                    <ArgonBox pr={1}>
                      <Controller
                        name="search"
                        control={control}
                        render={({ field }) => (
                          <ArgonInput
                            placeholder="Type here..."
                            {...field}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                onSubmit();
                              }
                            }}
                            startAdornment={
                              <Icon fontSize="small" style={{ marginRight: "6px" }}>
                                search
                              </Icon>
                            }
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel id="status-user">Status</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field }) => (
                            <Select labelId="status-user" {...field} label="Status">
                              {ListStatusNewsList.map((statusUser, index) => {
                                return (
                                  <MenuItem key={"status-" + index} value={statusUser.value}>
                                    {statusUser.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} pr={2}>
                    <ArgonBox pr={1}>
                      <InputLabel>Duration</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="durationFrom"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                        <Controller
                          name="durationTo"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} pr={2}>
                    <ArgonBox pr={1}>
                      <InputLabel>Created</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="createdFrom"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                        <Controller
                          name="createdTo"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputLabel> &nbsp;</InputLabel>
                    <ArgonBox sx={{ display: "flex", gap: 1 }}>
                      <ArgonButton
                        type="submit"
                        onClick={onSubmit}
                        variant="outlined"
                        color="dark"
                        size="medium"
                        rel="noreferrer"
                      >
                        <Icon sx={{ marginRight: "10px" }}>search</Icon>Search
                      </ArgonButton>
                      <ArgonButton
                        ml={3}
                        color="info"
                        className="btn"
                        variant="outlined"
                        onClick={onReset}
                      >
                        Reset
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </FormProvider>
            </ArgonBox>
            <ArgonBox
              pb={3}
              ml={3}
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <DataGrid
                autoHeight
                className="datagrid"
                paginationMode="server"
                rows={dataUsers ? dataUsers.rows : []}
                loading={isLoading}
                rowCount={dataUsers ? dataUsers.total : 0}
                onPageChange={(newPage) => pageChange(newPage)}
                columns={newsColumns}
                pageSize={perPage}
                onPageSizeChange={(newPageSize) => pageSizeChange(newPageSize)}
                rowsPerPageOptions={[20, 50, 100]}
                getRowId={(row) => row._id}
                pagination
                sortingMode="server"
                sortModel={sortModel.model}
                onSortModelChange={handleSortModelChange}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                      No data
                    </Stack>
                  ),
                }}
              />
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
