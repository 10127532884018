// @mui material components
import { Card, Grid, InputLabel, Select, MenuItem } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import { FormProvider, useForm, Controller } from "react-hook-form";
import { AgeGroupList } from "../../../constant/constant";
import { useState } from "react";
import { useGetGraphInactivePlayer } from "../../../queries/dashboard.queries";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import PieChart from "../../../examples/Charts/PieChart";
import HorizontalChart from "../../../examples/Charts/HorizontalChart";
import { CountryInactivePlayerData, AgeGroupInactivePlayerData } from "../data/index";
import { subDate, startOfDay, endOfDay } from "../../../utils/formatDate.ts";
import { inactivePlayerColumns } from "../../../mock/data-dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function Default() {
  const [q, setQ] = useState({});
  const [viewDetail, setViewDetail] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const {
    data: dataGraph,
    refetch,
    isLoading,
  } = useGetGraphInactivePlayer({
    ...q,
  });
  const countryInactivePlayerData = { ...CountryInactivePlayerData };
  const ageGroupInactivePlayerData = { ...AgeGroupInactivePlayerData };
  let tableData = [];
  if (dataGraph && Array.isArray(dataGraph)) {
    countryInactivePlayerData.labels = [];
    countryInactivePlayerData.datasets[0].data = [];
    ageGroupInactivePlayerData.labels = [];
    ageGroupInactivePlayerData.datasets[0].data = [];
    dataGraph.map((doc) => {
      countryInactivePlayerData.labels.push(doc.country);
      countryInactivePlayerData.datasets[0].data.push(doc.number);
    });
    ageGroupInactivePlayerData.labels = AgeGroupList.filter((doc) => doc.value !== "all").map(
      (doc) => doc.label
    );
    ageGroupInactivePlayerData.datasets[0].data = AgeGroupList.filter(
      (doc) => doc.value !== "all"
    ).map((doc) => {
      let count = 0;
      dataGraph.map((item) => {
        item.ageRange.map((ageData) => {
          if (ageData.range === doc.value) {
            count += ageData.count;
          }
        });
      });
      return count;
    });
    const totalCount = {
      _id: 0,
      country: `${dataGraph.length} countries`,
      total: 0,
    };
    AgeGroupList.filter((doc) => doc.value !== "all").map((doc) => {
      totalCount[doc.value] = 0;
    });
    tableData = dataGraph.map((item, index) => {
      const obj = { _id: index + 1, country: item.country, total: 0 };
      AgeGroupList.filter((doc) => doc.value !== "all").map((doc) => {
        obj[doc.value] = 0;
      });
      item.ageRange.map((ageData) => {
        totalCount.total += ageData.count;
        totalCount[ageData.range] += ageData.count;
        obj[ageData.range] += ageData.count;
        obj.total += ageData.count;
      });
      return obj;
    });
    tableData = [totalCount].concat(tableData);
  }

  const defaultValues = {
    ageGroup: "all",
    fromDate: "",
    toDate: "",
  };
  const { control, handleSubmit } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        fromDate: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          }),
        toDate: yup
          .string()
          .nullable()
          .test("isDate", "Invalid duration", (value) => {
            return !value || !isNaN(new Date(value));
          })
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.fromDate)) ||
              new Date(value) >= new Date(contextTest.parent.fromDate)
            );
          }),
      })
    ),
    mode: "onChange",
  });

  const onSubmit = handleSubmit((data) => {
    const query = {};
    for (let key in defaultValues) {
      if (data[key]) {
        if (key === "fromDate") {
          query[key] = startOfDay(data[key]);
        } else if (key === "toDate") {
          query[key] = endOfDay(data[key]);
        } else {
          if (data[key] !== "all") {
            query[key] = data[key];
          }
        }
      }
    }
    setQ(query);
    refetch();
  });

  return (
    <Card style={{ padding: "10px" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pt={0}
        pr={0}
        pb={1}
      >
        <ArgonTypography variant="h6">Graph of Inactive players</ArgonTypography>
        <ArgonButton
          onClick={() => setViewDetail(!viewDetail)}
          variant="text"
          color="info"
          size="medium"
          rel="noreferrer"
        >
          {!viewDetail ? `View Detail >` : `Hide Detail`}
        </ArgonButton>
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pb={1} ml={3}>
        <FormProvider onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1} rowSpacing={0.1}>
            <Grid item xs={12} sm={6} lg={3}>
              <ArgonBox pr={1}>
                <InputLabel id="age-group">Age group</InputLabel>
                <ArgonBox pr={1}>
                  <Controller
                    name="ageGroup"
                    control={control}
                    render={({ field }) => (
                      <Select labelId="age-group" {...field} label="Age group">
                        {AgeGroupList.map((dataAge, index) => {
                          return (
                            <MenuItem key={"age-group-" + index} value={dataAge.value}>
                              {dataAge.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <ArgonBox mb={3}>
                <InputLabel>Date range</InputLabel>
                <ArgonBox sx={{ display: "flex", gap: 1 }}>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        {...field}
                        defaultValues={null}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD/MM/YYYY",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            sx={{ root: { height: "50px" } }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD/MM/YYYY",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={2}>
              <InputLabel> &nbsp;</InputLabel>
              <ArgonBox pr={1}>
                <ArgonButton
                  type="submit"
                  onClick={onSubmit}
                  variant="outlined"
                  color="dark"
                  size="medium"
                  rel="noreferrer"
                >
                  Filter
                </ArgonButton>
              </ArgonBox>
            </Grid>
          </Grid>
        </FormProvider>
      </ArgonBox>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={12} lg={8}>
          <HorizontalChart chart={countryInactivePlayerData} />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <PieChart chart={ageGroupInactivePlayerData} height="500px" />
        </Grid>
      </Grid>
      {viewDetail && (
        <>
          <ArgonBox
            pb={3}
            ml={3}
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <DataGrid
              autoHeight
              className="datagrid"
              rows={tableData}
              rowCount={tableData.length}
              columns={inactivePlayerColumns}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              getRowId={(row) => row._id}
              pagination
            />
          </ArgonBox>
        </>
      )}
    </Card>
  );
}

export default Default;
