// Argon Dashboard 2 MUI components
import { Icon } from "@mui/material";
import ArgonBadge from "components/ArgonBadge";
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonButton from "components/ArgonButton";
import { RewardTypeLabel } from "../constant/constant";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
import { Link } from "react-router-dom";

export const rewardColumns = [
  {
    field: "",
    headerName: "#",
    sortable: false,
    width: 50,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row._id) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return (
        <ArgonBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
          <ArgonButton
            variant="outlined"
            color="dark"
            component={Link}
            to={`/reward/${params.row._id}`}
          >
            Edit
          </ArgonButton>
        </ArgonBox>
      );
    },
  },
  {
    field: "name",
    headerName: "Reward name",
    width: 200,
  },
  {
    field: "rewardType",
    headerName: "Reward type",
    width: 230,
    renderCell: (params) => {
      return RewardTypeLabel[params.row.rewardType];
    },
  },
  {
    field: "createdAt",
    headerName: "Created time",
    width: 160,
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
  },
];

export const rewardCardColumns = [
  {
    field: "rarity",
    headerName: "Rarity",
    width: 100,
  },
  {
    field: "WH",
    headerName: "WH",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-WH`}
          type="number"
          min={0} defaultValue={params.row.WH || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "GR",
    headerName: "GR",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-GR`}
          type="number"
          min={0} defaultValue={params.row.GR || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "BU",
    headerName: "BU",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-BU`}
          type="number"
          min={0} defaultValue={params.row.BU || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "BK",
    headerName: "BK",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-BK`}
          type="number"
          min={0} defaultValue={params.row.BK || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "PR",
    headerName: "PR",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-PR`}
          type="number"
          min={0} defaultValue={params.row.PR || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "RD",
    headerName: "RD",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-RD`}
          type="number"
          min={0} defaultValue={params.row.RD || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "OR",
    headerName: "OR",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-OR`}
          type="number"
          min={0} defaultValue={params.row.OR || 0}
          className="input-number-card"
        />
      );
    },
  },
  {
    field: "CL",
    headerName: "CL",
    width: 100,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <input
          name={`${params.row.rarity}-CL`}
          type="number"
          min={0} defaultValue={params.row.CL || 0}
          className="input-number-card"
        />
      );
    },
  },
];
