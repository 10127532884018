// @mui material components
import Card from "@mui/material/Card";
import {
  Grid,
  Icon,
  TextField,
  CircularProgress,
  Checkbox,
  Radio,
  FormControlLabel,
  Typography,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateQuest, useGetQuestById } from "../../../queries/quests.queries";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { questSchema } from "./schemas";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  ListStatusQuestList,
  ListQuestType,
  CardType,
  MutationTypeList,
  TribeType,
  CostComparisonList,
  RewardMutationList,
  RewardMutationEnum,
  StatusQuest,
} from "../../../constant/constant";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QuestType } from "../../../utils/quest.enum.ts";
import { toast } from "react-toastify";

function Default() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoadingError } = useGetQuestById(id);
  const [disableOption, setDisableOption] = useState({});

  const methods = useForm({
    resolver: yupResolver(questSchema),
    mode: "onChange",
    defaultValues: {
      questName: "",
      type: "",
      total: "",
      durationFrom: "",
      durationTo: "",
      status: 2,
      mutationPoint: "",
      mutationOption: 1,
      mutationType: "",
      cardType: "",
      conditionMutationType: "",
      tribeType: "",
      speciflicCost: "",
      costComparison: "LESS",
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
  } = methods;

  const { mutate, isSuccess, isLoading } = useUpdateQuest(id);
  const onBack = () => {
    navigate(-1);
  };

  const onSubmit = handleSubmit((data) => {
    trigger();
    mutate(data);
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/quest");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isLoadingError) {
      navigate("/quest");
    }
  }, [isLoadingError, navigate]);

  useEffect(() => {
    if (data) {
      if (
        ((!data.durationFrom || new Date(data.durationFrom) < new Date()) &&
          (!data.durationTo || new Date(data.durationTo) > new Date()) &&
          Number(data.status) === StatusQuest.ACTIVE) ||
        Number(data.status) === StatusQuest.STOPPED
      ) {
        setDisableOption({
          disabled: true,
        });
      } else {
        setDisableOption({});
      }
      reset({
        questName: data.questName,
        type: data.type,
        total: data.total,
        durationFrom: data.durationFrom || "",
        durationTo: data.durationTo || "",
        status: data.status,
        mutationPoint: data.mutationPoint,
        mutationOption: data.mutationOption,
        mutationType: data.mutationType || "",
        cardType: data.cardType || "",
        conditionMutationType: data.conditionMutationType || "",
        tribeType: data.tribeType || "",
        speciflicCost: data.speciflicCost || "",
        costComparison: data.costComparison || "LESS",
      });
    }
  }, [data]);

  const watchShowField = watch("type");
  const watchMutationType = watch("mutationOption");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Typography variant="h6">Edit Quest</Typography>
            </ArgonBox>
            <FormProvider {...methods}>
              <ArgonBox component="form" onSubmit={onSubmit} ml={3} mr={3}>
                <Grid item xs={4}>
                  <ArgonBox>
                    <Box>
                      <Label required={true}>Quest name</Label>
                      <Controller
                        name="questName"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...disableOption}
                            fullWidth
                            size="small"
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            placeholder="Type questName..."
                            {...field}
                            sx={{ root: { height: "50px" }, mt: 1 }}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ mb: 1, mt: 1 }}>
                      <Typography variant="h4">Condition</Typography>
                      <Box>
                        <Label required={true}>Quest Type</Label>
                        <Controller
                          name="type"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <Select
                                disabled={true}
                                labelId="type-user"
                                {...field}
                                label="Quest Type"
                              >
                                {ListQuestType.filter((doc) => doc.value != "all").map(
                                  (questType, index) => {
                                    return (
                                      <MenuItem key={"type-" + index} value={questType.value}>
                                        {questType.label}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                {fieldState.error?.message}
                              </p>
                            </>
                          )}
                        />
                      </Box>
                      <Box>
                        <Label required={true}>Number (X=)</Label>
                        <Controller
                          name="total"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              placeholder="Input number > 0"
                              {...field}
                              sx={{ root: { height: "50px" }, mt: 1 }}
                            />
                          )}
                        />
                      </Box>
                      {Number(watchShowField) === QuestType.PLAY_X_CARD_SPECIFIC_TYPE && (
                        <Box>
                          <Label required={true}>Card Type</Label>
                          <Controller
                            name="cardType"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  {...disableOption}
                                  labelId="card-type"
                                  {...field}
                                  label="Select card type"
                                >
                                  {CardType.map((cardType, index) => {
                                    return (
                                      <MenuItem key={"type-" + index} value={cardType.value}>
                                        {cardType.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                  {fieldState.error?.message}
                                </p>
                              </>
                            )}
                          />
                        </Box>
                      )}
                      {Number(watchShowField) === QuestType.PLAY_X_CARD_SPECIFIC_MUTATION && (
                        <Box>
                          <Label required={true}>Mutation Type</Label>
                          <Controller
                            name="conditionMutationType"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  {...disableOption}
                                  labelId="type-user"
                                  {...field}
                                  label="Quest Type"
                                >
                                  {MutationTypeList.map((conditionMutationType, index) => {
                                    return (
                                      <MenuItem
                                        key={"type-" + index}
                                        value={conditionMutationType.value}
                                      >
                                        {conditionMutationType.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                  {fieldState.error?.message}
                                </p>
                              </>
                            )}
                          />
                        </Box>
                      )}
                      {Number(watchShowField) === QuestType.PLAY_X_CARD_SPECIFIC_TRIBE && (
                        <Box>
                          <Label required={true}>Tribe Type</Label>
                          <Controller
                            name="tribeType"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  {...disableOption}
                                  labelId="type-user"
                                  {...field}
                                  label="Quest Type"
                                >
                                  {TribeType.map((tribeType, index) => {
                                    return (
                                      <MenuItem key={"type-" + index} value={tribeType.value}>
                                        {tribeType.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                  {fieldState.error?.message}
                                </p>
                              </>
                            )}
                          />
                        </Box>
                      )}
                      {Number(watchShowField) === QuestType.PLAY_X_CARD_SPECIFIC_CONST && (
                        <Box>
                          <Label required={true}>Specific cost</Label>
                          <Controller
                            name="speciflicCost"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...disableOption}
                                {...field}
                                fullWidth
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Input number > 0"
                                sx={{ root: { height: "50px" }, mt: 1 }}
                              />
                            )}
                          />
                          <Label>Cost comparison</Label>
                          <Controller
                            name="costComparison"
                            control={control}
                            render={({ field }) => (
                              <Grid container>
                                {CostComparisonList.map((item, index) => (
                                  <Grid item xs={1} key={"grid-" + index}>
                                    <FormControlLabel
                                      {...field}
                                      key={item.label}
                                      label={item.label}
                                      control={
                                        <Radio
                                          {...disableOption}
                                          sx={{ ml: 1 }}
                                          value={item.value}
                                          checked={getValues("costComparison") === item.value}
                                        />
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            )}
                          />
                        </Box>
                      )}
                    </Box>
                  </ArgonBox>
                </Grid>
                <Grid item xs={4}>
                  <ArgonBox mb={1}>
                    <Box>
                      <Typography variant="h4">Take places </Typography>
                      <Box sx={{ display: "flex", gap: 10 }}>
                        <Box>
                          <Label>Duration</Label>
                          <ArgonBox sx={{ display: "flex", gap: 1 }}>
                            <Controller
                              name="durationFrom"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    {...disableOption}
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    defaultValues={null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...disableOption}
                                        {...params}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                        sx={{ root: { height: "50px" } }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                            <Controller
                              name="durationTo"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    {...disableOption}
                                    inputFormat="DD/MM/YYYY"
                                    {...field}
                                    renderInput={(params) => (
                                      <TextField
                                        {...disableOption}
                                        {...params}
                                        helperText={error?.message ? error?.message : null}
                                        error={invalid}
                                        sx={{ root: { height: "50px" } }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </ArgonBox>
                        </Box>
                        <Box>
                          <Label>Status</Label>
                          <ArgonBox mb={1}>
                            <Controller
                              name="status"
                              value={[]}
                              control={control}
                              render={({ field }) => (
                                <Grid container>
                                  {ListStatusQuestList.filter((doc) => doc.value != "all").map(
                                    (item, index) => (
                                      <Box key={"grid-" + index}>
                                        <FormControlLabel
                                          {...field}
                                          key={item.label}
                                          label={item.label}
                                          control={
                                            <Radio
                                              {...disableOption}
                                              sx={{ ml: 2 }}
                                              value={item.value}
                                              checked={Number(getValues("status")) === item.value}
                                            />
                                          }
                                        />
                                      </Box>
                                    )
                                  )}
                                </Grid>
                              )}
                            />
                          </ArgonBox>
                        </Box>
                      </Box>
                    </Box>
                  </ArgonBox>
                </Grid>
                <Grid item xs={4}>
                  <ArgonBox>
                    <Box>
                      <Typography variant="h4">Reward </Typography>
                      <ArgonBox>
                        <Label required={true}>Mutation points</Label>
                        <Controller
                          name="mutationPoint"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              size="small"
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              placeholder="Type mutation points..."
                              {...field}
                              sx={{ root: { height: "50px" }, mt: 1 }}
                            />
                          )}
                        />
                      </ArgonBox>
                      <ArgonBox>
                        <Label required={true}>Mutation Type</Label>
                        <Controller
                          name="mutationOption"
                          value={[]}
                          control={control}
                          render={({ field }) => (
                            <Grid container>
                              {RewardMutationList.filter((doc) => doc.value != "all").map(
                                (item, index) => (
                                  <ArgonBox key={"grid-" + index}>
                                    <FormControlLabel
                                      {...field}
                                      key={item.label}
                                      label={item.label}
                                      control={
                                        <Radio
                                          {...disableOption}
                                          sx={{ ml: 2 }}
                                          value={item.value}
                                          checked={
                                            Number(getValues("mutationOption")) === item.value
                                          }
                                        />
                                      }
                                    />
                                  </ArgonBox>
                                )
                              )}
                            </Grid>
                          )}
                        />
                      </ArgonBox>
                      {Number(watchMutationType) === RewardMutationEnum.SPECTIFY && (
                        <Grid item xs={12}>
                          <ArgonBox>
                            <ArgonBox>
                              <Controller
                                name="mutationType"
                                control={control}
                                render={({ field, fieldState }) => (
                                  <>
                                    <Select
                                      {...disableOption}
                                      labelId="reward-type"
                                      {...field}
                                      label="Reward Type"
                                    >
                                      {MutationTypeList.filter((doc) => doc.value != "all").map(
                                        (mutationType, index) => {
                                          return (
                                            <MenuItem
                                              key={"type-" + index}
                                              value={mutationType.value}
                                            >
                                              {mutationType.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                    <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                      {fieldState.error?.message}
                                    </p>
                                  </>
                                )}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                      )}
                    </Box>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                    {!disableOption.disabled && (
                      <ArgonButton
                        m={3}
                        color="info"
                        className="btn"
                        variant="contained"
                        type="submit"
                        startIcon={isLoading && <CircularProgress size={20} />}
                        disabled={isLoading}
                      >
                        Submit
                      </ArgonButton>
                    )}
                    <ArgonButton
                      ml={3}
                      color="info"
                      className="btn"
                      variant="outlined"
                      onClick={onBack}
                      startIcon={isLoading && <CircularProgress size={20} />}
                    >
                      Cancel
                    </ArgonButton>
                  </Box>
                </Grid>
              </ArgonBox>
            </FormProvider>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
