import * as yup from "yup";
export const cardSchema = yup.object().shape({
  durationFrom: yup.string().nullable(),
  durationTo: yup
    .string()
    .nullable()
    .test("gatherThan", "Invalid duration", (value, contextTest) => {
      return (
        !value ||
        !contextTest.parent.durationFrom ||
        isNaN(new Date(value)) ||
        isNaN(new Date(contextTest.parent.durationFrom)) ||
        new Date(value) >= new Date(contextTest.parent.durationFrom)
      );
    }),
  createdFrom: yup.string().nullable(),
  createdTo: yup
    .string()
    .nullable()
    .test("gatherThan", "Invalid created", (value, contextTest) => {
      return (
        !value ||
        !contextTest.parent.createdFrom ||
        isNaN(new Date(value)) ||
        isNaN(new Date(contextTest.parent.createdFrom)) ||
        new Date(value) >= new Date(contextTest.parent.createdFrom)
      );
    }),
});
