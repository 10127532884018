import * as yup from "yup";

export const newsSchema = yup.object().shape({
  img: yup
    .mixed()
    .test("required", "Image is required", (value) => {
      return !!value;
    })
    .test("file-type", "Invalid image", (value) => {
      return !value || ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(value?.type);
    })
    .test("fileSize", "Image size is too large.", (value) => {
      return !value || value?.size <= 5 * 1024 * 1024;
    }),
  title: yup
    .string()
    .required("Title is required!")
    .max(25, "Can not input more than 25 characters"),
  durationFrom: yup
    .string()
    .nullable()
    .test("isDate", "Invalid duration", (value) => {
      return !value || !isNaN(new Date(value));
    }),
  durationTo: yup
    .string()
    .nullable()
    .test("isDate", "Invalid duration", (value) => {
      return !value || !isNaN(new Date(value));
    })
    .test("gatherThan", "Invalid duration", (value, contextTest) => {
      return (
        !value ||
        !contextTest.parent.durationFrom ||
        isNaN(new Date(value)) ||
        isNaN(new Date(contextTest.parent.durationFrom)) ||
        new Date(value) >= new Date(contextTest.parent.durationFrom)
      );
    }),
  status: yup.string(),
  description: yup
    .string()
    .required("Description is required!")
    .max(150, "Can not input more than 150 characters"),
  externalLink: yup.string(),
});
