// @mui material components
import { Card, Icon, TextField, Grid, InputLabel, Select, MenuItem, Stack } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState, useRef, useEffect } from "react";
import { rankColumns } from "../../../mock/data-rank";
import { DataGrid } from "@mui/x-data-grid";
import { ListOperationRankList, ListOperationRank } from "../../../constant/constant";
import { useGetRanks, useStopRank } from "../../../queries/ranks.queries";
import { rankSchema } from "./schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteNewsDialog } from "examples/DeleteNewsDialog";
import { RankCloneDialog } from "../RankCloneDialog";
import { getQuerySearch, convertQuery } from "../../../utils/utils";

function Default() {
  const navigate = useNavigate();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sortModel, setSortModel] = useState({
    sort: "",
    model: [],
  });
  const [q, setQ] = useState({});
  const [open, setOpen] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [rankId, setRankId] = useState(null);
  const [rankClone, setRankClone] = useState(null);

  const location = useLocation().search;
  const [updateNavigate, setUpdateNavigate] = useState(false);
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const previousLocation = usePrevious(location);

  const {
    data: dataUsers,
    refetch,
    isLoading,
  } = useGetRanks({
    page: pageCurrent,
    perPage,
    sort: sortModel.sort,
    ...q,
  });

  useEffect(() => {
    if (!updateNavigate) return;
    navigate({
      pathname: "/rank",
      search: convertQuery({
        page: pageCurrent,
        perPage,
        sort: sortModel.sort,
        ...q,
      }),
    });
  }, [pageCurrent, perPage, sortModel, q]);

  const pageChange = (page) => {
    setUpdateNavigate(true);
    setPageCurrent(page + 1);
    refetch();
  };

  const pageSizeChange = (pageSize) => {
    setUpdateNavigate(true);
    setPerPage(pageSize);
    refetch();
  };

  useEffect(() => {
    if (location) {
      const query = getQuerySearch(location);
      if (!query) {
        return;
      }

      if (query.page) {
        setPageCurrent(Number(query.page));
      }
      if (query.perPage) {
        setPerPage(Number(query.perPage));
      }
      if (query.sort) {
        setSortModel({
          sort: query.sort,
          model: [
            {
              field: query.sort.replace("-", ""),
              sort: query.sort.includes("-") ? "desc" : "asc",
            },
          ],
        });
      }
      delete query.page;
      delete query.perPage;
      delete query.sort;
      setQ({ ...query });
      reset({
        ...query,
      });
      refetch();
    }
  }, []);

  useEffect(() => {
    if (!location && previousLocation != location) {
      setUpdateNavigate(false);
      setPageCurrent(1);
      setPerPage(20);
      setSortModel({
        sort: "",
        model: [],
      });
      reset(defaultValues);
      setQ({});
      refetch();
    }
  }, [location]);

  const handleSortModelChange = (value) => {
    let sort = "";
    if (value && value[0]) {
      if (value[0].sort === "asc") {
        sort = value[0].field;
      } else {
        sort = "-" + value[0].field;
      }
    }
    setUpdateNavigate(true);
    setSortModel({
      sort,
      model: value,
    });
    refetch();
  };

  const defaultValues = {
    search: "",
    durationFrom: null,
    durationTo: null,
    createdFrom: null,
    createdTo: null,
    operation: "all",
  };

  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(rankSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const onSubmit = handleSubmit((data) => {
    const query = {};
    for (let key in defaultValues) {
      if (defaultValues[key] === "all") {
        if (data[key] !== "all") {
          query[key] = data[key];
        }
      } else if (Array.isArray(defaultValues[key])) {
        if (data[key].length > 0) {
          query[key] = data[key].join(",");
        }
      } else if (data[key]) {
        if (key === "createdFrom" || key === "createdTo") {
          query[key] = new Date(data[key]);
        } else {
          query[key] = data[key].trim();
        }
      }
    }
    setUpdateNavigate(true);
    setPageCurrent(1);
    setQ(query);
    refetch();
  });

  const forceToStop = (id) => {
    setRankId(id);
    setOpen(true);
  };

  const cloneRank = (id) => {
    console.log(id, dataUsers.rows);
    const dataRank = dataUsers?.rows?.find((doc) => doc._id === id);
    if (!dataRank) {
      return;
    }
    setRankId(id);
    setRankClone(dataRank);
    setOpenClone(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setOpenClone(false);
    setRankId(null);
    setRankClone(null);
  };

  const onReset = () => {
    setQ({});
    reset(defaultValues);
  };

  rankColumns.map((doc) => {
    if (doc.field === "action") {
      doc.renderCell = (params) => {
        return (
          <ArgonBox
            display="contents"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            {Number(params.row.operation) === ListOperationRank.PENDING && (
              <ArgonButton
                variant="outlined"
                color="dark"
                component={Link}
                to={`/rank/${params.row._id}`}
                sx={{ marginRight: "10px", margin: "0 auto" }}
              >
                Update
              </ArgonButton>
            )}
            {(Number(params.row.operation) === ListOperationRank.HAPPENING ||
              Number(params.row.operation) === ListOperationRank.CLOSED ||
              Number(params.row.operation) === ListOperationRank.EXPIRED) && (
              <ArgonButton
                variant="outlined"
                color="primary"
                component={Link}
                to={`/rank/${params.row._id}`}
                sx={{ marginRight: "10px", margin: "0 auto" }}
              >
                View
              </ArgonButton>
            )}
            {Number(params.row.operation) === ListOperationRank.HAPPENING && (
              <ArgonButton
                variant="outlined"
                color="error"
                onClick={() => forceToStop(params.row._id)}
                sx={{ marginRight: "10px", margin: "0 auto" }}
              >
                Force to stop
              </ArgonButton>
            )}
            <ArgonButton
              variant="outlined"
              color="error"
              onClick={() => cloneRank(params.row._id)}
              sx={{ marginRight: "10px", margin: "0 auto" }}
            >
              Clone
            </ArgonButton>
          </ArgonBox>
        );
      };
    }
  });

  return (
    <DashboardLayout>
      <DeleteNewsDialog
        open={open}
        onClose={handleCloseDialog}
        message="Are you sure you want to stop the rank season. This action cannot be undone?"
        id={rankId}
        handleRefetch={refetch}
        deleteQuery={useStopRank}
        buttonConfirm="Proceed"
      />
      <RankCloneDialog open={openClone} onClose={handleCloseDialog} id={rankId} rank={rankClone} />
      <DashboardNavbar />
      <ArgonBox py={0}>
        <ArgonBox mb={3}>
          <Card style={{ padding: "10px" }}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              pt={0}
              pr={0}
              pb={1}
            >
              <ArgonTypography variant="h6" sx={{ fontSize: "20px" }}>
                List Rank
              </ArgonTypography>
              <ArgonButton
                component={Link}
                to="/rank/add"
                variant="outlined"
                color="info"
                size="medium"
                rel="noreferrer"
              >
                <Icon sx={{ marginRight: "10px", float: "right" }}>add</Icon>Add
              </ArgonButton>
            </ArgonBox>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={1}
              ml={3}
            >
              <FormProvider onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4} lg={3}>
                    <InputLabel id="demo-simple-select-label" aria-setsize={10}>
                      Search
                    </InputLabel>
                    <ArgonBox pr={1}>
                      <Controller
                        name="search"
                        control={control}
                        render={({ field }) => (
                          <ArgonInput
                            placeholder="Search by Rank name"
                            {...field}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                onSubmit();
                              }
                            }}
                            startAdornment={
                              <Icon fontSize="small" style={{ marginRight: "6px" }}>
                                search
                              </Icon>
                            }
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3}>
                    <ArgonBox pr={1}>
                      <InputLabel>Operation state</InputLabel>
                      <ArgonBox pr={1}>
                        <Controller
                          name="operation"
                          control={control}
                          render={({ field }) => (
                            <Select {...field} label="Status">
                              {ListOperationRankList.filter(
                                (doc) => doc.value !== ListOperationRank.PREPARING
                              ).map((statusUser, index) => {
                                return (
                                  <MenuItem key={"status-" + index} value={statusUser.value}>
                                    {statusUser.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} pr={2}>
                    <ArgonBox pr={1}>
                      <InputLabel>Duration</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="durationFrom"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                        <Controller
                          name="durationTo"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} pr={2}>
                    <ArgonBox pr={1}>
                      <InputLabel>Created Date</InputLabel>
                      <ArgonBox sx={{ display: "flex", gap: 1 }}>
                        <Controller
                          name="createdFrom"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                        <Controller
                          name="createdTo"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              {...field}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type headline..."
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputLabel> &nbsp;</InputLabel>
                    <ArgonBox sx={{ display: "flex", gap: 1 }}>
                      <ArgonButton
                        type="submit"
                        onClick={onSubmit}
                        variant="outlined"
                        color="dark"
                        size="medium"
                        rel="noreferrer"
                      >
                        <Icon sx={{ marginRight: "10px" }}>search</Icon>Search
                      </ArgonButton>
                      <ArgonButton
                        ml={3}
                        color="info"
                        className="btn"
                        variant="outlined"
                        onClick={onReset}
                      >
                        Reset
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </FormProvider>
            </ArgonBox>
            <ArgonBox
              pb={3}
              ml={3}
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <DataGrid
                autoHeight
                className="datagrid"
                paginationMode="server"
                rows={dataUsers ? dataUsers.rows : []}
                loading={isLoading}
                rowCount={dataUsers ? dataUsers.total : 0}
                onPageChange={(newPage) => pageChange(newPage)}
                columns={rankColumns}
                pageSize={perPage}
                onPageSizeChange={(newPageSize) => pageSizeChange(newPageSize)}
                rowsPerPageOptions={[20, 50, 100]}
                getRowId={(row) => row._id}
                pagination
                sortingMode="server"
                sortModel={sortModel.model}
                onSortModelChange={handleSortModelChange}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                      No data
                    </Stack>
                  ),
                }}
              />
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
