import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Label } from "examples/Label";
import { useCloneRank } from "../../../queries/ranks.queries";
import { useEffect } from "react";

export const RankCloneDialog = (props) => {
  const { open, onClose, id, rank } = props;
  const closeDialog = () => {
    onClose();
  };

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .required("Event name is required")
          .max(50, "Can not input more than 50 characters"),
        durationFrom: yup.string().nullable().required("Duration From is required"),
        durationTo: yup
          .string()
          .nullable()
          .required("Duration To is required")
          .test("gatherThan", "Invalid duration", (value, contextTest) => {
            return (
              isNaN(new Date(value)) ||
              isNaN(new Date(contextTest.parent.durationFrom)) ||
              new Date(value) >= new Date(contextTest.parent.durationFrom)
            );
          }),
      })
    ),
    mode: "onChange",
    defaultValues: {
      name: "",
      durationFrom: "",
      durationTo: "",
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors, isValid },
  } = methods;

  const { mutate, isSuccess, isLoading, isError } = useCloneRank();

  const onSubmit = handleSubmit((data) => {
    trigger();
    let dataForm = {};
    for (let key in data) {
      if (key === "durationFrom" || key === "durationTo") {
        dataForm[key] = new Date(data[key]);
      } else {
        dataForm[key] = data[key];
      }
    }
    console.log(id, "rankId");
    dataForm.rankId = id;
    mutate(dataForm);
  });

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (rank) {
      reset({
        name: "CLONE - " + rank.name,
        durationFrom: rank.durationFrom,
        durationTo: rank.durationTo,
      });
    } else {
      reset({});
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "85%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {closeDialog ? (
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                fontSize: 28,
                color: "#333",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Clone rank season</Typography>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <FormProvider {...methods}>
              <ArgonBox mb={3} component="form" onSubmit={onSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ArgonBox>
                      <Label required={true}>Summary</Label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            fullWidth
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            placeholder="Type Rank name"
                            {...field}
                            sx={{ root: { height: "50px" } }}
                          />
                        )}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ArgonBox>
                      <Label required={true}>Duration</Label>
                      <ArgonBox>
                        <Controller
                          name="durationFrom"
                          control={control}
                          render={({ field, fieldState }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                inputFormat="HH:mm DD/MM/YYYY"
                                {...field}
                                wrapperClassName="datepicker"
                                defaultValues={null}
                                renderInput={(params) => (
                                  <ArgonBox>
                                    <TextField
                                      fullWidth
                                      {...params}
                                      sx={{ root: { height: "50px" } }}
                                    />
                                    <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                      {fieldState.error?.message}
                                    </p>
                                  </ArgonBox>
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ArgonBox>
                      <Label>&nbsp;</Label>
                      <ArgonBox>
                        <Controller
                          name="durationTo"
                          control={control}
                          render={({ field, fieldState }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                inputFormat="HH:mm DD/MM/YYYY"
                                {...field}
                                wrapperClassName="datepicker"
                                defaultValues={null}
                                renderInput={(params) => (
                                  <ArgonBox>
                                    <TextField
                                      fullWidth
                                      {...params}
                                      sx={{ root: { height: "50px" } }}
                                    />
                                    <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                      {fieldState.error?.message}
                                    </p>
                                  </ArgonBox>
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ArgonBox sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                      <ArgonButton
                        m={3}
                        color="info"
                        className="btn"
                        variant="contained"
                        type="submit"
                        startIcon={isLoading && <CircularProgress size={20} />}
                      >
                        Clone
                      </ArgonButton>
                      <ArgonButton
                        m={3}
                        color="info"
                        className="btn"
                        variant="outlined"
                        type="button"
                        onClick={closeDialog}
                      >
                        Cancel
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </FormProvider>
          </ArgonBox>
        </DialogContent>
      </Dialog>
    </>
  );
};
