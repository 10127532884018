// @mui material components
import Card from "@mui/material/Card";
import {
  Grid,
  Box,
  TextField,
  CircularProgress,
  Checkbox,
  Radio,
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
  FormI,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { rewardCardColumns } from "../../../mock/data-reward";
import Select2 from "react-select";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAddReward } from "../../../queries/reward.queries";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { rewardSchema } from "./schemas";
import {
  RewardMutationList,
  RewardTypeList,
  MutationTypeList,
  RewardMutationEnum,
  RewardType,
  RarityList,
  RewardCardTypeList,
  MutationType,
} from "../../../constant/constant";
import { useGetCards, useGetCardsSleeves } from "../../../queries/cards.queries";

function Default() {
  const navigate = useNavigate();
  const [q, setQ] = useState("");
  const [listCards, setListCards] = useState([]);
  const [listCardsSleeves, setListCardsSleeves] = useState({});
  const { data: dataCards, refetch } = useGetCards({
    page: 1,
    perPage: 50,
    q,
  });

  const { data: dataCardsSleeves } = useGetCardsSleeves();

  const methods = useForm({
    resolver: yupResolver(rewardSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      rewardType: 0,
      mutationPoint: "",
      mutationOption: "",
      mutationType: MutationType.OR,
      tokenNumber: "",
      rewardCardType: 1,
      cardsSpecify: [],
      sleeves: [],
      grounds: [],
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isValid },
    reset,
  } = methods;

  const { mutate, isSuccess, isLoading } = useAddReward();

  const watchRewardType = watch("rewardType");
  const watchMutationType = watch("mutationOption");
  const watchRewardCardType = watch("rewardCardType");

  const onBack = () => {
    navigate(-1);
  };
  const onSubmit = handleSubmit((data) => {
    trigger();
    const dataFormSubmit = {
      name: data.name,
      rewardType: data.rewardType,
      mutationPoint: "",
      mutationOption: "",
      mutationType: "",
      tokenNumber: "",
      rewardCardType: "",
      cardsRandom: [],
      cardsSpecify: [],
      podsRandom: [],
      sleeves: [],
      grounds: [],
    };
    if (Number(data.rewardType) === RewardType.CARD) {
      dataFormSubmit.rewardCardType = data.rewardCardType;
      if (Number(data.rewardCardType) === 1) {
        let formRewardCard = new FormData(document.querySelector("#form-reward-card"));
        for (var p of formRewardCard) {
          if (Number(p[1]) > 0) {
            dataFormSubmit.cardsRandom.push({
              rarity: p[0].split("-")[0],
              type: MutationType[p[0].split("-")[1]],
              value: Number(p[1]),
            });
          }
        }
      } else {
        const obj = {};
        data.cardsSpecify.map((doc) => {
          if (!obj[doc.value]) {
            obj[doc.value] = 1;
          } else {
            obj[doc.value]++;
          }
        });
        for (let key in obj) {
          dataFormSubmit.cardsSpecify.push({
            serial_number: key,
            value: obj[key],
          });
        }
      }
    } else if (Number(data.rewardType) === RewardType.POD) {
      let formRewardCard = new FormData(document.querySelector("#form-reward-pods"));
      for (var p of formRewardCard) {
        if (Number(p[1]) > 0) {
          dataFormSubmit.podsRandom.push({
            rarity: p[0].split("-")[0],
            type: MutationType[p[0].split("-")[1]],
            value: Number(p[1]),
          });
        }
      }
    } else if (Number(data.rewardType) === RewardType.MUTATUION_POINT) {
      dataFormSubmit.mutationPoint = data.mutationPoint;
      dataFormSubmit.mutationOption = data.mutationOption;
      dataFormSubmit.mutationType = data.mutationType;
    } else if (Number(data.rewardType) === RewardType.CARD_SLEEVES) {
      const obj = {};
      data.sleeves.map((doc) => {
        if (!obj[doc.value]) {
          obj[doc.value] = 1;
        } else {
          obj[doc.value]++;
        }
      });
      for (let key in obj) {
        dataFormSubmit.sleeves.push({
          serial_number: key,
          value: obj[key],
        });
      }
    } else if (Number(data.rewardType) === RewardType.BATTLE_GROUND) {
      const obj = {};
      data.grounds.map((doc) => {
        if (!obj[doc.value]) {
          obj[doc.value] = 1;
        } else {
          obj[doc.value]++;
        }
      });
      for (let key in obj) {
        dataFormSubmit.grounds.push({
          serial_number: key,
          value: obj[key],
        });
      }
    } else {
      dataFormSubmit.tokenNumber = data.tokenNumber;
    }
    mutate(dataFormSubmit);
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/reward");
    }
  }, [isSuccess, navigate]);

  const rowsCards = RarityList.map((doc, index) => {
    let obj = { id: index, rarity: doc.value };
    MutationTypeList.map((item) => {
      obj[item.label] = 0;
    });
    return obj;
  });

  const changeInputSelect = (e) => {
    setQ(e);
    refetch();
  };
  useEffect(() => {
    if (dataCards && Array.isArray(dataCards.rows)) {
      const rows = dataCards.rows.map((item) => {
        return {
          value: item.serial_number,
          label: `${item.serial_number} - ${item.name}`,
        };
      });
      setListCards(rows);
    }
  }, [dataCards]);

  useEffect(() => {
    if (dataCardsSleeves) {
      const sleeves = dataCardsSleeves.sleeves.map((item) => {
        return {
          value: item.serialNumber.toString(),
          label: `${item.name} - ${item.obtainCriteria}`,
        };
      });
      const battleGrounds = dataCardsSleeves.battleGrounds.map((item) => {
        return {
          value: item.serialNumber.toString(),
          label: `${item.name} - ${item.obtainCriteria}`,
        };
      });
      setListCardsSleeves({ sleeves, battleGrounds });
    }
  }, [dataCardsSleeves]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3} mb={3} style={{ minHeight: "calc(100vh - 2rem)" }}>
        <Card style={{ overflow: "unset" }}>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Typography variant="h6">Add Reward</Typography>
          </ArgonBox>
          <FormProvider {...methods}>
            <ArgonBox component="form" onSubmit={onSubmit} ml={3} mr={3}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonBox>
                      <Label required={true}>Reward name</Label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            helperText={error?.message ? error?.message : null}
                            error={invalid}
                            placeholder="Type name..."
                            {...field}
                            sx={{ root: { height: "50px" }, mt: 1 }}
                          />
                        )}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonBox>
                      <Label required={true}>Reward type</Label>
                      <Controller
                        name="rewardType"
                        control={control}
                        render={({ field }) => (
                          <Select labelId="reward-type" {...field} label="Reward Type">
                            {RewardTypeList.filter((doc) => doc.value != "all").map(
                              (rewardType, index) => {
                                return (
                                  <MenuItem key={"type-" + index} value={rewardType.value}>
                                    {rewardType.label}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        )}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  {watchRewardType === RewardType.MUTATUION_POINT && (
                    <ArgonBox>
                      <Typography variant="h4">Reward = Mutation point</Typography>
                      <ArgonBox>
                        <Label required={true}>Mutation points</Label>
                        <Controller
                          name="mutationPoint"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              fullWidth
                              size="small"
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              placeholder="Type mutation points..."
                              {...field}
                              sx={{ root: { height: "50px" }, mt: 1 }}
                            />
                          )}
                        />
                      </ArgonBox>
                      <ArgonBox>
                        <Label required={true}>Mutation Type</Label>
                        <Controller
                          name="mutationOption"
                          value={[]}
                          control={control}
                          render={({ field }) => (
                            <Grid container>
                              {RewardMutationList.filter((doc) => doc.value != "all").map(
                                (item, index) => (
                                  <ArgonBox key={"grid-" + index}>
                                    <FormControlLabel
                                      {...field}
                                      key={item.label}
                                      label={item.label}
                                      control={
                                        <Radio
                                          sx={{ ml: 2 }}
                                          value={item.value}
                                          checked={
                                            Number(getValues("mutationOption")) === item.value
                                          }
                                        />
                                      }
                                    />
                                  </ArgonBox>
                                )
                              )}
                            </Grid>
                          )}
                        />
                      </ArgonBox>
                      {Number(watchMutationType) === RewardMutationEnum.SPECTIFY && (
                        <Grid item xs={12}>
                          <ArgonBox>
                            <ArgonBox>
                              <Controller
                                name="mutationType"
                                control={control}
                                render={({ field }) => (
                                  <Select labelId="reward-type" {...field} label="Reward Type">
                                    {MutationTypeList.filter((doc) => doc.value != "all").map(
                                      (mutationType, index) => {
                                        return (
                                          <MenuItem
                                            key={"type-" + index}
                                            value={mutationType.value}
                                          >
                                            {mutationType.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                      )}
                    </ArgonBox>
                  )}
                  {watchRewardType === RewardType.TOKEN && (
                    <ArgonBox>
                      <Typography variant="h4">Reward = Tokens</Typography>
                      <ArgonBox>
                        <Label required={true}>Tokens</Label>
                        <Controller
                          name="tokenNumber"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              fullWidth
                              size="small"
                              type={"number"}
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              placeholder="Type token numbers..."
                              {...field}
                              sx={{ root: { height: "50px" }, mt: 1 }}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  )}
                  {watchRewardType === RewardType.CARD && (
                    <ArgonBox
                      sx={{
                        "MuiDataGrid-footerContainer": {
                          display: "none",
                        },
                      }}
                    >
                      <Typography variant="h4">Reward = Cards</Typography>
                      <ArgonBox>
                        <Label required={true}>Mutation Type</Label>
                        <Controller
                          name="rewardCardType"
                          value={[]}
                          control={control}
                          render={({ field }) => (
                            <Grid container>
                              {RewardCardTypeList.filter((doc) => doc.value != "all").map(
                                (item, index) => (
                                  <ArgonBox key={"grid-" + index}>
                                    <FormControlLabel
                                      {...field}
                                      key={item.label}
                                      label={item.label}
                                      control={
                                        <Radio
                                          sx={{ ml: 2 }}
                                          value={item.value}
                                          checked={
                                            Number(getValues("rewardCardType")) === item.value
                                          }
                                        />
                                      }
                                    />
                                  </ArgonBox>
                                )
                              )}
                            </Grid>
                          )}
                        />
                      </ArgonBox>
                      {Number(watchRewardCardType) == 1 && (
                        <Box
                          component="form"
                          noValidate
                          autoComplete="off"
                          id="form-reward-card"
                          mt={3}
                        >
                          <DataGrid
                            autoHeight
                            className="datagrid"
                            rows={rowsCards}
                            columns={rewardCardColumns}
                            hideFooter
                          />
                        </Box>
                      )}
                      {Number(watchRewardCardType) == 2 && (
                        <ArgonBox mt={3}>
                          <Controller
                            name="cardsSpecify"
                            control={control}
                            render={({ field }) => (
                              <Select2
                                {...field}
                                className="input-cont"
                                isMulti
                                placeholder="Search Card"
                                options={listCards}
                                onInputChange={changeInputSelect}
                              />
                            )}
                          />
                        </ArgonBox>
                      )}
                    </ArgonBox>
                  )}
                  {watchRewardType === RewardType.POD && (
                    <ArgonBox>
                      <Typography variant="h4">Reward = Pods</Typography>
                      <ArgonBox
                        component="form"
                        noValidate
                        autoComplete="off"
                        id="form-reward-pods"
                        mt={3}
                      >
                        <DataGrid
                          autoHeight
                          className="datagrid"
                          rows={rowsCards}
                          columns={rewardCardColumns}
                          hideFooter
                        />
                      </ArgonBox>
                    </ArgonBox>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {watchRewardType === RewardType.CARD_SLEEVES && (
                    <ArgonBox
                      sx={{
                        "MuiDataGrid-footerContainer": {
                          display: "none",
                        },
                      }}
                    >
                      <Typography variant="h4">Reward = Cards Sleeves</Typography>
                      <ArgonBox mt={3}>
                        <Controller
                          name="sleeves"
                          control={control}
                          render={({ field }) => (
                            <Select2
                              {...field}
                              className="input-cont"
                              isMulti
                              placeholder="Select an card"
                              options={listCardsSleeves?.sleeves || []}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {watchRewardType === RewardType.BATTLE_GROUND && (
                    <ArgonBox
                      sx={{
                        "MuiDataGrid-footerContainer": {
                          display: "none",
                        },
                      }}
                    >
                      <Typography variant="h4">Reward = Battlegrounds</Typography>
                      <ArgonBox mt={3}>
                        <Controller
                          name="grounds"
                          control={control}
                          render={({ field }) => (
                            <Select2
                              {...field}
                              className="input-cont"
                              isMulti
                              placeholder="Select an card"
                              options={listCardsSleeves?.battleGrounds || []}
                            />
                          )}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <ArgonBox sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                    <ArgonButton
                      m={3}
                      color="info"
                      className="btn"
                      variant="contained"
                      type="submit"
                      startIcon={isLoading && <CircularProgress size={20} />}
                      disabled={isLoading}
                    >
                      Submit
                    </ArgonButton>
                    <ArgonButton
                      ml={3}
                      color="info"
                      className="btn"
                      variant="outlined"
                      onClick={onBack}
                      startIcon={isLoading && <CircularProgress size={20} />}
                    >
                      Cancel
                    </ArgonButton>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </FormProvider>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
