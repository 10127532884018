import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";

export const PreviewDialog = (props) => {
  const { open, onClose, file } = props;
  let { ratio } = props;
  if (!ratio) {
    ratio = "3:2";
  }
  ratio = ratio.split(":");
  const maxheight = 400;
  const maxWidth = 600;
  let height = 0;
  let width = 0;
  if (maxWidth / maxheight > Number(ratio[0]) / Number(ratio[1])) {
    height = maxheight;
    width = (maxheight * Number(ratio[0])) / Number(ratio[1]);
  } else {
    width = maxWidth;
    height = (maxWidth * Number(ratio[1])) / Number(ratio[0]);
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "85%",
              maxWidth: "800px", // Set your width here
              textAlign: "center",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Tỉ lệ: " + ratio.join(":")}
          </DialogContentText>
          <ArgonBox
            component="img"
            sx={{
              width: `${width}px`,
              height: `${height}px`,
              objectFit: "fill",
              boxShadow: ({ boxShadows: { md } }) => md,
            }}
            src={file ? file : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
            alt=""
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
