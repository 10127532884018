import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

export const DeleteNewsDialog = (props) => {
  const { open, onClose, id, handleRefetch, deleteQuery, title, message, buttonConfirm } = props;
  const [error] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const { mutate, isSuccess } = deleteQuery(id);
  const onConfirm = async () => {
    mutate();
    setConfirm(true);
  };
  useEffect(() => {
    if (isSuccess && confirm) {
      handleRefetch();
      onClose();
      setConfirm(false);
    }
  }, [handleRefetch, isSuccess, onClose]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {!message && (
            <DialogContentText id="alert-dialog-description">
              {error
                ? "Some thing went wrong!"
                : `Do you want to delete this ${title.toString().toLowerCase()}?`}
            </DialogContentText>
          )}
          {message && (
            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} autoFocus>
            {buttonConfirm ? buttonConfirm : "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
