// Argon Dashboard 2 MUI components
import { Icon } from "@mui/material";
import ArgonBadge from "components/ArgonBadge";
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonButton from "components/ArgonButton";
import {
  ListTypeEventList,
  ListStatusEventList,
  ListOperationEventList,
  ListOperationEvent,
} from "../constant/constant";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
import { Link } from "react-router-dom";

export const eventColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    sortable: false,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row._id) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    width: 320,
    sortable: false,
    renderCell: (params) => {
      return (
        <ArgonBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
          {(Number(params.row.operation) === ListOperationEvent.NOT_START ||
            Number(params.row.operation) === ListOperationEvent.PREPARING) && (
            <ArgonButton
              variant="outlined"
              color="dark"
              component={Link}
              to={`/event/${params.row._id}`}
              sx={{ marginRight: "10px" }}
            >
              Edit
            </ArgonButton>
          )}
          {(Number(params.row.operation) === ListOperationEvent.RUNNING ||
            Number(params.row.operation) === ListOperationEvent.STOPPED ||
            Number(params.row.operation) === ListOperationEvent.EXPIRED) && (
            <ArgonButton
              variant="outlined"
              color="dark"
              component={Link}
              to={`/event/${params.row._id}`}
              sx={{ marginRight: "10px" }}
            >
              View
            </ArgonButton>
          )}
          {Number(params.row.operation) === ListOperationEvent.RUNNING && (
            <ArgonButton
              variant="outlined"
              color="dark"
              component={Link}
              to={`/event/${params.row._id}`}
              sx={{ marginRight: "10px" }}
            >
              Force to stop
            </ArgonButton>
          )}
        </ArgonBox>
      );
    },
  },
  {
    field: "name",
    headerName: "Event name",
    width: 200,
  },
  {
    field: "eventType",
    headerName: "Type",
    width: 100,
    renderCell: (params) => {
      return ListTypeEventList.find((doc) => doc.value === Number(params.row.eventType))?.label;
    },
  },
  {
    field: "operation",
    headerName: "Operation state",
    width: 160,
    sortable: false,
    renderCell: (params) => {
      return ListOperationEventList.find((doc) => doc.value === Number(params.row.operation))
        ?.label;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      const statusLabel = ListStatusEventList.find(
        (doc) => doc.value === Number(params.row.status)
      );
      return (
        <ArgonBadge
          variant="gradient"
          badgeContent={statusLabel.label}
          color={statusLabel.value === 1 ? "success" : "warning"}
          size="xs"
          container
        />
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created time",
    width: 160,
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "joined",
    headerName: "Joined",
    width: 120,
    sortable: false,
  },
  {
    field: "completed",
    headerName: "Completed",
    width: 160,
    sortable: false,
  },
];
