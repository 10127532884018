const axios = require("axios");
import { toast } from "react-toastify";

export const fetch = async (fetchOption) => {
  try {
    const axiosOption = {
      baseURL: fetchOption.baseURL || process.env.REACT_APP_API_URL || "http://localhost:3100",
      url: fetchOption.url,
      method: fetchOption.method || "GET", // default to GET
      data: fetchOption.data,
      params: fetchOption.params,
      headers: {},
    };
    if (fetchOption.noAuth !== true) {
      axiosOption.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
    }
    if (fetchOption.headers !== undefined) {
      axiosOption.headers = {
        ...axiosOption.headers,
        ...fetchOption.headers,
      };
    }
    return await axios(axiosOption);
  } catch (error) {
    toast.error(`Something went wrong: ${error.response.data.message}`);
    if (error.response && error.response.data && error.response.data.statusCode === 401) {
      let errorRefresh = true;
      if (localStorage.getItem("refreshed")) {
        errorRefresh = false;
      }
      if (localStorage.getItem("refreshToken") && !localStorage.getItem("refreshed")) {
        localStorage.setItem("refreshed", "true");
        try {
          const res = await axios.post(`/api/user/refresh-access-token`, {
            refreshToken: localStorage.getItem("refreshToken"),
          });
          if (res.data && res.data.accessToken) {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshed", "");
            errorRefresh = false;
            window.location.reload();
          } else {
            errorRefresh = true;
          }
        } catch (e) {
          errorRefresh = true;
        }
      }
      if (errorRefresh) {
        localStorage.setItem("accessToken", "");
        localStorage.setItem("refreshed", "");
        localStorage.setItem("refreshToken", "");
        window.location.replace("/login");
      }
    } else {
      throw error;
    }
  }
};
