import * as yup from "yup";
import { QuestType } from "../../../utils/quest.enum.ts";
import { RewardMutationEnum } from "../../../constant/constant";
export const questSchema = yup.object().shape({
  questName: yup
    .string()
    .required("Quest name is required")
    .max(40, "Can not input more than 40 characters"),
  type: yup.string().required("Type is required!"),
  total: yup
    .string()
    .required("Number x is required!")
    .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
      return /^[0-9]*$/.test(value) && Number(value) > 0;
    }),
  durationFrom: yup.string().nullable(),
  durationTo: yup
    .string()
    .nullable()
    .test("gatherThan", "Invalid duration", (value, contextTest) => {
      return (
        isNaN(new Date(value)) ||
        isNaN(new Date(contextTest.parent.durationFrom)) ||
        new Date(value) >= new Date(contextTest.parent.durationFrom)
      );
    }),
  status: yup.string(),
  mutationPoint: yup
    .string()
    .required("Mutation point is required")
    .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
      return /^[0-9]*$/.test(value) && Number(value) > 0;
    }),
  mutationOption: yup.string().required("Mutation type is required"),
  mutationType: yup.string().when("mutationOption", (value) => {
    if (Number(value) === RewardMutationEnum.SPECTIFY) {
      return yup.string().required("Mutation type is required");
    }
  }),
  cardType: yup.string().when("type", (value) => {
    if (Number(value) === QuestType.PLAY_X_CARD_SPECIFIC_TYPE) {
      return yup.string().required("Card type is required");
    }
  }),
  tribeType: yup.string().when("type", (value) => {
    if (Number(value) === QuestType.PLAY_X_CARD_SPECIFIC_TRIBE) {
      return yup.string().required("Tribe type is required");
    }
  }),
  speciflicCost: yup.string().when("type", (value) => {
    if (Number(value) === QuestType.PLAY_X_CARD_SPECIFIC_CONST) {
      return yup
        .string()
        .required("Specific cost is required")
        .test("validateNumber", "Invalid value. Please input interger > 0", (value) => {
          return /^[0-9]*$/.test(value) && Number(value) > 0;
        });
    }
  }),
  costComparison: yup.string(),
  conditionMutationType: yup.string().when("type", (value) => {
    if (Number(value) === QuestType.PLAY_X_CARD_SPECIFIC_MUTATION) {
      return yup.string().required("Mutation type is required");
    }
  }),
});
