// @mui material components
import Card from "@mui/material/Card";
import {
  Grid,
  Icon,
  TextField,
  CircularProgress,
  Radio,
  FormControlLabel,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Label } from "examples/Label";
import { useLayoutEffect, useState, useEffect, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateEvent, useGetEventById } from "../../../queries/events.queries";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { eventSchema } from "./schemas";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  ListTypeEventList,
  ListStatusEventList,
  ListTypeFreeList,
  ListTypeFee,
  RewardMutationList,
  MutationTypeList,
  RewardMutationEnum,
  MutationType,
  ListPayResetLoseList,
  PayResetLose,
  YesNoNumberList,
  YesNoNumber,
  PaidToContinueList,
  AfterCompleteList,
  CountMutationType,
  HighestLevel,
  RewardTypeLabel,
  TypeEventReward,
  TypeEventRewardList,
  TypeEventList,
  ListOperationEvent,
} from "../../../constant/constant";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { PreviewDialog } from "../../../examples/PreviewDialog";
import { RewardConfigPopup } from "../RewardConfigPopup";
import { toast } from "react-toastify";

function Default() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [rewardConfig, setRewardConfig] = useState(null);
  const [indexRewardConfig, setIndexRewardConfig] = useState(0);
  const [open, setOpen] = useState(false);
  const [popupReward, setPopupReward] = useState(false);
  const [rewardForWholeEvent, setRewardForWholeEvent] = useState(null);
  const [rewardForEachBattle, setRewardForEachBattle] = useState([null]);
  const { id } = useParams();
  const { data: dataNews, isLoadingError } = useGetEventById(id);
  const [disableOption, setDisableOption] = useState({});
  const [widthImage, setWidthImage] = useState(300);
  const [loadData, setLoadData] = useState(false);
  const refImage = useRef();
  const methods = useForm({
    resolver: yupResolver(eventSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      eventType: 1,
      status: 2,
      description: "",
      durationFrom: "",
      durationTo: "",
      entryFeeType: ListTypeFee.Free,
      entryFee_type: ListTypeFee.Free,
      entryFee_token: "",
      entryFee_mutationPoint: "",
      entryFee_mutationOption: "",
      entryFee_mutationType: MutationType.WH,
      loseLimitType: "",
      loseLimit: "",
      payResetLoseType: PayResetLose.Same_entry_free,
      payResetLose_type: PayResetLose.Same_entry_free,
      payResetLose_token: "",
      payResetLose_mutationPoint: "",
      payResetLose_mutationOption: "",
      payResetLose_mutationType: MutationType.WH,
      paidToContinue: "",
      afterComplete: "",
      numberDeckFrom: 2,
      numberDeckTo: 2,
      numberRound: 3,
      limitLevel: HighestLevel,
      totalCardInDeck: "",
      maxMutationType: 4,
      numberIndetifyCard: "",
      maxCardInBoard: "",
      timeVirusCountdown: "",
      playerHealth: "",
      typeEventReward: "",
      numberWin: "",
    },
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isValid },
    reset,
    watch,
  } = methods;

  const { mutate, isSuccess, isLoading } = useUpdateEvent(id);

  const watchEventType = watch("eventType");
  const watchEntryFeeType = watch("entryFeeType");
  const watchEntryFeeMutationType = watch("entryFee_mutationOption");
  const watchPayResetLoseType = watch("payResetLoseType");
  const watchPayResetLoseMutationType = watch("payResetLose_mutationOption");
  const watchLoseLimitType = watch("loseLimitType");
  const watchTypeEventReward = watch("typeEventReward");
  const watchName = watch("name");
  const watchDescription = watch("description");

  const onBack = () => {
    navigate(-1);
  };

  const validateForm = (data) => {


    if (
      Number(data.typeEventReward) === TypeEventReward.EACH &&
      rewardForEachBattle.length > 0 &&
      rewardForEachBattle.filter((doc) => !doc).length > 0
    ) {
      return `Reward for each battle is required`;
    }

    if (Number(data.typeEventReward) === TypeEventReward.WHOLE && !rewardForWholeEvent) {
      return `Reward for whole event is required`;
    }

    if (Number(data.typeEventReward) === TypeEventReward.WHOLE && !data.numberWin) {
      return `Number win is required`;
    }

    if (!data.durationFrom || !data.durationTo) {
      return `Druation is required`;
    }

    if (new Date(data.durationFrom) >= new Date(data.durationTo)) {
      return `Druation From need less than Druation To`;
    }

    if (
      Number(data.eventType) === TypeEventList.TOURNAMENT &&
      (!data.numberDeckFrom || !data.numberDeckTo)
    ) {
      return `Number of decks per tournament`;
    }

    if (Number(data.eventType) === TypeEventList.TOURNAMENT && !data.numberRound) {
      return `Number of rounds per battle is required`;
    }

    return "";
  };

  const onSubmit = handleSubmit((data) => {
    trigger();
    const message = validateForm(data);
    if (message) {
      toast.error(message);
      return;
    }
    let formData = new FormData();
    for (let key in data) {
      if (key.includes("_")) {
        let arrs = key.split("_");
        if (data[arrs[0]]) {
          data[arrs[0]][arrs[1]] = data[key];
        } else {
          data[arrs[0]] = {
            [arrs[1]]: data[key],
          };
        }
        delete data[key];
      }
    }
    data.rewardIds = [];
    if (Number(data.typeEventReward) === TypeEventReward.WHOLE) {
      data.rewardIds = [rewardForWholeEvent._id];
    } else if (Number(data.typeEventReward) === TypeEventReward.EACH) {
      data.rewardIds = rewardForEachBattle.map((doc, index) => doc._id);
      data.numberWin = data.rewardIds.length;
    }
    if (Number(data.payResetLoseType) === PayResetLose.Same_entry_free) {
      data.payResetLose = data.entryFee;
    }
    for (let key in data) {
      if (key === "durationFrom" || key === "durationTo") {
        formData.append(key, new Date(data[key]));
      } else if (key === "entryFee" || key === "payResetLose" || key === "rewardIds") {
        formData.append(key, JSON.stringify(data[key]));
      } else if (key !== "image") {
        formData.append(key, data[key]);
      }
    }
    if (data.image?.name) {
      formData.append("image", data.image);
    }
    formData.append("imgPath", dataNews.image);
    mutate(formData);
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/event");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isLoadingError) {
      navigate("/event");
    }
  }, [isLoadingError, navigate]);

  useEffect(() => {
    if (dataNews && !loadData) {
      setLoadData(true);
      if (
        Number(dataNews.operation) === ListOperationEvent.RUNNING ||
        Number(dataNews.operation) === ListOperationEvent.STOPPED ||
        Number(dataNews.operation) === ListOperationEvent.EXPIRED
      ) {
        setDisableOption({
          disabled: true,
        });
      } else {
        setDisableOption({});
      }
      setFile(dataNews.image);
      if (dataNews.typeEventReward === TypeEventReward.WHOLE && dataNews.rewards.length > 0) {
        setRewardForWholeEvent(dataNews.rewards[0]);
      }
      if (dataNews.typeEventReward === TypeEventReward.EACH && dataNews.rewards.length > 0) {
        setRewardForEachBattle(dataNews.rewards);
      }
      for (let key in dataNews) {
        if (dataNews[key] === null || dataNews[key] === undefined) {
          dataNews[key] = "";
        }
      }

      reset({
        image: dataNews.image,
        name: dataNews.name,
        eventType: dataNews.eventType,
        status: dataNews.status,
        description: dataNews.description,
        durationFrom: dataNews.durationFrom,
        durationTo: dataNews.durationTo,
        entryFeeType: dataNews.entryFeeType,
        entryFee_type: dataNews.entryFee.type || ListTypeFee.Free,
        entryFee_token: dataNews.entryFee.token || "",
        entryFee_mutationPoint: dataNews.entryFee.mutationPoint || "",
        entryFee_mutationOption: dataNews.entryFee.mutationOption || "",
        entryFee_mutationType: dataNews.entryFee.mutationType || MutationType.WH,
        loseLimitType: dataNews.loseLimitType,
        loseLimit: dataNews.loseLimit,
        payResetLoseType: dataNews.payResetLoseType,
        payResetLose_type: dataNews.payResetLose.type || ListTypeFee.Free,
        payResetLose_token: dataNews.payResetLose.token || "",
        payResetLose_mutationPoint: dataNews.payResetLose.mutationPoint || "",
        payResetLose_mutationOption: dataNews.payResetLose.mutationOption || "",
        payResetLose_mutationType: dataNews.payResetLose.mutationType || MutationType.WH,
        paidToContinue: dataNews.paidToContinue,
        afterComplete: dataNews.afterComplete,
        numberDeckFrom: dataNews.numberDeckFrom,
        numberDeckTo: dataNews.numberDeckTo,
        numberRound: dataNews.numberRound,
        limitLevel: dataNews.limitLevel,
        totalCardInDeck: dataNews.totalCardInDeck,
        maxMutationType: dataNews.maxMutationType,
        numberIndetifyCard: dataNews.numberIndetifyCard,
        maxCardInBoard: dataNews.maxCardInBoard,
        timeVirusCountdown: dataNews.timeVirusCountdown,
        playerHealth: dataNews.playerHealth,
        typeEventReward: dataNews.typeEventReward,
        numberWin: dataNews.numberWin,
      });
    }
  }, [dataNews]);

  useEffect(() => {
    setFile("");
    reset();
    setLoadData(false);
  }, []);

  const handleCloseDialog = () => {
    setOpen(false);
    setPopupReward(false);
    setRewardConfig(null);
  };

  const showPreview = () => {
    if (file) {
      setOpen(true);
    }
  };

  const deleteImage = () => {
    setFile("");
    setValue("image", "");
  };

  const onSelectReward = (dataReward) => {
    if (indexRewardConfig === 0) {
      setRewardForWholeEvent(dataReward);
    } else {
      rewardForEachBattle[indexRewardConfig - 1] = dataReward;
      setRewardForEachBattle(rewardForEachBattle);
    }
  };

  const showPopupReward = (dataReward, index) => {
    setRewardConfig(dataReward);
    setPopupReward(true);
    setIndexRewardConfig(index);
  };

  useLayoutEffect(() => {
    setWidthImage(refImage.current.offsetWidth);
  }, []);

  return (
    <DashboardLayout>
      <PreviewDialog ratio="16:9" open={open} onClose={handleCloseDialog} file={file} />
      <RewardConfigPopup
        open={popupReward}
        onClose={handleCloseDialog}
        reward={rewardConfig}
        onSelectReward={onSelectReward}
      />
      <DashboardNavbar />
      <ArgonBox py={3}>
        <FormProvider {...methods}>
          <ArgonBox mb={3} component="form" onSubmit={onSubmit}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <Typography variant="h6">Add Event</Typography>
                </ArgonBox>
                <ArgonBox
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <Typography variant="h7">Basic information</Typography>
                </ArgonBox>
                <ArgonBox ml={3} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sx={{ textAlign: "center", position: "relative" }}>
                      <ArgonBox
                        component="img"
                        ref={refImage}
                        sx={{
                          width: `100%`,
                          height: `${(widthImage * 9) / 16}px`,
                          cursor: "pointer",
                          objectFit: "fill",
                        }}
                        src={
                          file
                            ? file
                            : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                        alt=""
                        onClick={showPreview}
                      />
                      {file && !disableOption.disabled && (
                        <Icon
                          onClick={deleteImage}
                          sx={{
                            right: "2px",
                            top: "17px",
                            position: "absolute",
                            cursor: "pointer",
                            background: "white",
                          }}
                        >
                          close
                        </Icon>
                      )}
                      <ArgonBox
                        className="formInput"
                        sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                      >
                        <Label htmlFor="upload-photo">
                          <Controller
                            name="image"
                            control={control}
                            render={({ field, fieldState }) => (
                              <div>
                                <input
                                  {...disableOption}
                                  style={{ display: "none" }}
                                  id="upload-photo"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  onChange={(e) => {
                                    setFile(URL.createObjectURL(e.target.files[0]));
                                    field.onChange(e.target.files[0]);
                                  }}
                                />
                                <p style={{ fontSize: "0.75rem" }}>
                                  Image must be less than 5MB, and should have ratio 16:9, minimum
                                  dimension 1920*1080.
                                </p>
                                <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                                  {fieldState.error?.message}
                                </p>
                              </div>
                            )}
                          />

                          <ArgonButton color="warning" variant="contained" component="span">
                            Upload image
                          </ArgonButton>
                        </Label>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ArgonBox>
                            <Label required={true}>Event name</Label>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...disableOption}
                                  fullWidth
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type name..."
                                  {...field}
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                            <InputLabel style={{ float: "right" }}>
                              {watchName?.length}/50 characters
                            </InputLabel>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12}>
                          <ArgonBox>
                            <Label required={true}>Type</Label>
                            <ArgonBox mt={1}>
                              <Controller
                                name="eventType"
                                value={[]}
                                control={control}
                                render={({ field }) => (
                                  <Grid container>
                                    {ListTypeEventList.filter((doc) => doc.value != "all").map(
                                      (item, index) => (
                                        <Box key={"grid-" + index}>
                                          <FormControlLabel
                                            {...field}
                                            key={item.label}
                                            label={item.label}
                                            control={
                                              <Radio
                                                {...disableOption}
                                                sx={{ ml: 2 }}
                                                value={item.value}
                                                checked={
                                                  Number(getValues("eventType")) === item.value
                                                }
                                              />
                                            }
                                          />
                                        </Box>
                                      )
                                    )}
                                  </Grid>
                                )}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12}>
                          <ArgonBox>
                            <Label required={true}>Description</Label>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...disableOption}
                                  fullWidth
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Type here..."
                                  {...field}
                                  sx={{ root: { height: "50px" } }}
                                  multiline
                                  rows={4}
                                />
                              )}
                            />
                            <InputLabel style={{ float: "right" }}>
                              {watchDescription?.length}/2000 characters
                            </InputLabel>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12}>
                          <ArgonBox>
                            <Label>Status</Label>
                            <ArgonBox mt={1}>
                              <Controller
                                name="status"
                                value={[]}
                                control={control}
                                render={({ field }) => (
                                  <Grid container>
                                    {ListStatusEventList.filter((doc) => doc.value != "all").map(
                                      (item, index) => (
                                        <Box key={"grid-" + index}>
                                          <FormControlLabel
                                            {...field}
                                            key={item.label}
                                            label={item.label}
                                            control={
                                              <Radio
                                                {...disableOption}
                                                sx={{ ml: 2 }}
                                                value={item.value}
                                                checked={Number(getValues("status")) === item.value}
                                              />
                                            }
                                          />
                                        </Box>
                                      )
                                    )}
                                  </Grid>
                                )}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12}>
                          <ArgonBox>
                            <Label required={true}>Duration</Label>
                            <ArgonBox sx={{ display: "flex", gap: 1 }}>
                              <Controller
                                name="durationFrom"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                      {...disableOption}
                                      inputFormat="HH:mm DD/MM/YYYY"
                                      {...field}
                                      defaultValues={null}
                                      renderInput={(params) => (
                                        <TextField
                                          {...disableOption}
                                          {...params}
                                          placeholder="Placholder ..."
                                          sx={{ root: { height: "50px" } }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                              <Controller
                                name="durationTo"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                      {...disableOption}
                                      inputFormat="HH:mm DD/MM/YYYY"
                                      {...field}
                                      renderInput={(params) => (
                                        <TextField
                                          {...disableOption}
                                          {...params}
                                          sx={{ root: { height: "50px" } }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
            {/* Event configuration */}
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <Typography variant="h7">Event configuration</Typography>
                </ArgonBox>
                <ArgonBox ml={6} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    {/* Entry fee */}
                    <Grid item xs={12} sm={6}>
                      <ArgonBox>
                        <Label>Entry fee</Label>
                        <ArgonBox mt={1}>
                          <Controller
                            name="entryFeeType"
                            value={[]}
                            control={control}
                            render={({ field }) => (
                              <Grid container sx={{ alignItems: "center" }}>
                                {ListTypeFreeList.filter((doc) => doc.value != "all").map(
                                  (item, index) => (
                                    <Grid item xs={4} key={"grid-" + index}>
                                      <FormControlLabel
                                        {...field}
                                        key={item.label}
                                        label={item.label}
                                        sx={{ paddingLeft: "10px", display: "flex" }}
                                        control={
                                          <Radio
                                            {...disableOption}
                                            sx={{ ml: 2 }}
                                            value={item.value}
                                            checked={
                                              Number(getValues("entryFeeType")) === item.value
                                            }
                                          />
                                        }
                                      />
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {Number(watchEntryFeeType) === ListTypeFee.Token && (
                      <Grid item xs={12} sm={6}>
                        <ArgonBox>
                          <Label>Tokens</Label>
                          <Controller
                            name="entryFee_token"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...disableOption}
                                fullWidth
                                size="small"
                                type={"number"}
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Input decimal > 0, maximum 64 characters. Example: 0.123456789"
                                {...field}
                                sx={{ root: { height: "50px" }, mt: 1 }}
                              />
                            )}
                          />
                        </ArgonBox>
                      </Grid>
                    )}
                    {Number(watchEntryFeeType) === ListTypeFee.Mutation && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <Label required={true}>Mutation points</Label>
                            <Controller
                              name="entryFee_mutationPoint"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...disableOption}
                                  fullWidth
                                  size="small"
                                  type={"number"}
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Placholder ..."
                                  {...field}
                                  sx={{ root: { height: "50px" }, mt: 1 }}
                                />
                              )}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <Label required={true}>Mutation Type</Label>
                            <Controller
                              name="entryFee_mutationOption"
                              value={[]}
                              control={control}
                              render={({ field }) => (
                                <Grid container>
                                  {RewardMutationList.filter((doc) => doc.value != "all").map(
                                    (item, index) => (
                                      <ArgonBox key={"grid-" + index}>
                                        <FormControlLabel
                                          {...field}
                                          key={item.label}
                                          label={item.label}
                                          control={
                                            <Radio
                                              {...disableOption}
                                              sx={{ ml: 2 }}
                                              value={item.value}
                                              checked={
                                                Number(getValues("entryFee_mutationOption")) ===
                                                item.value
                                              }
                                            />
                                          }
                                        />
                                      </ArgonBox>
                                    )
                                  )}
                                </Grid>
                              )}
                            />
                          </ArgonBox>
                        </Grid>
                        {Number(watchEntryFeeMutationType) === RewardMutationEnum.SPECTIFY && (
                          <Grid item xs={12} sm={6}>
                            <Label>Specify</Label>
                            <ArgonBox>
                              <Controller
                                name="entryFee_mutationType"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...disableOption}
                                    labelId="reward-type"
                                    {...field}
                                    label="Reward Type"
                                  >
                                    {MutationTypeList.filter((doc) => doc.value != "all").map(
                                      (mutationTypeValue, index) => {
                                        return (
                                          <MenuItem
                                            key={"type-" + index}
                                            value={mutationTypeValue.value}
                                          >
                                            {mutationTypeValue.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              />
                            </ArgonBox>
                          </Grid>
                        )}
                      </>
                    )}
                    {/* Entry fee */}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox>
                        <Label>Loses limitation</Label>
                        <ArgonBox mt={1}>
                          <Controller
                            name="loseLimitType"
                            value={[]}
                            control={control}
                            render={({ field }) => (
                              <Grid container>
                                {YesNoNumberList.filter((doc) => doc.value != "all").map(
                                  (item, index) => (
                                    <Box key={"grid-" + index}>
                                      <FormControlLabel
                                        {...field}
                                        key={item.label}
                                        label={item.label}
                                        control={
                                          <Radio
                                            {...disableOption}
                                            sx={{ ml: 2 }}
                                            value={item.value}
                                            checked={
                                              Number(getValues("loseLimitType")) === item.value
                                            }
                                          />
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                              </Grid>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {Number(watchLoseLimitType) === YesNoNumber.Yes && (
                      <Grid item xs={12} sm={6}>
                        <ArgonBox>
                          <Label>Loses limit</Label>
                          <Controller
                            name="loseLimit"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...disableOption}
                                fullWidth
                                size="small"
                                type={"number"}
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Placholder ..."
                                {...field}
                                sx={{ root: { height: "50px" }, mt: 1 }}
                              />
                            )}
                          />
                        </ArgonBox>
                      </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    {/* Pay to reset the loses */}
                    <Grid item xs={12} sm={6}>
                      <ArgonBox>
                        <Label>Pay to reset the loses</Label>
                        <ArgonBox mt={1}>
                          <Controller
                            name="payResetLoseType"
                            value={[]}
                            control={control}
                            render={({ field }) => (
                              <Grid container>
                                {ListPayResetLoseList.filter((doc) => doc.value != "all").map(
                                  (item, index) => (
                                    <Box key={"grid-" + index}>
                                      <FormControlLabel
                                        {...field}
                                        key={item.label}
                                        label={item.label}
                                        control={
                                          <Radio
                                            {...disableOption}
                                            sx={{ ml: 2 }}
                                            value={item.value}
                                            checked={
                                              Number(getValues("payResetLoseType")) === item.value
                                            }
                                          />
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                              </Grid>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {Number(watchPayResetLoseType) === PayResetLose.Token && (
                      <Grid item xs={12} sm={6}>
                        <ArgonBox>
                          <Label>Tokens</Label>
                          <Controller
                            name="payResetLose_token"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...disableOption}
                                fullWidth
                                size="small"
                                type={"number"}
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Input decimal > 0, maximum 64 characters. Example: 0.123456789"
                                {...field}
                                sx={{ root: { height: "50px" }, mt: 1 }}
                              />
                            )}
                          />
                        </ArgonBox>
                      </Grid>
                    )}
                    {Number(watchPayResetLoseType) === PayResetLose.Mutation && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <Label required={true}>Mutation points</Label>
                            <Controller
                              name="payResetLose_mutationPoint"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...disableOption}
                                  fullWidth
                                  type={"number"}
                                  size="small"
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  placeholder="Placholder ..."
                                  {...field}
                                  sx={{ root: { height: "50px" }, mt: 1 }}
                                />
                              )}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <Label required={true}>Mutation Type</Label>
                            <Controller
                              name="payResetLose_mutationOption"
                              value={[]}
                              control={control}
                              render={({ field }) => (
                                <Grid container>
                                  {RewardMutationList.filter((doc) => doc.value != "all").map(
                                    (item, index) => (
                                      <ArgonBox key={"grid-" + index}>
                                        <FormControlLabel
                                          {...field}
                                          key={item.label}
                                          label={item.label}
                                          control={
                                            <Radio
                                              {...disableOption}
                                              sx={{ ml: 2 }}
                                              value={item.value}
                                              checked={
                                                Number(getValues("payResetLose_mutationOption")) ===
                                                item.value
                                              }
                                            />
                                          }
                                        />
                                      </ArgonBox>
                                    )
                                  )}
                                </Grid>
                              )}
                            />
                          </ArgonBox>
                        </Grid>
                        {Number(watchPayResetLoseMutationType) === RewardMutationEnum.SPECTIFY && (
                          <Grid item xs={12} sm={6}>
                            <Label>Specify</Label>
                            <ArgonBox>
                              <Controller
                                name="payResetLose_mutationType"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...disableOption}
                                    labelId="reward-type"
                                    {...field}
                                    label="Reward Type"
                                  >
                                    {MutationTypeList.filter((doc) => doc.value != "all").map(
                                      (mutationTypeValue, index) => {
                                        return (
                                          <MenuItem
                                            key={"type-" + index}
                                            value={mutationTypeValue.value}
                                          >
                                            {mutationTypeValue.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              />
                            </ArgonBox>
                          </Grid>
                        )}
                      </>
                    )}
                    {/* Pay to reset the loses */}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox>
                        <Label>Progress after paid to continue</Label>
                        <ArgonBox mt={1}>
                          <Controller
                            name="paidToContinue"
                            value={[]}
                            control={control}
                            render={({ field }) => (
                              <Grid container>
                                {PaidToContinueList.filter((doc) => doc.value != "all").map(
                                  (item, index) => (
                                    <Box key={"grid-" + index}>
                                      <FormControlLabel
                                        {...field}
                                        key={item.label}
                                        label={item.label}
                                        control={
                                          <Radio
                                            {...disableOption}
                                            sx={{ ml: 2 }}
                                            value={item.value}
                                            checked={
                                              Number(getValues("paidToContinue")) === item.value
                                            }
                                          />
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                              </Grid>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox>
                        <Label>After completed event</Label>
                        <ArgonBox mt={1}>
                          <Controller
                            name="afterComplete"
                            value={[]}
                            control={control}
                            render={({ field }) => (
                              <Grid container>
                                {AfterCompleteList.filter((doc) => doc.value != "all").map(
                                  (item, index) => (
                                    <Box key={"grid-" + index}>
                                      <FormControlLabel
                                        {...field}
                                        key={item.label}
                                        label={item.label}
                                        control={
                                          <Radio
                                            {...disableOption}
                                            sx={{ ml: 2 }}
                                            value={item.value}
                                            checked={
                                              Number(getValues("afterComplete")) === item.value
                                            }
                                          />
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                              </Grid>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
            {/* Event configuration */}
            {/* Tournament configuration */}
            {Number(watchEventType) === 2 && (
              <ArgonBox mb={3}>
                <Card>
                  <ArgonBox
                    ml={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <Typography variant="h7">Tournament configuration</Typography>
                  </ArgonBox>
                  <ArgonBox ml={6} mr={3} pb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <ArgonBox>
                          <Label>Number of decks per tournament</Label>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <Controller
                                name="numberDeckFrom"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <TextField
                                    {...disableOption}
                                    fullWidth
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="1"
                                    {...field}
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                              ~
                            </Grid>
                            <Grid item xs={5}>
                              <Controller
                                name="numberDeckTo"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <TextField
                                    {...disableOption}
                                    fullWidth
                                    helperText={error?.message ? error?.message : null}
                                    error={invalid}
                                    placeholder="1"
                                    {...field}
                                    sx={{ root: { height: "50px" } }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12} sm={6}>
                        <ArgonBox>
                          <Label>Number of rounds per battle</Label>
                          <Controller
                            name="numberRound"
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...disableOption}
                                fullWidth
                                helperText={error?.message ? error?.message : null}
                                error={invalid}
                                placeholder="Placholder ..."
                                {...field}
                                sx={{ root: { height: "50px" } }}
                              />
                            )}
                          />
                        </ArgonBox>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Card>
              </ArgonBox>
            )}
            {/* Tournament configuration */}
            {/* Deck & Batlle configuration */}
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <Typography variant="h7">Deck & Batlle configuration</Typography>
                </ArgonBox>
                <ArgonBox ml={6} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h8">Limit deck information</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <ArgonBox>
                        <Label>Highest card level</Label>
                        <Controller
                          name="limitLevel"
                          control={control}
                          render={({ field }) => (
                            <Select {...disableOption} {...field} label="Limit level">
                              {[...Array(HighestLevel)].map((__, index) => {
                                return (
                                  <MenuItem key={"type-" + index} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <ArgonBox>
                        <Label>Total cards</Label>
                        <Controller
                          name="totalCardInDeck"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              type="number"
                              placeholder="1"
                              {...field}
                              sx={{ root: { height: "50px" } }}
                            />
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <ArgonBox>
                        <Label>Number of mutatuin types</Label>
                        <Controller
                          name="maxMutationType"
                          control={control}
                          render={({ field }) => (
                            <Select {...disableOption} {...field} label="Max mutation Type">
                              {[...Array(CountMutationType)].map((__, index) => {
                                return (
                                  <MenuItem key={"type-" + index} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <ArgonBox>
                        <Label>Number of identical cards</Label>
                        <Controller
                          name="numberIndetifyCard"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              type="number"
                              placeholder="1"
                              {...field}
                              sx={{ root: { height: "50px" } }}
                            />
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h8">Battle parameter</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <ArgonBox>
                        <Label>Number of cards on board to win</Label>
                        <Controller
                          name="maxCardInBoard"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              placeholder="1"
                              {...field}
                              sx={{ root: { height: "50px" } }}
                            />
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <ArgonBox>
                        <Label>{`Timer to virus health drop (s)`}</Label>
                        <Controller
                          name="timeVirusCountdown"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              type="number"
                              placeholder="1"
                              {...field}
                              sx={{ root: { height: "50px" } }}
                            />
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <ArgonBox>
                        <Label>Health limit of players</Label>
                        <Controller
                          name="playerHealth"
                          control={control}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...disableOption}
                              fullWidth
                              helperText={error?.message ? error?.message : null}
                              error={invalid}
                              type="number"
                              placeholder="1"
                              {...field}
                              sx={{ root: { height: "50px" } }}
                            />
                          )}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
            {/* Deck & Batlle configuration */}
            {/* Number of battles and Reward */}
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox
                  ml={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <Typography variant="h7">Number of battles and Reward</Typography>
                </ArgonBox>
                <ArgonBox ml={6} mr={3} pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ArgonBox>
                        <Label>Type Reward</Label>
                        <ArgonBox mt={1}>
                          <Controller
                            name="typeEventReward"
                            value={[]}
                            control={control}
                            render={({ field }) => (
                              <Grid container>
                                {TypeEventRewardList.map((item, index) => (
                                  <Box key={"grid-" + index}>
                                    <FormControlLabel
                                      {...field}
                                      key={item.label}
                                      label={item.label}
                                      control={
                                        <Radio
                                          {...disableOption}
                                          sx={{ ml: 2 }}
                                          value={item.value}
                                          checked={
                                            Number(getValues("typeEventReward")) === item.value
                                          }
                                        />
                                      }
                                    />
                                  </Box>
                                ))}
                              </Grid>
                            )}
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {Number(watchTypeEventReward) === TypeEventReward.WHOLE && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <Label>Number of winning</Label>
                            <Controller
                              name="numberWin"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...disableOption}
                                  fullWidth
                                  helperText={error?.message ? error?.message : null}
                                  error={invalid}
                                  type="number"
                                  placeholder="1"
                                  {...field}
                                  sx={{ root: { height: "50px" } }}
                                />
                              )}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <ArgonButton
                              variant="outlined"
                              color="primary"
                              size="medium"
                              rel="noreferrer"
                              fullWidth
                              onClick={() => showPopupReward(rewardForWholeEvent, 0)}
                            >
                              {!rewardForWholeEvent ? (
                                <>
                                  <Icon sx={{ marginRight: "10px", float: "right" }}>add</Icon>Add
                                  reward configuration
                                </>
                              ) : (
                                <>
                                  <Icon sx={{ marginRight: "10px", float: "right" }}>edit</Icon>
                                  {`${rewardForWholeEvent.name} -
                                    ${RewardTypeLabel[rewardForWholeEvent.rewardType]}`}
                                </>
                              )}
                            </ArgonButton>
                          </ArgonBox>
                        </Grid>
                      </>
                    )}
                    {Number(watchTypeEventReward) === TypeEventReward.EACH && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <ArgonBox>
                            <ArgonButton
                              variant="outlined"
                              color="info"
                              size="medium"
                              rel="noreferrer"
                              onClick={() => {
                                setRewardForEachBattle([...rewardForEachBattle, null]);
                              }}
                            >
                              <Icon sx={{ marginRight: "10px", float: "right" }}>add</Icon>Add
                            </ArgonButton>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        {rewardForEachBattle.map((doc, index) => (
                          <Grid key={index} item xs={12}>
                            <Grid container spacing={2} sx={{ maxWidth: "1200px" }}>
                              <Grid item xs={6} sm={3} lg={2} xl={2}>
                                <ArgonBox>
                                  <ArgonButton
                                    variant="outlined"
                                    color="error"
                                    size="medium"
                                    rel="noreferrer"
                                    onClick={() => {
                                      const dataRows = [...rewardForEachBattle];
                                      dataRows.splice(index, 1);
                                      setRewardForEachBattle(dataRows);
                                    }}
                                  >
                                    <Icon sx={{ marginRight: "10px", float: "right" }}>delete</Icon>
                                    Delete
                                  </ArgonButton>
                                </ArgonBox>
                              </Grid>
                              <Grid item xs={6} sm={3} lg={2} xl={2}>
                                <ArgonBox>
                                  <ArgonButton
                                    variant="outlined"
                                    color="dark"
                                    size="medium"
                                    rel="noreferrer"
                                    disabled={true}
                                  >
                                    Batlle {index + 1}
                                  </ArgonButton>
                                </ArgonBox>
                              </Grid>
                              <Grid item xs={12} sm={6} lg={6} xl={4}>
                                <ArgonBox>
                                  <ArgonButton
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    rel="noreferrer"
                                    fullWidth
                                    onClick={() => showPopupReward(doc, index + 1)}
                                  >
                                    {!doc ? (
                                      <>
                                        <Icon sx={{ marginRight: "10px", float: "right" }}>
                                          add
                                        </Icon>
                                        Add reward configuration
                                      </>
                                    ) : (
                                      <>
                                        <Icon sx={{ marginRight: "10px", float: "right" }}>
                                          edit
                                        </Icon>
                                        {`${doc.name} -
                                    ${RewardTypeLabel[doc.rewardType]}`}
                                      </>
                                    )}
                                  </ArgonButton>
                                </ArgonBox>
                              </Grid>
                              <Grid item xs={12} sm={6}></Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                    <Grid item xs={12}>
                      <ArgonBox sx={{ m: 2, display: "flex", gap: 1, justifyContent: "center" }}>
                        {!disableOption.disabled && (
                          <ArgonButton
                            m={3}
                            color="info"
                            className="btn"
                            variant="contained"
                            type="submit"
                            startIcon={isLoading && <CircularProgress size={20} />}
                            disabled={isLoading}
                          >
                            Submit
                          </ArgonButton>
                        )}
                        <ArgonButton
                          ml={3}
                          color="info"
                          className="btn"
                          variant="outlined"
                          onClick={onBack}
                          startIcon={isLoading && <CircularProgress size={20} />}
                        >
                          Cancel
                        </ArgonButton>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
            {/* Number of battles and Reward */}
          </ArgonBox>
        </FormProvider>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
