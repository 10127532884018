import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
const { fetch } = require("../service/index");

const useGetCards = (param) => {
  return useQuery(
    [
      "all-cards",
      {
        ...param,
      },
    ],
    async (query) => {
      const params = {
        page: query.queryKey[1].page,
        perPage: query.queryKey[1].perPage,
        name: query.queryKey[1].q,
        level: [1,2,3,4,5]
      };
      const res = await fetch({
        url: `/api/card/admin/list-card`,
        method: "POST",
        data: params,
      });
      return res.data;
    }
  );
};

const useGetBanCards = (param) => {
  return useQuery(
    [
      "all-cards",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/ban-card`,
        params: {
          ...query.queryKey[1],
        },
      });
      if (res.data && res.data.rows.length > 0) {
        res.data.rows.map((doc, index) => {
          doc.index = (Number(res.data.page) - 1) * Number(res.data.perPage) + index + 1;
        });
      }
      return res.data;
    }
  );
};

const useGetCardsSleeves = (param) => {
  return useQuery(["all-cards-sleeves"], async (query) => {
    const res = await fetch({
      url: `/api/card/list-card-sleeves`,
      method: "POST",
    });
    return res.data;
  });
};

const useDeleteCards = async (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      await fetch({
        url: `/api/news/delete/${id}`,
        method: "delete",
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-news");
      },
    }
  );
};

function useAddBanCard() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/ban-card/create`,
        method: "POST",
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("all-cards");
      },
    }
  );
}

function useUpdateBanCard(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) =>
      await fetch({
        url: `/api/ban-card/${id}`,
        method: "PUT",
        data,
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-cards");
      },
    }
  );
}

function useGetBanCardById(id) {
  return useQuery(
    ["ban-card-detail"],
    async () => {
      const res = await fetch({
        url: `/api/ban-card/` + id,
      });
      return res.data;
    },
    {
      retry: false,
    }
  );
}

function useDeleteBanCard(id) {
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      await fetch({
        url: `/api/ban-card/delete/${id}`,
        method: "DELETE",
      }),
    {
      // when success auto call useGetNews with key: "all-news"
      onSuccess: () => {
        queryClient.invalidateQueries("all-cards");
      },
    }
  );
}

export {
  useGetCards,
  useGetBanCards,
  useDeleteCards,
  useGetCardsSleeves,
  useAddBanCard,
  useUpdateBanCard,
  useGetBanCardById,
  useDeleteBanCard,
};
