import { Typography, Box, colors } from "@mui/material";

export const Label = (props) => {
  let { required, children, fontsize } = props;
  if (!fontsize) {
    fontsize = "1rem";
  }
  return (
    <>
      <Typography component="label" variant="body1" fontSize={fontsize}>
        {children}{" "}
        {required && (
          <Box component="span" sx={{ color: colors.red["700"] }}>
            (*)
          </Box>
        )}
      </Typography>
    </>
  );
};
