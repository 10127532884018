import { useQuery } from "@tanstack/react-query";
const { fetch } = require("../service/index");
import { fotmartTimeUTCToTZ } from "../utils/formatDate.ts";
import { TimeChart } from "../constant/constant";

const useGetGraphSignupAccesing = (param) => {
  return useQuery(
    [
      "all-graph-signup",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/Report/reportSignupAccess`,
        params: {
          ...query.queryKey[1],
        },
      });
      if (query.queryKey[1].groupType === TimeChart.Hour) {
        res.data.datas.map((doc) => {
          doc.time = fotmartTimeUTCToTZ(doc.time);
        });
      }
      return res.data.datas;
    },
    {
      retry: false,
    }
  );
};

const useGetGraphPlayMode = (param) => {
  return useQuery(
    [
      "all-graph-play-mode",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/Report/reportPlayMode`,
        params: {
          ...query.queryKey[1],
        },
      });
      return res.data;
    },
    {
      retry: false,
    }
  );
};

const useGetGraphInactivePlayer = (param) => {
  return useQuery(
    [
      "all-graph-inactive",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/Report/reportInactivePlayer`,
        params: {
          ...query.queryKey[1],
        },
      });
      return res.data.datas;
    },
    {
      retry: false,
    }
  );
};

const useGetGraphActivePlayer = (param) => {
  return useQuery(
    [
      "all-graph-active",
      {
        ...param,
      },
    ],
    async (query) => {
      const res = await fetch({
        url: `/api/Report/reportActivatePlayer`,
        params: {
          ...query.queryKey[1],
        },
      });
      return res.data;
    },
    {
      retry: false,
    }
  );
};

export {
  useGetGraphSignupAccesing,
  useGetGraphPlayMode,
  useGetGraphInactivePlayer,
  useGetGraphActivePlayer,
};
