import { Icon } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { formatDate, DateFormat } from "../utils/formatDate.ts";
export const newsColumns = [
  {
    field: "",
    headerName: "#",
    width: 50,
    sortable: false,
    renderCell: (index) =>
      index.api.state.pagination.page * index.api.state.pagination.pageSize +
      index.api.getRowIndex(index.row._id) +
      1,
  },
  {
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    sortable: false,
    width: 200,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
    renderCell: (params) => {
      return (
        <ArgonBadge
          variant="gradient"
          badgeContent={Number(params.row.status) === 1 ? "ACTIVE" : "INACTIVE"}
          color={Number(params.row.status) === 1 ? "success" : "warning"}
          size="xs"
          container
        />
      );
    },
  },
  {
    field: "durationFrom",
    headerName: "From",
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.durationFrom, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "durationTo",
    headerName: "To",
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.durationTo, DateFormat.FULL_DATE_TIME);
    },
  },
  {
    field: "description",
    headerName: "Short Description",
    width: 150,
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 150,
    renderCell: (params) => {
      return formatDate(params.row.createdAt, DateFormat.FULL_DATE_TIME);
    },
  },
];
